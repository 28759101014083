import React from "react";
import Classes from "./profile.module.css";
import Select from "react-select";
import { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import { STAGGING_BACKEND, LOCAL_BACKEND } from '../../common/helper';

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "white", // Set the background color for the control (input)
    color: "black", // Set the text color for the control (input)
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "white", // Set the background color for the dropdown menu
  }),
  option: (provided, { isSelected, isFocused }) => {
    return {
      ...provided,
      backgroundColor: isSelected
        ? "whitesmoke" // Set the background color for the selected option
        : isFocused
          ? "darkgray" // Set the background color for the focused option
          : "whitesmoke", // Set the background color for other options
      color: "#333", // Set the text color for options
    };
  },
  singleValue: (provided, state) => {
    const textColor = "#333";
    return { ...provided, color: textColor };
  },
};

const cancerTypeOptions = [
  { label: "Stomach cancer", value: "stomach-cancer" },
  { label: "Breast cancer", value: "breast-cancer" },
  { label: "Brain cancer new", value: "brain-cancer-new" },
  { label: "Bad cancer", value: "bad-cancer" },
  { label: "Chest cancer", value: "chest-cancer" },
];

const stageOptions = [
  { label: "Stage 1", value: "stage-1" },
  { label: "Stage 2", value: "stage-2" },
];

const ChecklistTable = (props) => {
  const [tumorType, setTypeType] = useState();
  const [stageType, setStageType] = useState();
  const [checkList, setCheckLists] = useState([])
  const title = props.title;



  useEffect(async () => {
    const url = STAGGING_BACKEND + `user/checklist/`
    const requestOptions = {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'token ' + localStorage.getItem("token"),

      },
    }
    const response = await fetch(url, requestOptions);
    const responseData = await response.json();
    console.log(responseData);
    setCheckLists(responseData?.data)
  }, []);

  return (
    <div>
      <div className={Classes.listHeader} hidden>

        <div className=" w-100 d-flex justify-content-between">
          <h3>{title}</h3>
          <div className="d-flex">
            <Select
              options={cancerTypeOptions}
              value={tumorType}
              className={Classes.selectSearch}
              id="what_cancer"
              styles={customStyles}
              onChange={(event) => {
                setTypeType(event);
              }}
            />
            <Select
              options={stageOptions}
              value={stageType}
              className={Classes.selectSearch}
              id="what_stage"
              styles={customStyles}
              onChange={(event) => {
                setStageType(event);
              }}
            />
          </div>

          <div className="d-flex gap-2">
            <button className="blue-button">Search</button>
            <button
              className="blue-button"
              style={{ backgroundColor: "#e3e3e3", color: '#333' }}
            >
              Reset
            </button>
          </div>
        </div>
      </div>

      <div className={Classes.tableContainer}>
        <Table hover className="drugsTable">
          <thead>
            <tr>
              <th>Id</th>
              <th>Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {checkList.map((item, index) => {
              return (
                <tr key={index}>
                  <td className="drugName">{item.id}</td>
                  <td className="drugName">{item.name}</td>
                  <td>
                    <a href={item.file} download>
                      <button className="blue-button" id="see-details">
                        Download Pdf
                      </button>
                    </a>
                  </td>
                </tr>
              )
            })}

          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default ChecklistTable;
