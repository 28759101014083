export const STAGGING_BACKEND = "https://testapi.cancerclarity.com/"
export const LOCAL_BACKEND = "http://127.0.0.1:8000/"



export function convertTo12HourFormat(timeStr) {
    // Split the time string into hours and minutes
    const [hours, minutes] = timeStr.split(':');
  
    // Convert hours to a number
    const hour = parseInt(hours, 10);
  
    // Determine AM or PM
    const period = hour >= 12 ? 'PM' : 'AM';
  
    // Convert to 12-hour format
    const formattedHour = hour % 12 || 12; // Handle midnight (0) as 12
  
    // Create the formatted time string
    const formattedTime = `${formattedHour}:${minutes} ${period}`;
  
    return formattedTime;
  }


export function convertDatetimeTo12HourFormat(datetimeStr) {
    const date = new Date(datetimeStr);
  
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12; // Convert 0 to 12 for 12-hour format
    const formattedMinutes = minutes.toString().padStart(2, '0');
  
    return `${year}-${month}-${day} ${formattedHours}:${formattedMinutes} ${ampm}`;
  }