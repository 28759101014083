import Utils from "./Utils.js";
import Select_Simple from "./Select_Simple.js";
import close from '../../images/remove_tag.svg'
class Select_Multiple extends Select_Simple
{
  static tname = "select-multiple";

  constructor() 
  {
    super();
    this.selected_groups = [];
  }

  // must return [value] or null
  get value() 
  {
     return this?.selected_groups;
  }

  // must accept [value] or null
  set value(v) 
  {
    if (v === null)
    {
      return;
    }
    else
    {
      this.selected_groups = v;
      if (this.selected_groups.length > 0)
        this.Render_At_Load();
    }
  }

  Render_Null(with_null) 
  {
    if (with_null)
    {
      const null_li = document.createElement("li");
      this.tag_dropdown_list.append(null_li);
    }
  }

  Render_Items(items) 
  {
    this.items = items;
    for (const item of items)
    {
      const li = document.createElement("li");
      li.innerText = item.text;
      li.setAttribute("value", item.value);
      this.tag_dropdown_list.append(li);
    }
    this.Select_Tag_Options();
    if (this.hasAttribute('enable-search'))
    {
      this.Create_Search();
    }
  }

  async Select_Tag_Options() 
  {
    const li_s = this.tag_dropdown_list.querySelectorAll("li");
    li_s.forEach((element) =>
    {
      this.Create_Selected_Tags(element);
    });
  }

  Render_At_Load()
  {
    const li_s = this.tag_dropdown_list.querySelectorAll("li");
    if (this.selected_groups.length > 0)
    {
      this.On_Add_Focus();
      this.selected_groups.forEach((element) =>
      {
        li_s.forEach((li) =>
        {
          const li_val = li.getAttribute('value');
          const elem = element;
          
         if (li_val == elem)
          {
            this.Dynamic_Create_Div(elem, li)
          }
        });
      });
    }
  }

  Dynamic_Create_Div(text, element)
  {
    const div = document.createElement("div");
    const img = document.createElement("img");
    img.setAttribute("src", {close});
  
    let val ;
    this.items.forEach((e) => {
      if(e.value.toLowerCase() == text.toLowerCase()){
        val = e.value;
        div.innerText = e.text;
      }
    })
    div.append(img);
    div.classList.add("selected_tag");
    div.setAttribute("value", val);
    this.box_inputs.append(div);
    if (this.selected_groups.length > 0)
    {
      const exists = this.selected_groups.findIndex(element => element === text) > -1
      if (!exists)
      {
        this.selected_groups.push(text);
      }
    }
    else
    {
      this.selected_groups.push(text)
    }
    img.addEventListener("click", () => 
    {
      const li = document.createElement("li");
      this.items.forEach((e)=>{
        if(e.value.toLowerCase() == text.toLowerCase()){
          li.innerText = e.text;
        }
      })
      li.setAttribute("value", val);
      this.tag_dropdown_list.append(li);
      div.remove();
      this.Create_Selected_Tags(li);
      this.selected_groups = this.selected_groups.filter(
        (el) => el.toLowerCase() != text.toLowerCase()
      );
      if (this.selected_groups.length < 1)
      {
        this.box_wrapper.classList.remove("focus");
      }
      if (this.hasAttribute('enable-search'))
        this.On_Search_Tags();
    });
    if (element.nodeName == 'LI')
    {
      element.remove();
    }
  }

  Create_Selected_Tags(element) 
  {
    element.addEventListener("click", () => this.Dynamic_Create_Div(element.getAttribute('value'),  element));
  }

  On_Search_Tags()
  {
    const dropdown_list = document.querySelector('#tag_selector_search')

    const li_s = this.tag_dropdown_list.querySelectorAll("li");

    li_s.forEach((li) =>
    {
      dropdown_list.addEventListener("input", (event) => this.On_Search_Render_UI(event, li));
    })
  }

  On_Search_Render_UI(event, li)
  {
    if (li.innerText.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1)
    {
      li.classList.remove('d_none')
      li.classList.add('d_block')
    }
    else if (event.target.value === '')
    {
      li.classList.remove('d_none')
      li.classList.remove('d_block')
    }
    else
    {
      li.classList.add('d_none')
    }
  }

  On_Toggle_Dropdown() 
  {
    if (this.tag_dropdown.classList.contains("d_none")) 
    {
      this.close_btn_dropdown.classList.remove("d_none");
      this.tag_dropdown.classList.remove("d_none");
    }
    this.close_btn_dropdown.addEventListener('click', () => { this.tag_dropdown.classList.add("d_none") })
  }

  Get_HTML() 
  {
    const html = `
      <div cid="box_wrapper" class="input-box">
        <label cid="box_label" class="input-label"></label>
        <div class="label_container" cid="box_inputs">
        </div>
        <div class="tag_dropdown d_none" cid="tag_dropdown" >
        <button class="close_btn_dropdown d_none" cid="close_btn_dropdown">X</button>
         <div cid="search_container">
         </div>
          <ul cid="tag_dropdown_list" id="tag_dropdown_list"></ul>
        </div>
        <small cid="box_error"></small>
      </div>
    `;
    return html;
  }

  Create_Search()
  {
    const input = document.createElement('input');
    input.classList.add('tag_selector_search')
    input.setAttribute('type', 'search')
    input.setAttribute('cid', 'tag_selector_search')
    input.setAttribute('id', 'tag_selector_search')
    input.setAttribute('placeholder', 'Search here')
    this.search_container.append(input);
    setTimeout(() =>
    {
      this.On_Search_Tags()
    }, 2000);
  }

  After_Render() 
  {
    this.box_inputs.addEventListener("click", this.On_Focus);
    this.box_inputs.addEventListener("click", this.On_Toggle_Dropdown);
  }
}

Utils.Register_Element(Select_Multiple);

export default Select_Multiple;
