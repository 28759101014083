import React, { useState, useEffect } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Header from "../../components/Header/Header";
import birds from "../../images/birds.png";
import leafs from "../../images/leafs.png";
import logo from "../../images/logo.png";
import EditProfileImage from "../../images/settings.png";
import Classes from "./profile.module.css";
import { Images } from "../../Utils/Images";
import ChecklistTable from "./ChecklistTable";
import { useNavigate } from "react-router-dom";
import CheckListTabs from "./checkListTabs";

const checkListFrame = [
  {
    id: "1",
    titleName: "Chemotharapy days",
    title: "Chemotharapy",
    imgUrl: Images.ChemotharapyDays,
  },
  {
    id: "2",
    titleName: "Medication to ask about",
    title: "Chemotharapy",
    imgUrl: Images.MedicationAskAbout,
  },
];

const CommonChecklistChemo = () => {
  const [header, setHeader] = useState(false);
  const [sideBar, setSideBar] = useState(false);
  const [showTable, setShowTable] = useState(true);
  const [title, setTitle] = useState("");
  function handleHeader() {
    setHeader((t) => !t);
  }
  const navigate = useNavigate();

  function handleSideBar() {
    setSideBar((t) => !t);
  }

  function backButton() {
    navigate("/checklist");
  }

  return (
    <>
      <div className="mobile-header-section">
        <div
          id="nav-icon4"
          className={!sideBar ? "hamburger" : "hamburger open"}
          onClick={handleSideBar}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
        <img src={logo} style={{ height: "60px" }} alt="" />
        <div
          id="nav-icon3"
          onClick={handleHeader}
          className={!header ? "hamburger" : "hamburger open"}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>

      <div className="cc-page-wrapper">
        <Sidebar
          sideBar={sideBar}
          marginTopH6="mt3point5rem"
          margintop2rem="margintop2rem"
        />
        <div className="main-area-inner-wrapper">
          <Header header={header} />

          <div className="new-page-content-wrapper mt-4">
            <h1 className="site-heading">Essential Checklists</h1>

            <div className={Classes.checklistContainer}>
              <button className="blue-button mb-4" onClick={backButton}>
                Back
              </button>
              <CheckListTabs />
              {showTable ? (
                <div className="row">
                  {checkListFrame.map((item) => {
                    return (
                      <div className="col-lg-4">
                        <div
                          class="service-card-parent  w-80 mx-auto"
                          onClick={() => {
                            setShowTable(false);
                            setTitle(item.title);
                          }}
                        >
                          <div class="service-card-wrapper ">
                            <img
                              src={item.imgUrl}
                              style={{ height: "70%" }}
                              alt=""
                            />
                          </div>
                          <p class="text-center">{item.titleName}</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <ChecklistTable title={title} />
              )}
            </div>
          </div>
        </div>
        <img
          src={birds}
          style={{ height: "100px", width: "auto" }}
          className="signup-birds hideInMobile"
          alt=""
        />
        <img
          src={leafs}
          style={{ height: "150px", width: "auto" }}
          className="signup-ground hideInMobile"
          alt=""
        />
      </div>
    </>
  );
};

export default CommonChecklistChemo;
