import React,{useState, useEffect} from 'react';
import Sidebar from "../../../components/sidebar/Sidebar";
import Header from "../../../components/Header/Header";
import birds from "../../../images/birds.png";
import leafs from "../../../images/leafs.png";
import logo from "../../../images/logo.png";
import EditProfileImage from "../../../images/settings.png";
import Classes from '../profile.module.css'
import { Images } from '../../../Utils/Images';
import { Link } from 'react-router-dom';

const ccDiaryOptions = [
    {
      id: "1",
      titleName: "Wallness Center",
      imgUrl: Images.WalnessCenter,
      redirectUrl: '/cancer-clarity-diary-trackers'
    },
    {
      id: "2",
      titleName: "Mental Health",
      imgUrl: Images.MentalHealth,
      redirectUrl: '/common-checklist-chemotharapy'
    },
    {
      id: "3",
      titleName: "Follow-Up Schedule",
      imgUrl: Images.AppointmentTrackers,
      redirectUrl: '/common-checklist-results'
    },
  ]

const MySupervisorShip = () => {
    const [header, setHeader] = useState(false);
    const [sideBar, setSideBar] = useState(false);
    function handleHeader() {
      setHeader((t) => !t);
    }

    function handleSideBar() {
      setSideBar((t) => !t);
    }
  
    return (
        <>
  <div className="mobile-header-section">
        <div
          id="nav-icon4"
          className={!sideBar ? "hamburger" : "hamburger open"}
          onClick={handleSideBar}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
        <img src={logo} style={{ height: "60px" }} alt="" />
        <div
          id="nav-icon3"
          onClick={handleHeader}
          className={!header ? "hamburger" : "hamburger open"}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
  </div>

      <div className="cc-page-wrapper">
        <Sidebar
          sideBar={sideBar}
          marginTopH6="mt3point5rem"
          margintop2rem="margintop2rem"
        />
        <div className="main-area-inner-wrapper">
          <Header header={header} />

          <div className="new-page-content-wrapper mt-4">
            <h1 className="site-heading">My Supervisorship</h1>
           
          <div className={Classes.checklistContainer}>

        <div className="row">
        {ccDiaryOptions?.map(item => {
          return <div className="col-lg-4">
             <Link to={item.redirectUrl}>
             <div className={Classes.checklistCard}>
                <div className={Classes.avtarImg}>
                <img src={item.imgUrl}  alt="" />
                </div>
                <h3>{item.titleName}</h3>
              </div>
             </Link>
              </div>
        })}
          
        </div>


          </div>
          </div>
        </div>
        <img
          src={birds}
          style={{ height: "100px", width: "auto" }}
          className="signup-birds hideInMobile"
          alt=""
        />
        <img
          src={leafs}
          style={{ height: "150px", width: "auto" }}
          className="signup-ground hideInMobile"
          alt=""
        />
      </div>
        </>
    );
}

export default MySupervisorShip;
