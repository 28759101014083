import React, { useEffect, useState } from "react";
import Classes from "./profile.module.css";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
const CheckListTabs = () => {
  const [activeTab, setActiveTab] = useState("");

  const row = "row";
  const location = useLocation();
  useEffect(() => {
    matchRoutes();
  }, []);
  
  function matchRoutes() {
    if (location.pathname == "/common-checklist")
      setActiveTab("questionProviderChecklistBtn");
    else if (location.pathname == "/common-checklist-chemotharapy")
      setActiveTab("chemotharapyChecklistBtn");
    else if (location.pathname == "/common-checklist-results")
      setActiveTab("testResultsChecklistBtn");
  }

  return (
    <div className={`${row} ${Classes.tabParentChecklist}`}>
    <div className="col-lg-4 mb-4">
    <Link to="/common-checklist">
        <button
          className={`${Classes.checklistTabBtn} ${
            activeTab === "questionProviderChecklistBtn" ? Classes.active : ""
          }`}
          id="questionProviderChecklistBtn"
        >
          Question for your provider
        </button>
      </Link>
    </div>
    <div className="col-lg-4 mb-4">
    <Link to="/common-checklist-chemotharapy">
        <button
          className={`${Classes.checklistTabBtn} ${
            activeTab === "chemotharapyChecklistBtn" ? Classes.active : ""
          }`}
          id="chemotharapyChecklistBtn"
        >
          {" "}
          Chemotharapy{" "}
        </button>
      </Link>
    </div>
    <div className="col-lg-4 mb-4">
    <Link to="/common-checklist-results">
        <button
          className={`${Classes.checklistTabBtn} ${
            activeTab === "testResultsChecklistBtn" ? Classes.active : ""
          }`}
          id="testResultsChecklistBtn"
        >
          {" "}
          Test / Results{" "}
        </button>
      </Link>
    </div>
      
    </div>
  );
};

export default CheckListTabs;
