import React, { useState, useEffect, useRef } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import SaveData from "../../components/Save_Data/Save_Data";
import RenderData from "../../components/Save_Data/Render_Data";
import { STAGGING_BACKEND, LOCAL_BACKEND } from "../../common/helper";
import DeleteData from "../../components/Save_Data/Delete_Data";
import CitySearchDropdown from "./questions/city";
import QuestionAirFormFooter from "../../components/questionAirFormFooter/questionAirFormFooter";
import QuestionAirTabs from "../../components/questionairTabs/questionairTabs";
import DashboardLayout from "../../components/dashboardLayout/dashboardLayout";
import { generateNamesArray, handleSwitchStatement } from "../../libs/Util";
import { questionAirOptionCards } from "../../libs/optionsHandling";
import { useLocation } from "react-router-dom"; 
import { navigateToPreviousOption, navigateToNextOption, getPageOptionsFromIndices } from "../../libs/Util";
import Loader from "../../components/Loader/Loader";


function ClinicalQuestion3() {
  const location = useLocation();
  const [lastOption, setLastOption] = useState(false);
  const [isInstitution, setIsInstitution] = useState(false);
  const [importDataState, setImportDataState] = useState(false);
  const [saveDataState, setSaveDataState] = useState(false);
  const [clearData, setClearData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [city, setCity] = useState("");
  const [zipcode, setZipCode] = useState("");
  const [distance, setDistance] = useState(null);
  const [institution, setInstitution] = useState("");
  const cityRef = useRef();
  const distanceRef = useRef();
  const whichInstitutionRef = useRef();

  const navigate = useNavigate();
  const selector = useSelector((state) => state.options);

  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get("mode");
  const page = urlParams.get("page");
  const mode = urlParams.get("mode");
  const savedFilter = urlParams.get("checkByCustomNctIds");
  const optionsToShow = new URLSearchParams(location.search).get(
    "optionsToShow"
  );
  const pageOptions = getPageOptionsFromIndices(optionsToShow);

  let names = generateNamesArray(mode, pageOptions, savedFilter, page, optionsToShow);

  const OnSubmitForm = async () => {
    try {
      setIsLoading(true)
      const url = STAGGING_BACKEND + "cancer/questionair/cancer_type/";
      const payload = {
        city: city,
        // zipcode: zipcodeRef?.current?.value,
        distance: distanceRef.current.value ? distanceRef?.current?.value : null,
        specific_institute: whichInstitutionRef?.current?.value,
      };
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "token " + localStorage.getItem("token"),
        },
        body: JSON.stringify(payload),
      };
      const response = await fetch(url, requestOptions);
      const responseData = await response.json();
  
      if (mode != "full_options") {
        navigateToNextOption(navigate, '2', mode, optionsToShow);
      } else {
        navigate(
          `/clinical-question4?mode=full_options${
            page === "ccTreatmentGuide" ? "&page=ccTreatmentGuide" : ""
          }${savedFilter === "true" ? "&checkByCustomNctIds=true" : ""}`
        );
      }  
    } catch (error) {
      console.log('error', error)
    } finally {
      setIsLoading(false)
    }
    
  };

  useEffect(() => {
    let array = names.map((item) => {
      return item.text;
    });
    Check_Last_Index(array);
    setImportDataState(true);
    Handle_Dynamic_Feilds();
  }, []);

  useEffect(() => {
    setSaveDataState(true);
  }, [institution, distance, zipcode, city]);

  function Handle_Dynamic_Feilds() {
    let val = window.localStorage.getItem(`Location_is_institution`);
    if (val == 1) setIsInstitution(true);
  }

  function Check_Last_Index(array) {
    const lastIndex = array[array.length - 1];
    if (lastIndex == "Location") setLastOption(true);
  }
  const OnSubmitForm2 = async () => {
    try {
      setIsLoading(true);
      const url = STAGGING_BACKEND + "cancer/questionair/cancer_type/";
      const payload = {
        city: city,
        // zipcode: zipcodeRef?.current?.value,
        distance: distanceRef.current.value ? distanceRef?.current?.value : null,
        specific_institute: whichInstitutionRef?.current?.value,
      };
  
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "token " + localStorage.getItem("token"),
        },
        body: JSON.stringify(payload),
      };
      const response = await fetch(url, requestOptions);
      const responseData = await response.json();
      navigate(
        page === "ccTreatmentGuide"
          ? "/cc-treatment-plans"
          : `/stats-drugs${
              savedFilter === "true" ? "?checkByCustomNctIds=true" : ""
            }`
      );  
    } catch (error) {
      console.log('error', error)
    } finally {
      setIsLoading(false)
    }
    
  };
  function HandleBackBtn() {
    if (mode != "full_options") {
      navigateToPreviousOption(navigate, '2', mode, optionsToShow);
    } else {
      navigate(
        `/clinical-question2?mode=full_options${
          page === "ccTreatmentGuide" ? "&page=ccTreatmentGuide" : ""
        }${savedFilter === "true" ? "&checkByCustomNctIds=true" : ""}`
      );
    }
  }
  const handleClearCancel = () => {
    setClearData(false);
  };

  const handleClearConfirm = () => {
    setClearData(false);
  };

  const handleCitySelect = (city) => {
    setCity(city.value);
    // Now you have the selected city in the parent component's state
  };

  return (
    <>
    {isLoading && <Loader/>}
      <DashboardLayout>
        <div className="clinical-question-main-area mt-4">
          <h1 className="text-center site-heading">
            {page === "ccTreatmentGuide"
              ? "CC Treatment Guide"
              : "Clinical Trial Finder"}
          </h1>
          <QuestionAirTabs names={names} currentTab="2" />
          <div className="activeSection-wrapper">
            <div className="question-form-wrapper">
              <form action="">
                <h2>Location</h2>
                <p>Please input the following data</p>
                <div className="form_container_sec">
                  <label htmlFor="">What city do you live in? </label>
                  {/* <Select components={{ MenuList }} options={cityList ? cityList : []} value={city} className='select_search' id="gender" onChange={(event) => {setCity(event)}}  /> */}
                  <CitySearchDropdown onCitySelect={handleCitySelect} />

                  {/* <label htmlFor="">What's your zip code? </label>
                  <div className="input-box-wrapper mb-3">
                    <input
                      type="text"
                      className="signup-box-input loginfields"
                      placeholder="Enter your zip code"
                      id="zip_code"
                      ref={zipcodeRef}
                      onChange={(event) => setZipCode(event.target.value)}
                    />
                  </div> */}

                  <label htmlFor="">
                    How far are you willing to travel? (miles)
                  </label>
                  <div className="input-box-wrapper mb-3">
                    <input
                      type="number"
                      className="signup-box-input loginfields"
                      placeholder="Enter distance in miles"
                      id="distance"
                      ref={distanceRef}
                      onChange={(event) => setDistance(event.target.value)}
                    />
                  </div>

                  <label htmlFor="">
                    Are you interested in a specific institution?{" "}
                  </label>
                  <select
                    onChange={(event) => {
                      if (event.target.value == 1) {
                        setIsInstitution(true);
                      }
                      if (event.target.value != 1) {
                        setIsInstitution(false);
                      }
                    }}
                    id="is_institution"
                  >
                    <option value="">Chose your option</option>
                    <option value="1">yes</option>
                    <option value="0">no</option>
                  </select>

                  {isInstitution && (
                    <>
                      <label htmlFor="">Which institution? </label>
                      <div className="input-box-wrapper mb-3">
                        <input
                          type="text"
                          name="userName"
                          className="signup-box-input loginfields"
                          placeholder="Enter Institution"
                          id="which_institution"
                          ref={whichInstitutionRef}
                          onChange={(event) =>
                            setInstitution(event.target.value)
                          }
                        />
                      </div>
                    </>
                  )}
                </div>
                <QuestionAirFormFooter
                  handleBack={HandleBackBtn}
                  handleSubmit={OnSubmitForm}
                  handleSubmit2={OnSubmitForm2}
                  handleClear={() => setClearData(true)}
                  isLastOption={lastOption}
                />
              </form>
            </div>
          </div>
        </div>
      </DashboardLayout>

      {saveDataState && (
        <SaveData
          feilds={[
            "city",
            "zip_code",
            "distance",
            "is_institution",
            "which_institution",
          ]}
          title={"Location"}
        />
      )}
      {importDataState && (
        <RenderData
          feilds={[
            "city",
            "zip_code",
            "distance",
            "is_institution",
            "which_institution",
          ]}
          title={"Location"}
        />
      )}
      {clearData && (
        <DeleteData
          onCancel={handleClearCancel}
          onConfirm={handleClearConfirm}
          feilds={[
            "city",
            "zip_code",
            "distance",
            "is_institution",
            "which_institution",
          ]}
        />
      )}
    </>
  );
}

export default ClinicalQuestion3;
