import React from 'react';

const QuestionAirFormFooter = ({ handleBack, handleSubmit, handleSubmit2, handleClear, isLastOption }) => {
  return (
    <div className="form_footer">
      <div className="questions-both-btn-wrapper" style={{ maxWidth: "500px!important", margin: "30px auto!important" }}>
        <button className="blue-button" type="button" onClick={handleBack}>
          Back
        </button>
        <button className="blue-button" onClick={handleSubmit} type="button" disabled={isLastOption ? true : false}>
          Next
        </button>
      </div>
      <div className="questions-both-btn-wrapper">
        <button className="blue-button finish-button w-100" type="button" style={{ width: "100%!important" }} onClick={handleSubmit2}>
          Finish
        </button>
        <button className="blue-button finish-button clear_all_btn w-100" type="button" onClick={handleClear}>
          Clear All
        </button>
      </div>
    </div>
  );
};

export default QuestionAirFormFooter;
