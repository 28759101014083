import Utils from "./Utils.js";

class Select_Simple extends HTMLElement
{
  static tname = "select-simple";

  constructor()
  {
    super();
    this.rendered = false;
    this.end_html = "";
    Utils.Bind(this, "On_");
  }

  connectedCallback()
  {
    if (!this.rendered)
    {
      this.Render();
      this.rendered = true;
    }
  }

  // public fields ================================================================================

  get value()
  {
    return this?.box_input?.value;
  }

  set value(v)
  {
    if (this.box_input)
    {
      this.box_input.value = v;
      this.On_Blur();
    }
  }

  // public methods ===============================================================================

  Set_Items(items, with_null, do_sort, remove_duplicates)
  {
    this.box_input.replaceChildren();

    if (remove_duplicates)
    {
      const unique_items = [];
      for (const item of items)
      {
        if (!unique_items.find(i => i.value == item.value))
        {
          unique_items.push(item);
        }
      }
      items = unique_items;
    }

    if (do_sort)
    {
      items.sort((a, b) => (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0));
    }

    this.Render_Null(with_null);

    this.Render_Items(items);
  }

  // events =======================================================================================

  On_Focus(event)
  {
    this.box_wrapper.classList.remove("error");
    requestAnimationFrame(this.On_Add_Focus);
  }

  On_Add_Focus()
  {
    this.box_wrapper.classList.add("focus");
  }

  On_Blur(event)
  {
    if (Utils.isEmpty(this.value))
    {
      this.box_wrapper.classList.remove("focus");
    }
    else
    {
      this.box_wrapper.classList.add("focus");
    }
  }

  // rendering ====================================================================================

  Render_Null(with_null)
  {
    if (with_null)
    {
      const null_option = document.createElement('option');
      this.box_input.append(null_option);
    }
  }

  Render_Items(items)
  {
    for (const item of items)
    {
      const option = document.createElement('option');
      option.innerText = item.text;
      option.value = item.value;
      this.box_input.append(option);
    }
  }

  Show_Error(message)
  {
    this.box_error.innerText = message;
    this.box_wrapper.classList.remove("success");
    this.box_wrapper.classList.add("error");
  }

  Show_Message(message)
  {
    this.box_error.innerText = message;
    this.box_wrapper.classList.remove("error");
    this.box_wrapper.classList.add("success");
  }

  Validates()
  {
    if (this.hasAttribute('required'))
    {
      const has_data = !Utils.isEmpty(this?.box_input?.value);
      if (!has_data)
      {
        this.Show_Error("Required");
        return false;
      }
      else
      {
        return true
      }
    }
  }

  Get_HTML()
  {
    const html = `
      <div cid="box_wrapper" class="input-box">
        <label cid="box_label" class="input-label"></label>
        <select cid="box_input" class="input-1" /></select>
        <small cid="box_error"></small>
        ${this.end_html}
      </div>
    `;

    return html;
  }

  Render()
  {
    const children = [...this.children];
    const html = this.Get_HTML();
    const doc = Utils.toDocument(html); // parse html into dom elements
    this.replaceChildren(doc); // insert elements into this component

    Utils.Set_Id_Shortcuts(this, this, "cid"); // create shortcust to elements by id

    if (this.hasAttribute("label-text"))
    {
      this.box_label.innerText = this.getAttribute("label-text");
    }

    this.After_Render(children);
  }

  After_Render(children)
  {
    this.box_input.addEventListener("focus", this.On_Focus);
    this.box_input.addEventListener("blur", this.On_Blur);
    if (!Utils.isEmpty(children))
    {
      this.box_input.replaceChildren(...children);
      if (Utils.hasValue(this.box_input.value))
      {
        this.On_Add_Focus();
      }
    }
  }
}

Utils.Register_Element(Select_Simple);

export default Select_Simple;
