import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Style from "../../css/multiselector.module.css";
import "../../components/multiSelect/select_multiple.css";

const MultiSelector = ({ options, onChange, label , initialSelectedItems }) => {
  const [selectedItems, setSelectedItems] = useState(initialSelectedItems);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    setSelectedItems(initialSelectedItems);
  }, [initialSelectedItems]); 

  const handleListItemClick = (item) => {
    let newSelectedItems;
    if (selectedItems.includes(item)) {
      newSelectedItems = selectedItems.filter(
        (selectedItem) => selectedItem !== item
      );
    } else {
      newSelectedItems = [...selectedItems, item];
    }
    setSelectedItems(newSelectedItems);
    onChange(newSelectedItems); // Callback for parent component
  };

  const handleRemoveTag = (itemToRemove) => {
    const newSelectedItems = selectedItems.filter(
      (item) => item !== itemToRemove
    );
    setSelectedItems(newSelectedItems);
    onChange(newSelectedItems); // Callback for parent component
  };

  const filteredOptions = options.filter((option) =>
    option.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="multi-selector">
      <label htmlFor="">{label}</label>
      <br />
      <section class="multiselect-section">
        <div className="custom-multiselect-container">
          <div className="search-input">
            <div className="multiselect-label-area">
              <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                className="mutationSearchInput"
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
          <div className="mutliselect-options-wrap">
            <ul>
              {filteredOptions.map((option) => (
                <li
                  key={option}
                  className={
                    selectedItems.includes(option)
                      ? `${Style.selected} ${Style.selectedLi}`
                      : ""
                  }
                  onClick={() => handleListItemClick(option)}
                >
                  {option}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="select-tags-container" id="select-tags-container">
          <h6 style={{ margin: "0" }} className="text-center">
            Selected Options
          </h6>
          <div className="selected-tags">
            {selectedItems.map((item) => (
              <div key={item} className="selected-tag">
                {item}
                <span
                  className={Style.selectedCrossIcon}
                  onClick={() => handleRemoveTag(item)}
                >
                  {" "}
                  &times;
                </span>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

MultiSelector.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  initialSelectedItems: PropTypes.arrayOf(PropTypes.string)
};

export default MultiSelector;
