import React, { useState, useEffect } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Header from "../../components/Header/Header";
import birds from "../../images/birds.png";
import leafs from "../../images/leafs.png";
import logo from "../../images/logo.png";
import EditProfileImage from "../../images/settings.png";
import Classes from "./profile.module.css";
import { Images } from "../../Utils/Images";
import { Link } from "react-router-dom";
const checkListFrame = [
  {
    id: "1",
    titleName: "Question for your provider",
    imgUrl: Images.Checklist1,
    redirectUrl: "/common-checklist",
  },
  {
    id: "2",
    titleName: "Chemotherapy",
    imgUrl: Images.TestResults,
    redirectUrl: "/common-checklist-chemotharapy",
  },
  {
    id: "3",
    titleName: "Tests/Results",
    imgUrl: Images.TestResults,
    redirectUrl: "/common-checklist-results",
  },
];

const Checklist = () => {
  const [header, setHeader] = useState(false);
  const [sideBar, setSideBar] = useState(false);
  function handleHeader() {
    setHeader((t) => !t);
  }

  function handleSideBar() {
    setSideBar((t) => !t);
  }

  return (
    <>
      <div className="mobile-header-section">
        <div
          id="nav-icon4"
          className={!sideBar ? "hamburger" : "hamburger open"}
          onClick={handleSideBar}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
        <img src={logo} style={{ height: "60px" }} alt="" />
        <div
          id="nav-icon3"
          onClick={handleHeader}
          className={!header ? "hamburger" : "hamburger open"}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>

      <div className="cc-page-wrapper">
        <Sidebar
          sideBar={sideBar}
          marginTopH6="mt3point5rem"
          margintop2rem="margintop2rem"
        />
        <div className="main-area-inner-wrapper">
          <Header header={header} />

          <div
            className="new-page-content-wrapper"
            style={{ marginTop: "7rem" }}
          >
            <h1 className="site-heading">Essential Checlists</h1>
            <div
              className={Classes.checklistContainer}
              style={{ maxWidth: "1000px" }}
            >
              <div className="row mt-4">
                {checkListFrame.map((item) => {
                  return (
                    <div className="col-lg-4">
                      <Link to={item.redirectUrl}>
                        <div class="service-card-parent  w-80 mx-auto">
                          <div class="service-card-wrapper ">
                            <img src={item.imgUrl} alt="" style={{height: '100%'}} />
                          </div>
                          <p class="text-center">{item.titleName}</p>
                        </div>
                      </Link>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <img
          src={birds}
          style={{ height: "100px", width: "auto" }}
          className="signup-birds hideInMobile"
          alt=""
        />
        <img
          src={leafs}
          style={{ height: "150px", width: "auto" }}
          className="signup-ground hideInMobile"
          alt=""
        />
      </div>
    </>
  );
};

export default Checklist;
