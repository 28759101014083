import React from 'react';
import { Link , useLocation} from 'react-router-dom';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const QuestionAirTabs = ({ names, currentTab }) => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const optionsToShow = urlParams.get('optionsToShow');
  const responsive = {
    0: {
      items: 1,
    },
    400: {
      items: 3,
    },
    600: {
      items: 3,
    },
    700: {
      items: 5,
    },
    900: {
      items: 5,
    },
    1000: {
      items: 5,
    },
    1300: {
      items: 9,
    },
  };
  

  const isActiveTab = (route) => {
    const currentPath = location.pathname;
    return currentPath === route.split('?')[0];
  };


  return (
    <div className="slider-wrapper">
      <OwlCarousel className="owl-theme" margin={6} nav={false} dots={false} responsive={responsive}>
        {names.map((e, index) => {
          const isActive = isActiveTab(e.route);
          return (
            <div className="item" key={index}>
              <Link to={e.route}>
                <div className={`${isActive ? "activebtn" : ""} question-tab-button`}>
                  <p>{e.text}</p>
                </div>
              </Link>
              <i className={`${(names.length - 1 === index) && 'hideArrow'} fa-solid fa-arrow-right`}></i>
            </div>
          );
        })}
      </OwlCarousel>
    </div>
  );

};

export default QuestionAirTabs;
