import React, { useState } from "react";
import Sidebar from "../sidebar/Sidebar";
import Header from "../Header/Header";
import logo from "../../images/logo.png";
import birds from "../../images/birds.png";
import leafs from "../../images/leafs.png";

const DashboardLayout = ({ children, page }) => {
  const [header, setHeader] = useState(false);
  const [sideBar, setSideBar] = useState(false);

  const handleHeader = () => {
    setHeader((t) => !t);
  };
  const handleSideBar = () => {
    setSideBar((t) => !t);
  };

  return (
    <>
      <div className="mobile-header-section">
        <div
          id="nav-icon4"
          className={!sideBar ? "hamburger" : "hamburger open"}
          onClick={handleSideBar}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
        <img src={logo} style={{ height: "60px" }} alt="" />
        <div
          id="nav-icon3"
          onClick={handleHeader}
          className={!header ? "hamburger" : "hamburger open"}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div className="clinical-questions-section">
        <Sidebar /* ...sidebar props */ />
        <div className="clinical-question-inner-parent">
          <Header /* ...header props */ />
          <div className="clinical-question-main-area mt-4">{children}</div>
        </div>
        <img
          src={birds}
          style={{ height: "100px", width: "auto" }}
          className="signup-birds hideInMobile"
          alt=""
        />
        <img
          src={leafs}
          style={{ height: "150px", width: "auto" }}
          className="signup-ground hideInMobile"
          alt=""
        />
      </div>
      {/* Footer or other common components can be added here */}
    </>
  );
};

export default DashboardLayout;
