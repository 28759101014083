import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Signup from "./pages/Signup";
import "./App.css";
import Login from "./pages/login/Login";
import Welcome from "./pages/welcome/Welcome";
import CancerCenterConsulting from "./pages/cancerCenterConsulting/CancerCenterConsulting";
import ClinicalTrialFinder from "./pages/clinicalTrialFinder/ClinicalTrialFinder";
import PharmaConsulting from "./pages/pharmaConsulting/PharmaConsulting";
import TreatmentGuide from "./pages/treatmentGuide/TreatmentGuide";
import Services from "./pages/services/Services";
import ClinicalTrialFinderMenu from "./pages/clinicalTrialFinderMenu/ClinicalTrialFinderMenu";
import ClinicalTrialFinderPatient from "./pages/clinicalTrialFinder/ClinicalTrialFinderPatient";
import ClinicalTrialFinderHealthCare from "./pages/clinicalTrialFinder/ClinicalTrialFinderHealthCare";
import ClinicalTrialFinderPharmaResearcher from "./pages/clinicalTrialFinder/ClinicalTrialFinderPharmaResearcher";
import ContactUs from "./pages/contactUs/ContactUs";
import Profile from "./pages/profile/Profile";
import ServicesPackages from "./pages/servicesPackages/ServicesPackages";
import PharmaPackages from "./pages/pharmaPackages/PharmaPackages";
import ClinicalQuestions from "./pages/clinicalQuestions/ClinicalQuestions";
import TrialCriteria from "./pages/trailCriteria/trialCriteria";
import TrialMenuThree from "./pages/trailMenuThree/trialMenuThree";
import MyAccount from "./pages/myAccount/MyAccount";
import StatsDrugs from "./pages/statsDrugs/StatsDrugs";
import SecuritySettings from "./pages/securitySettings/securitySettings";
import AccountsSetting from "./pages/accountsSetting/AccountsSetting";
import SettingAccounts from "./pages/accountsSetting/SettingAccounts";
import ProtectedRoute from "./components/ProtectedRoute/protectedRoute";
import AddTeam from "./pages/addTeam/AddTeam";
import SavedTreatmentPlan from "./pages/savedTreatmentPlan/SavedTreatmentPlan";
import SavedTrials from "./pages/savedTrials/SavedTrials";
import About from "./pages/about/About";
import CancerCenterConsultingCards from "./pages/cancerCenterConsulting/CancerCenterConsultingCards";
import UserVerification from "./pages/userVerification/userVerification";
import UploadPicture from "./pages/profile/UploadPicture";
import ClinicalQuestion2 from './pages/clinicalQuestions/ClinicalQuestion2'
import ClinicalQuestion3 from './pages/clinicalQuestions/ClinicalQuestion3'
import ClinicalQuestion4 from './pages/clinicalQuestions/ClinicalQuestion4'
import ClinicalQuestion5 from './pages/clinicalQuestions/ClinicalQuestion5'
import ClinicalQuestion6 from './pages/clinicalQuestions/ClinicalQuestion6'
import ClinicalQuestion7 from './pages/clinicalQuestions/ClinicalQuestion7'
import ClinicalQuestion8 from './pages/clinicalQuestions/ClinicalQuestion8'
import ClinicalQuestion9 from './pages/clinicalQuestions/ClinicalQuestion9'
import TrialMenuPR from "./pages/trailMenuThree/trialMenuPR";
import ClinicalOptions from "./pages/clinicalQuestions/ClinicalOptions";
import TrialMenuPatient from "./pages/trailMenuThree/trialMenuPatient";
import CcTreatmentPlans from "./pages/ccTreatmentPlans/CcTreatmentPlans";
import CcTreatmentGuideFinish from "./pages/ccTreatmentPlans/CcTreatmentGuide_Finish";
import Results from "./pages/results/Results";
import EditProfile from "./pages/profile/EditProfile";
import Checklist from "./pages/profile/Checklist";
import CommonChecklist from "./pages/profile/CommonChecklist";
import CommonChecklistChemo from "./pages/profile/commonChecklistChemo";
import ChecklistResult from "./pages/profile/ChecklistResults";
import TrialCordinator from "./pages/profile/trialCordinator/trialCordinator";
import CcDiary from "./pages/profile/trialCordinator/CcDiary";
import MySupervisorShip from "./pages/profile/trialCordinator/MySupervisorShip";
import NutritionTracker from "./pages/profile/trialCordinator/NutritionTracker";
import MedtiationTracker from "./pages/profile/trialCordinator/MeditationTracker";
import SymptomTracker from "./pages/profile/trialCordinator/SymptomTracker";
import AppointmentTracker from "./pages/profile/trialCordinator/AppointmentTracker";
import EventCalendar from "./pages/profile/calendar/EventCalendar";
import Team from "./pages/profile/team/Team";
import ForgotPassword from "./pages/forgotPassword/forgotPassword";
import OtpScreen from "./pages/forgotPassword/otpScreen";
import UpdatePassword from "./pages/forgotPassword/updatePassword";

function App() {
  const location = useLocation();

  const titleMap = {
    "/": "Welcome - CancerClarity",
    "/signup": "Signup - CancerClarity",
    "/login": "Login - CancerClarity",
    "/contactus": "Contact Us - CancerClarity",
    "/about-us": "About Us - CancerClarity",
    "/forgot-password": "Forgot Password - CancerClarity",
    "/otp-screen": "OTP Screen - CancerClarity",
    "/update-password": "Update Password - CancerClarity",
    "/cancerCenterConsulting": "Cancer Center Consulting - CancerClarity",
    "/clinicalTrialFinder": "Clinical Trial Finder - CancerClarity",
    "/ClinicalTrialFinderPatient": "Clinical Trial Finder - Patient",
    "/ClinicalTrialFinderHealthCare": "Clinical Trial Finder - HealthCare",
    "/ClinicalTrialFinderPharmaResearcher": "Clinical Trial Finder - Pharma Researcher",
    "/pharmaConsulting": "Pharmaceutical Consulting - CancerClarity",
    "/treatmentGuide": "Treatment Guide - CancerClarity",
    "/my-team": "My Team - CancerClarity",
    "/saved-treatment-plans": "Saved Treatment Plans - CancerClarity",
    "/cc-treatment-plans": "CC Treatment Plans - CancerClarity",
    "/services-Packages": "Services Packages - CancerClarity",
    "/pharma-Packages": "Pharma Packages - CancerClarity",
    "/trial-menu-patient": "Trial Menu - Patient",
    "/trial-menu": "Trial Menu - CancerClarity",
    "/trial-menu-PR": "Trial Menu - PR",
    "/clinical-questions-options": "Clinical Questions Options",
    "/accounts": "My Account - CancerClarity",
    "/security-settings": "Security Settings - CancerClarity",
    "/accounts-settings": "Accounts Settings - CancerClarity",
    "/profile": "Profile - CancerClarity",
    "/stats-drugs": "Stats Drugs - CancerClarity",
    "/trial-criteria": "Trial Criteria - CancerClarity",
    "/clinical-questions": "Clinical Questions - CancerClarity",
    "/clinical-question2": "Clinical Question 2 - CancerClarity",
    "/clinical-question3": "Clinical Question 3 - CancerClarity",
    "/clinical-question4": "Clinical Question 4 - CancerClarity",
    "/clinical-question5": "Clinical Question 5 - CancerClarity",
    "/clinical-question6": "Clinical Question 6 - CancerClarity",
    "/clinical-question7": "Clinical Question 7 - CancerClarity",
    "/clinical-question8": "Clinical Question 8 - CancerClarity",
    "/clinical-question9": "Clinical Question 9 - CancerClarity",
    "/upload-profile-picture": "Upload Profile Picture - CancerClarity",
    "/cancer-clarity-diary-trackers": "Cancer Clarity Diary Trackers",
    "/event-calendar": "Event Calendar - CancerClarity",
    "/nutrition-tracker": "Nutrition Tracker - CancerClarity",
    "/meditation-tracker": "Meditation Tracker - CancerClarity",
    "/symptom-tracker": "Symptom Tracker - CancerClarity",
    "/appointment-tracker": "Appointment Tracker - CancerClarity",
    "/care-coordinator-my-survivorship": "My Survivorship - CancerClarity",
    "/checklist": "Checklist - CancerClarity",
    "/common-checklist": "Common Checklist - CancerClarity",
    "/common-checklist-chemotharapy": "Common Checklist - Chemotherapy",
    "/common-checklist-results": "Checklist Results - CancerClarity",
    "/trial-cordinator": "Trial Coordinator - CancerClarity",
    "/edit-profile": "Edit Profile - CancerClarity",
    "/results": "Results - CancerClarity",
    "/team": "Team - CancerClarity",
    "/user-verification/:id": "User Verification - CancerClarity",
    "/cc-treatment-guide-finish": "CC Treatment Guide - Finish",
    "/saved-trials": "Saved Trials - CancerClarity",
  };

  useEffect(() => {
    const currentPath = location.pathname;
    const pageTitle = titleMap[currentPath] || "CancerClarity"; 
    document.title = pageTitle;
  }, [location.pathname]);

  return (
    <>
      <Routes>
        <Route path="/signup" element={<Signup />}></Route>
        <Route path="/login" element={<Login />}>
        </Route>
        <Route path="/" element={<Welcome />}> </Route>
        <Route path="/contactus" element={<ContactUs />}>
        </Route>
        <Route path="/about-us" element={<About />}>
        </Route>
        <Route path="/forgot-password" element={<ForgotPassword />}>
        </Route>
        <Route path="/otp-screen" element={<OtpScreen />}>
        </Route>
        <Route path="/update-password" element={<UpdatePassword />}>
        </Route>
        <Route element={<ProtectedRoute />} >
          <Route path="/" element={<Welcome />}> </Route>
          <Route path="/services" element={<Services />}>
          </Route>
          <Route path="/cancerCenterConsulting" element={<CancerCenterConsulting />}>
          </Route>
          <Route path="/clinicalTrialFinder" element={<ClinicalTrialFinder />}>
          </Route>
          <Route path="/ClinicalTrialFinderPatient" element={<ClinicalTrialFinderPatient />}>
          </Route>
          <Route path="/ClinicalTrialFinderHealthCare" element={<ClinicalTrialFinderHealthCare />}>
          </Route>
          <Route path="/ClinicalTrialFinderPharmaResearcher" element={<ClinicalTrialFinderPharmaResearcher />}>
          </Route>

          <Route path="/pharmaConsulting" element={<PharmaConsulting />}>
          </Route>
          <Route path="/treatmentGuide" element={<TreatmentGuide />}>
          </Route>
          <Route path="/contactus" element={<ContactUs />}>
          </Route>

          <Route path="/my-team" element={<AddTeam />}>
          </Route>
          <Route path="/saved-treatment-plans" element={<SavedTreatmentPlan />}>
          </Route>
          <Route path="/cc-treatment-plans" element={<CcTreatmentPlans />}>

          </Route>
          <Route path="/settings-accounts" element={<SettingAccounts />}>
          </Route>

          <Route path="/cancer-consulting-cards" element={<CancerCenterConsultingCards />}>
          </Route>
          <Route path="/clinicalTrialFinderMenu" element={<ClinicalTrialFinderMenu />}>
          </Route>
          <Route path="/cc-treatment-guide-finish" element={<CcTreatmentGuideFinish />}>
          </Route>
          <Route path="/saved-trials" element={<SavedTrials />}>
          </Route>
          <Route path="/results" element={<Results />}>
          </Route>
          <Route path="/team" element={<Team />}>
          </Route>
          <Route path="/upload-profile-picture" element={<UploadPicture />}>
          </Route>
          <Route path="/cancer-clarity-diary-trackers" element={<CcDiary />}>
          </Route>
          <Route path="/event-calendar" element={<EventCalendar />}>
          </Route>
          <Route path="/nutritiion-tracker" element={<NutritionTracker />}>
          </Route>
          <Route path="/meditation-tracker" element={<MedtiationTracker />}>
          </Route>
          <Route path="/symptom-tracker" element={<SymptomTracker />}>
          </Route>
          <Route path="/appointment-tracker" element={<AppointmentTracker />}>
          </Route>
          <Route path="/care-coordinator-my-survivorship" element={<MySupervisorShip />}>
          </Route>
          <Route path="/checklist" element={<Checklist />}>
          </Route>
          <Route path="/common-checklist" element={<CommonChecklist />}>
          </Route>
          <Route path="/common-checklist-chemotharapy" element={<CommonChecklistChemo />}>
          </Route>
          <Route path="/common-checklist-results" element={<ChecklistResult />}>
          </Route>
          <Route path="/trial-cordinator" element={<TrialCordinator />}>
          </Route>
          <Route path="/edit-profile" element={<EditProfile />}>
          </Route>
          <Route path="/services-Packages" element={<ServicesPackages />}>
          </Route>
          <Route path="/pharma-Packages" element={<PharmaPackages />}>
          </Route>
          <Route path="/trial-menu-patient" element={<TrialMenuPatient />}>
          </Route>
          <Route path="/trial-menu" element={<TrialMenuThree />}>
          </Route>
          <Route path="/trial-menu-PR" element={<TrialMenuPR />}>
          </Route>
          <Route path="/clinical-questions-options" element={<ClinicalOptions />}>
          </Route>
          <Route path="/accounts" element={<MyAccount />}>
          </Route>

          <Route path="/security-settings" element={<SecuritySettings />}>
          </Route>
          <Route path="/accounts-settings" element={<AccountsSetting />}>
          </Route>

          {/* *************** */}
          <Route path="/profile" element={<Profile />}>
          </Route>
          <Route path="/stats-drugs" element={<StatsDrugs />}>
          </Route>

          <Route path="/trial-criteria" element={<TrialCriteria />}>
          </Route>
          <Route path="/clinical-questions" element={<ClinicalQuestions />}>
          </Route>
          <Route path="/clinical-question2" element={<ClinicalQuestion2 />}>
          </Route>
          <Route path="/clinical-question3" element={<ClinicalQuestion3 />}>
          </Route>
          <Route path="/clinical-question4" element={<ClinicalQuestion4 />}>
          </Route>
          <Route path="/clinical-question5" element={<ClinicalQuestion5 />}>
          </Route>
          <Route path="/clinical-question6" element={<ClinicalQuestion6 />}>
          </Route>

          <Route path="/clinical-question7" element={<ClinicalQuestion7 />}>
          </Route>
          <Route path="/clinical-question8" element={<ClinicalQuestion8 />}>
          </Route>

          <Route path="/clinical-question9" element={<ClinicalQuestion9 />}>
          </Route>

        </Route>

        <Route path="/user-verification/:id" exact element={<UserVerification />} />

      </Routes >
    </>
  );
}

export default App;
