import React, { useState } from "react";
import logo from "../../images/logo.png";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "../../components/dashboardLayout/dashboardLayout";
import Loader from "../../components/Loader/Loader";
import Sidebar from "../../components/sidebar/Sidebar";
import Header from "../../components/Header/Header";

function ForgotPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [sideBar, setSideBar] = useState(false);
  const [header, setHeader] = useState(false);
  const [loading, setLoading] = useState(false); 
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [emailError, setEmailError] = useState("");

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = () => {
    if (isEmailValid) {
      setLoading(true); 

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email: email }),
      };
      fetch(
        "https://testapi.cancerclarity.com/user/forget-password",
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          setLoading(false);
          if(data.error === true) {
            Swal.fire("Error", "User Does Not Exist", "error");  
          } else {
            navigate(`/otp-screen?email=${email}`);
          }
        })
        .catch((error) => {
          Swal.fire("Error", "Failed to send password reset email.", "error");
          setLoading(false); 
        });
    }
  };

  const handleEmailChange = (event) => {
    const email = event.target.value;
    setEmail(email);
    if (validateEmail(email)) {
      setIsEmailValid(true);
      setEmailError("");
    } else {
      setIsEmailValid(false);
      setEmailError("Please enter a valid email address.");
    }
  };

  function handleHeader() {
    setHeader((t) => !t);
  }

  function handleSideBar() {
    setSideBar((t) => !t);
  }


  return (
    <>
    <div>

  
      {loading && <Loader />}

      <div className="mobile-header-section">
          <div
            id="nav-icon4"
            className={!sideBar ? "hamburger" : "hamburger open"}
            onClick={handleSideBar}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
          <img src={logo} style={{ height: "60px" }} alt="" />
          <div
            id="nav-icon3"
            onClick={handleHeader}
            className={!header ? "hamburger" : "hamburger open"}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div className="signup-section-wrapper">
          <Sidebar sideBar={sideBar} />

          <div className="signup-inner-parent">
            <Header header={header} />

        <div className="forgotPassWrapper">
          <h1 className="marginZero site-heading">Welcome!</h1>
          <p className="marginZero BluetextUnderHeading">Enter your email</p>
          <div className="input-box-wrapper mt-4 mb-3">
            <input
              type="email"
              name="email"
              className="signup-box-input loginfields"
              placeholder="Email"
              required
              onChange={handleEmailChange}
            />
            {emailError && <p className="warning">{emailError}</p>  }
          </div>

          <div className="input-box-wrapper mb-3">
          <button
  className={`gray-button submit-btn ${!isEmailValid ? 'button-disabled' : ''}`}
  onClick={handleSubmit}
  disabled={!isEmailValid}
  style={{ cursor: isEmailValid ? 'pointer' : 'not-allowed !important' }}
>
  Submit
</button>

          </div>
        </div>
</div>
        </div>
        </div>
    </>
  );
}

const styles = {
  errorText: {
    color: "red",
    fontSize: "0.8rem",
    marginTop: "0.5rem",
  }
};

export default ForgotPassword;
