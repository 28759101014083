export const check_stage_and_tumor_type = () => {
    const cancerType = localStorage.getItem('Cancer_Type_what_cancer');
    const cancerStage = localStorage.getItem('Cancer_Type_stagDropdown');
    const requiredCancerStageTesticular = ["3", "3A", "3B", "3C"]
    const requirdStage = ["4"]
    if (cancerType == "Testicular Cancer" && requiredCancerStageTesticular.includes(cancerStage)) {
        console.log("return true")
        return true
    }
    if (requirdStage.includes(cancerStage)) {
        console.log("return false")
        return true
    }
    return false

}