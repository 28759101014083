import React, { useState, useEffect } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Header from "../../components/Header/Header";
import birds from "../../images/birds.png";
import leafs from "../../images/leafs.png";
import logo from "../../images/logo.png";
import EditProfileImage from "../../images/settings.png";
import userAvatar from "../../images/user-avatar.png";
import { STAGGING_BACKEND, LOCAL_BACKEND } from "../../common/helper";
import Swal from "sweetalert2";
import avatarImage from '../../images/profilePic.png';
import Loader from "../../components/Loader/Loader";
// import styles from "./editProfile.module.css"
import { useNavigate } from "react-router-dom";
const EditProfile = () => {
  const [header, setHeader] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [profile, setProfile] = useState({});
  const [loading, setLoading] = useState(true);
  const [passwordErrors, setPasswordErrors] = useState({
    newPasswordError: "",
    confirmPasswordError: "",
  });

  const navigate = useNavigate();

  
  const [editedProfile, setEditedProfile] = useState({
    first_name: "",
    last_name: "",
    email: "",
    old_password: "",
    new_password: "",
    new_password2: "",
  });
  const [userImage, setUserImage] = useState(null);
  const [sideBar, setSideBar] = useState(false);

  const submitForm = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    const formImage = userImage ? formData.append("image", userImage) : null;

    for (const key in editedProfile) {
      if (editedProfile[key] != null) {
        formData.append(key, editedProfile[key]);
      }
    }

    const url = STAGGING_BACKEND + `user/profile`;
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "token " + localStorage.getItem("token"),
      },
      body: formData,
    };
    const response = await fetch(url, requestOptions);
    const responseData = await response.json();
    if (responseData?.error === false) {
      Swal.fire({
        title: "Successful!",
        text: responseData.message,
        icon: "success",
        confirmButtonText: "Cool",
      });
    } else {
      Swal.fire({
        title: "Error!",
        text: responseData.message,
        icon: "error",
        confirmButtonText: "Try again",
      });
    }
  };

  const fetchUser = async () => {
    const url = STAGGING_BACKEND + `user/profile`;
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "token " + localStorage.getItem("token"),
      },
    };
    const response = await fetch(url, requestOptions);
    const responseData = await response.json();
    if (responseData) {
      setProfile(responseData?.data);
      setEditedProfile({
        first_name: responseData?.data?.first_name,
        last_name: responseData?.data?.last_name,
        email: responseData?.data?.email,
      });
    }
    setLoading(false);
  };

  const isValidPassword = (password) => {
    const hasEightCharacters = /.{8,}/.test(password);
   
    const hasSpecialCharacter = /.*[!@#$%^&*(),.?":{}|<>].*/.test(password);
    const hasUpperCase = /[A-Z]/.test(password);
   
    const hasNumber = /.*\d.*/.test(password);
  
    const messages = [];
    if (!hasEightCharacters) messages.push("at least 8 characters");  
    if (!hasSpecialCharacter) messages.push("one special character");
    if (!hasUpperCase) messages.push("one uppercase letter");
    if (!hasNumber) messages.push("one number");
  
    return {
      valid: hasEightCharacters && hasSpecialCharacter && hasUpperCase && hasNumber,
      message: `Password must include ${messages.join(", ")}.`,
    };
  };
  
  
  

  const passwordsMatch = (newPassword, confirmPassword) => {
    return newPassword === confirmPassword;
  };
  const validatePasswords = (newPassword, confirmPassword) => {
    const validationResult = isValidPassword(newPassword);
    if (!validationResult.valid) {
      setPasswordErrors(prev => ({
        ...prev,
        newPasswordError: validationResult.message,
      }));
    } else {
      setPasswordErrors(prev => ({ ...prev, newPasswordError: "" }));
    }
  
    const passwordMatchResult = passwordsMatch(newPassword, confirmPassword);
    setPasswordErrors(prev => ({
      ...prev,
      confirmPasswordError: passwordMatchResult ? "Passwords match." : "Passwords do not match.",
      match: passwordMatchResult
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
  
    setEditedProfile((prev) => ({
      ...prev,
      [name]: value,
    }));
  
    // Perform immediate validation for each field only after the user has typed something
    if (value.trim() !== "") {
      if (name === "new_password" || name === "new_password2") {
        validatePasswords(editedProfile.new_password, editedProfile.new_password2);
      }
    }
  };
  
 

    const redirectToProfile = () => {
      navigate('/profile');
    }
  useEffect(() => {
    fetchUser();

  }, []);

useEffect(() => {
  if (editedProfile.new_password && editedProfile.new_password2) {
    validatePasswords(editedProfile.new_password, editedProfile.new_password2);
  }
}, [editedProfile.new_password, editedProfile.new_password2]);

  const handleHeader = () => {
    setHeader((prev) => !prev);
  };

  const handleSideBar = () => {
    setSideBar((prev) => !prev);
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImagePreview(e.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      setImagePreview(null);
    }

    setUserImage(file);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="mobile-header-section">
            <div
              id="nav-icon4"
              className={!sideBar ? "hamburger" : "hamburger open"}
              onClick={handleSideBar}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
            <img src={logo} style={styles.logo} alt="Logo" />
            <div
              id="nav-icon3"
              onClick={handleHeader}
              className={!header ? "hamburger" : "hamburger open"}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>

          <div className="cc-page-wrapper">
            <Sidebar
              sideBar={sideBar}
              marginTopH6="mt3point5rem"
              margintop2rem="margintop2rem"
            />
            <div
              className="main-area-inner-wrapper"
              style={styles.mainAreaInnerWrapper}
            >
              <Header header={header} />

              <div
                className="new-page-content-wrapper mt-4"
                style={styles.contentWrapper}
              >
                <h1 className="site-heading" style={styles.siteHeading}>
                  Edit Profile
                </h1>

                <div className=" profile-picture-content-wrapper mt-4">
                  <form onSubmit={submitForm} style={styles.form}>
                    <div className="edit-profile-form-container">
                      <div className="row">
                        <div className="col-lg-6" style={styles.inputWrapper}>
                          <label htmlFor="firstName" style={styles.label}>
                            First Name
                          </label>
                          <input
                            type="text"
                            name="first_name"
                            onChange={handleInputChange}
                            className="signup-box-input"
                            value={editedProfile?.first_name}
                            placeholder="First Name"
                            id="firstName"
                            style={styles.input}
                          />
                          <small className="error-container"></small>
                        </div>
                        <div className="col-lg-6" style={styles.inputWrapper}>
                          <label htmlFor="lastName" style={styles.label}>
                            Last Name
                          </label>
                          <input
                            type="text"
                            name="last_name"
                            onChange={handleInputChange}
                            className="signup-box-input"
                            placeholder="Last Name"
                            id="lastName"
                            value={editedProfile?.last_name}
                            style={styles.input}
                          />
                          <small className="error-container"></small>
                        </div>
                        <div className="col-lg-12" style={styles.inputWrapper}>
                          <label htmlFor="email" style={styles.label}>
                            Email
                          </label>
                          <input
                            type="text"
                            name="email"
                            className="signup-box-input"
                            placeholder="Email"
                            id="email"
                            readOnly
                            value={editedProfile?.email}
                            onChange={handleInputChange}
                            onCopy={(e) => e.preventDefault()} // Prevent copying
                            onPaste={(e) => e.preventDefault()} // Prevent pasting
                            onCut={(e) => e.preventDefault()} // Prevent cutting
                            onMouseDown={(e) => e.preventDefault()} // Prevent mouse events for selection
                            style={{
                              ...styles.input,
                              backgroundColor: "#f5f5f5",
                              userSelect: "none", // Ensure this is applied
                              cursor: "not-allowed",
                            }}
                          />

                          <small className="error-container"></small>
                        </div>
                        <h3 className="mt-2 mb-3" style={styles.sectionHeading}>
                          Change Password
                        </h3>
                        <div className="col-lg-6" style={styles.inputWrapper}>
                          <label htmlFor="currentPassword" style={styles.label}>
                            Current Password
                          </label>
                          <input
                            type="password"
                            name="old_password"
                            onChange={handleInputChange}
                            className="signup-box-input"
                            placeholder="Current Password"
                            id="currentPassword"
                            style={styles.input}
                          />
                          <small className="error-container"></small>
                        </div>
                        <div className="col-lg-6" style={styles.inputWrapper}>
                          <label htmlFor="newPassword" style={styles.label}>
                            New Password
                          </label>
                          <input
                            type="password"
                            name="new_password"
                            onChange={handleInputChange}
                            className="signup-box-input"
                            placeholder="New Password"
                            id="newPassword"
                            style={{
                              ...styles.input,
                              borderColor: passwordErrors.newPasswordError
                                ? "red"
                                : "#ccc",
                            }}
                          />
                          <small style={{ color: "red" }}>
                            {passwordErrors.newPasswordError}
                          </small>
                        </div>
                        <div className="col-lg-6" style={styles.inputWrapper}>
                          <label
                            htmlFor="confirmNewPassword"
                            style={styles.label}
                          >
                            Confirm New Password
                          </label>
                          <input
                            type="password"
                            name="new_password2"
                            onChange={handleInputChange}
                            className="signup-box-input"
                            placeholder="Confirm New Password"
                            id="confirmNewPassword"
                            style={{
                              ...styles.input,
                              borderColor:
                                passwordErrors.confirmPasswordError &&
                                !passwordErrors.match
                                  ? "red"
                                  : passwordErrors.match
                                  ? "green"
                                  : "#ccc",
                            }}
                          />
                          <small
                            style={{
                              color: passwordErrors.match ? "green" : "red",
                            }}
                          >
                            {passwordErrors.confirmPasswordError}
                          </small>
                        </div>
                      </div>
                    </div>
                    <div
                      className="upload-img-cta-area d-flex"
                      style={styles.buttonContainer}
                    >
                      <button
                        type="button"
                        className="blue-button danger"
                        onClick={redirectToProfile}
                      >
                        Cancel
                      </button>
                      <button type="submit" className="blue-button">
                        Save
                      </button>
                    </div>
                  </form>

                  <div style={styles.profileImageContainer}>
                    <div style={styles.imageWrapper}>
                      <img
                        id="output"
                        className="preview_img"
                        src={imagePreview || profile?.image || userAvatar}
                        alt="Profile"
                        style={styles.profileImage}
                      />
                    </div>
                    <input
                      type="file"
                      id="file"
                      onChange={handleImageUpload}
                      style={styles.fileInput}
                    />
                    <label htmlFor="file" className="fileInputLabel">
                      Choose File...
                    </label>
                    <div style={styles.pictureButtonWrapper}>
                      <button
                        className="blue-button"
                        style={styles.uploadButton}
                      >
                        Upload Picture
                      </button>
                      <button
                        type="button"
                        className="blue-button danger"
                        style={styles.removeButton}
                      >
                        Remove Picture
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

const styles = {
  
  hamburger: {
    cursor: "pointer",
  },
  logo: {
    height: "60px",
  },
  pageWrapper: {
    display: "flex",
    flexDirection: "row",
    minHeight: "100vh",
  },
  mainAreaInnerWrapper: {
    flex: 1,
    padding: "20px",
    maxWidth: "1200px",
    margin: "0 auto",
  },
  contentWrapper: {
    backgroundColor: "#fff",
    padding: "20px",
   
    borderRadius: "8px",
  },
  siteHeading: {
    fontSize: "24px",
    color: "#333",
    marginBottom: "20px",
    textAlign: "center",
  },
  profilePictureContentWrapper: {
    display: "flex",
    flexDirection: "row",
    gap: "20px",
  },
  form: {
    flex: 1,
  },
  inputWrapper: {
    marginBottom: "15px",
  },
  label: {
    display: "block",
    marginBottom: "5px",
    fontWeight: "bold",
    color: "#555",
  },
  input: {
    width: "100%",
    padding: "5px",
    borderRadius: "5px",
    border: "1px solid #ccc",
  },
  sectionHeading: {
    fontSize: "20px",
    marginBottom: "15px",
    color: "#333",
    textAlign: "center",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems:"center",
    marginTop: "10px",
    width:"40%",
    
  },
  cancelButton: {
    padding: "5px 10px",
    width:"20%",
    borderRadius: "5px",
    backgroundColor: "#d9534f",
    color: "#fff",
    border: "none",
    cursor: "pointer",
  },
  saveButton: {
    padding: "5px 10px",
    borderRadius: "5px",
    width:"20%",
    backgroundColor: "#5bc0de",
    color: "#fff",
    border: "none",
    cursor: "pointer",
  },
  profileImageContainer: {
    width: "300px",
    textAlign: "center",
  },
  imageWrapper: {
    width: "200px",
    height: "200px",
    margin: "0 auto",
    borderRadius: "50%",
    overflow: "hidden",
    boxShadow: "0 0 10px rgba(0,0,0,0.1)",
    marginBottom: "10px",
  },
  profileImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  fileInput: {
    display: "none",
   
  },
  uploadButton: {
    padding: "10px",
    display: "block",
    width: "40%",
    marginBottom: "10px",
    backgroundColor: "#008dd0",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize:"14px"
  },
  removeButton: {
    padding: "10px",
    display: "block",
    width: "40%",
    backgroundColor: "#d9534f",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize:"14px"
  },
  pictureButtonWrapper:  {
    display: "flex",
    justifyContent:"center",
    alignItems:"center",
    flexDirection:"column"

  }

};

export default EditProfile;