import React, { useState, useEffect } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import { Link, useNavigate } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import Select_Simple from "../../components/multiSelect/Select_Simple.js";
import Select_Multiple from "../../components/multiSelect/Select_Multiple.js";
import DeleteData from "../../components/Save_Data/Delete_Data";
import { STAGGING_BACKEND, LOCAL_BACKEND } from "../../common/helper";
import MultiSelector from "../../components/multiselector/multiselector.js";
import QuestionAirTabs from "../../components/questionairTabs/questionairTabs.js";
import QuestionAirFormFooter from "../../components/questionAirFormFooter/questionAirFormFooter.js";
import DashboardLayout from "../../components/dashboardLayout/dashboardLayout.js";
import { saveToLocalStorage, loadFromLocalStorage, handleSwitchStatement, generateNamesArray, clearFromLocalStorage, navigateToNextQuestion, navigateToPreviousQuestion , shouldRenderSection  } from "../../libs/Util.js";
import { useLocation } from "react-router-dom"; 
import { navigateToPreviousOption, navigateToNextOption, getPageOptionsFromIndices } from "../../libs/Util";
import Loader from "../../components/Loader/Loader.js";
 
function ClinicalQuestion7() {
  const location = useLocation();

  const [lastOption, setLastOption] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [clearData, setClearData] = useState(false);
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedItems, setSelectedItems] = useState(() => {
    const saved = localStorage.getItem("molecular_profile_selected_options");
    return saved ? JSON.parse(saved) : [];
  });

  const navigate = useNavigate();
  const selector = useSelector((state) => state.options);

  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get("mode");
  const page = urlParams.get("page");
  const mode = urlParams.get("mode");
  const savedFilter = urlParams.get("checkByCustomNctIds");
  const optionsToShow = new URLSearchParams(location.search).get("optionsToShow");
  const pageOptions = getPageOptionsFromIndices(optionsToShow);
  let names = generateNamesArray(mode, pageOptions, savedFilter, page, optionsToShow);

  const OnSubmitForm = async () => {
    try {
      setIsLoading(true)
      const url = STAGGING_BACKEND + "cancer/questionair/cancer_type/";
      const payload = {
        mutations: selectedItems,
      };
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "token " + localStorage.getItem("token"),
        },
        body: JSON.stringify(payload),
      };
      const response = await fetch(url, requestOptions);
      const responseData = await response.json();
      navigate(
        `/clinical-question8?mode=full_options${
          page === "ccTreatmentGuide" ? "&page=ccTreatmentGuide" : ""
        }`
      );
      if (mode != "full_options") {
        navigateToNextOption(navigate, '6', mode, optionsToShow);
      } else {
        navigate(
          `/clinical-question8?mode=full_options${
            page === "ccTreatmentGuide" ? "&page=ccTreatmentGuide" : ""
          }${savedFilter === "true" ? "&checkByCustomNctIds=true" : ""}`
        );
      }  
    } catch (error) {
      console.log('error', error)
    } finally {
      setIsLoading(false);
    }
    
  };

 

  useEffect(() => {
    async function fetchData() {
      const url = STAGGING_BACKEND + "cancer/create/";

      const payload = {
        filter: "molecular",
      };

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "token " + localStorage.getItem("token"),
        },
        body: JSON.stringify(payload),
      };

      const response = await fetch(url, requestOptions);
      const responseData = await response.json();
      console.log(responseData);
      const dataa = responseData.data;
      const uniqueArray = dataa?.filter((value, index) => {
        return dataa.indexOf(value) === index;
      });
      setData(uniqueArray);

      const get_opt = window.localStorage.getItem(
        "molecular_profile_selected_options"
      );
      if (get_opt) {
        console.log("get_optttt****", JSON.parse(get_opt));

        Render_At_Load(JSON.parse(get_opt));
      }
    }
    fetchData();
  }, []);

  const filteredData = data.filter((item) =>
    item.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    let array = names.map((item) => {
      return item.text;
    });
    Check_Last_Index(array);
  }, []);

  function Check_Last_Index(array) {
    const lastIndex = array[array.length - 1];
    console.log("last item is", lastIndex);
    if (lastIndex == "Molecular Profile") setLastOption(true);
  }

  function Render_At_Load(selected_items) {
    setSelectedItems(selected_items);
  }

  const OnSubmitForm2 = async () => {
    const url = STAGGING_BACKEND + "cancer/questionair/cancer_type/";
    const payload = {
      mutations: selectedItems,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "token " + localStorage.getItem("token"),
      },
      body: JSON.stringify(payload),
    };
    const response = await fetch(url, requestOptions);
    const responseData = await response.json();

    console.log(responseData);
    navigate(
      page === "ccTreatmentGuide"
        ? "/cc-treatment-plans"
        : `/stats-drugs${
            savedFilter === "true" ? "?checkByCustomNctIds=true" : ""
          }`
    );
  };

  function HandleBackBtn() {
    if (mode != "full_options") {
      navigateToPreviousOption(navigate, '6', mode, optionsToShow);
    } else {
      navigate(
        `/clinical-question6?mode=full_options${
          page === "ccTreatmentGuide" ? "&page=ccTreatmentGuide" : ""
        }${savedFilter === "true" ? "&checkByCustomNctIds=true" : ""}`
      );
    }
  }
  const handleClearCancel = () => {
    setClearData(false);
  };
  const handleClearConfirm = () => {
    const li_s = document.querySelectorAll("#multiSelectList li");
    li_s.forEach((li) => {
      li.classList.remove("selected_li");
    });
    const selected_tags = document.querySelectorAll(
      "#select-tags-container div"
    );
    selected_tags.forEach((item) => {
      item.remove();
    });
    setClearData(false);
  };

  const handleSelectionChange = (newSelectedItems) => {
    setSelectedItems(newSelectedItems);
    localStorage.setItem(
      "molecular_profile_selected_options",
      JSON.stringify(newSelectedItems)
    );
  };

  return (
    <>
    {
      isLoading && <Loader/>
    }
      <DashboardLayout page="ccTreatmentGuide">
        <h1 className="text-center site-heading">
          {page === "ccTreatmentGuide"
            ? "CC Treatment Guide"
            : "Clinical Trial Finder"}
        </h1>
        <QuestionAirTabs names={names} currentTab="6" />
        <div className="activeSection-wrapper">
          <div className="question-form-wrapper molecular-profile-question">
            <form action="">
              <h2>Molecular Profile</h2>
              <p>Please input the following data</p>
              <MultiSelector
                options={filteredData}
                initialSelectedItems={selectedItems}
                onChange={handleSelectionChange}
                label="What Mutations Are Present In Your Cancer?"
              />
              <QuestionAirFormFooter
                handleBack={HandleBackBtn}
                handleSubmit={OnSubmitForm}
                handleSubmit2={OnSubmitForm2}
                handleClear={() => setClearData(true)}
                isLastOption={lastOption}
              />
            </form>
          </div>
        </div>
      </DashboardLayout>

      {clearData && (
        <DeleteData
          onCancel={handleClearCancel}
          onConfirm={handleClearConfirm}
        />
      )}
    </>
  );
}

export default ClinicalQuestion7;
