export const booleanOptions = [
    { value: "", label: "choose below" },
    { value: "1", label: "yes" },
    { value: "0", label: "no" },
  ];

  export const drugOptions = [
    { value: "", label: "choose below" },
    { value: "drug1", label: "Drug1" },
    { value: "drug2", label: "Drug2" },
  ];

  export const mechanismOptions = [
    { value: "", label: "choose below" },
    { value: "mechanism1", label: "Mechanism1" },
    { value: "mechanism2", label: "Mechanism2" },
  ];
  
  export const ecogStatusOptions = [
    { value: "", label: "choose below" },
    { value: "0", label: "ECOG 0" },
    { value: "1", label: "ECOG 1" },
    { value: "2", label: "ECOG 2" },
    { value: "3", label: "ECOG 3" },
    { value: "4", label: "ECOG 4" },
    { value: "I dont Know", label: "I dont Know" },
  ];

  export const karnoskyScoreOptions = [
    { value: "", label: "choose below" },
    { value: "100", label: "KPS 100" },
    { value: "90", label: "KPS 90" },
    { value: "80", label: "KPS 80" },
    { value: "70", label: "KPS 70" },
    { value: "60", label: "KPS 60" },
    { value: "50", label: "KPS 50" },
    { value: "40", label: "KPS 40" },
    { value: "30", label: "KPS 30" },
    { value: "20", label: "KPS 20" },
    { value: "10", label: "KPS 10" },
    { value: "I dont Know", label: "I dont Know" },
  ];


  export const trialTypeOptions = [
    { value: "", label: "choose below" },
    { value: "Behavioral", label: "Behavioral" },
    { value: "Biological device", label: "Biological" },
    { value: "Combination Product", label: "Combination Product" },
    { value: "Device", label: "Device" },
    { value: "Diagnostic Test", label: "Diagnostic Test" },
    { value: "Dietary Supplement", label: "Dietary Supplement" },
    { value: "Drug", label: "Drug" },
    { value: "Other", label: "Other" },
    { value: "Procedure", label: "Procedure" },
    { value: "Radiation", label: "Radiation" },
  ];

  export const phaseTrialOptions = [
    { value: "", label: "choose below" },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "any", label: "any" },
  ];
  export const trialStatusOptions = [
    { value: "", label: "choose below" },
    { value: "Enrolling by invitation", label: "Enrolling by invitation" },
    { value: "Unknown status", label: "Unknown status" },
    { value: "Withdrawn", label: "Withdrawn" },
    { value: "Completed", label: "Completed" },
    { value: "Recruiting", label: "Recruiting" },
    { value: "Terminated", label: "Terminated" },
    { value: "Enrolling by invitation", label: "Enrolling by invitation" },
    { value: "Not yet recruiting", label: "Not yet recruiting" },
    { value: "Active, not recruiting", label: "Active, not recruiting" },
  ];



  export const toptions = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Neither", label: "Neither" },
    { value: null, label: "I prefer not to say" },
  ];

  export const pregnantOptions = [
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
    { value: null, label: "I prefer not to say" },
  ];


  
export const breastFeedingOptions = [
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
    { value: null, label: "I prefer not to say" },
  ];
  export const menopausalOptions = [
    { value: "postmenopausal", label: "Postmenopausal" },
    { value: "premenopausal", label: "Premenopausal" },
    { value: "perimenopausal", label: "Perimenopausal" },
    { value: null, label: "I prefer not to say" },
  ];

  
export const raceOptions = [
    { value: "American Indian", label: "American Indian" },
    { value: "Black or African American", label: "Black or African American" },
    { value: "Asian", label: "Asian" },
    { value: "Alaska Native", label: "Alaska Native" },
    { value: "Latino or Hispanic", label: "Latino or Hispanic" },
    {
      value: "Native Hawaiian or Other Pacific Islander",
      label: "Native Hawaiian or Other Pacific Islander",
    },
    { value: "White or Caucasian", label: "White or Caucasian" },
    { value: null, label: "unspecified" },
  ];
   

export const tumorTypesSelective = ["Breast Cancer", "Ovarian Cancer",
  "endometrial cancer", "Fallopian Tube Cancer", "Primary Peritoneal Cancer"]

  export const HER2Options = [
  { value: "Positive (IHC 3+)", label: "Positive (IHC 3+)" },
  { value: "Positive (IHC 2+ and FISH+)", label: "Positive (IHC 2+ and FISH+)" },
  { value: "HER2 low/negative (IHC 2+ and FISH-)", label: "HER2 low/negative (IHC 2+ and FISH-)" },
  { value: "HER low/negative (IHC 1+)", label: "HER low/negative (IHC 1+)" },
  { value: "HER2 negative (IHC 0)", label: "HER2 negative (IHC 0)" },
]
export const dMMROptions = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
  { value: null, label: "I'm unsure" },
]

export const  progesteroneReceptorOptions = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
  { value: null, label: "I am not Sure" },
]

export const  estrogenReceptorOptions = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
  { value: null, label: "I am not Sure" },
]


export const questionAirOptionCards = [
  { id: 0, name: "Cancer Stage & Options", page: 'clinical-questions', tab: '0' },
  { id: 1, name: "Patient Characteristics", page: 'clinical-question2', tab: '1'  },
  { id: 2, name: "Location", page: 'clinical-question3' , tab: '2' },
  { id: 3, name: "Trial Type & Sponsor", page: 'clinical-question4', tab: '3'},
  { id: 4, name: "Clinical Characteristics", page: 'clinical-question5', tab: '4'  },
  { id: 5, name: "Cancer Characteristics", page: 'clinical-question6', tab: '5'  },
  { id: 6, name: "Molecular Profile" , page: 'clinical-question7', tab: '6' },
  { id: 7, name: "Cancer Treatment History" , page: 'clinical-question8', tab: '7' },
  { id: 8, name: "Drug Name & Mechanism of Action", page: 'clinical-question9', tab: '8'  },
];
