import React, { useState, useEffect } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link, useNavigate } from "react-router-dom";
import SaveData from "../../components/Save_Data/Save_Data";
import RenderData from "../../components/Save_Data/Render_Data";
import { STAGGING_BACKEND, LOCAL_BACKEND } from "../../common/helper";
import { useDispatch, useSelector } from "react-redux";
import DeleteData from "../../components/Save_Data/Delete_Data";
import Select from "react-select";
import DashboardLayout from "../../components/dashboardLayout/dashboardLayout";
import QuestionAirFormFooter from "../../components/questionAirFormFooter/questionAirFormFooter";
import QuestionAirTabs from "../../components/questionairTabs/questionairTabs";
import { saveToLocalStorage, loadFromLocalStorage, clearFromLocalStorage, navigateToNextQuestion, navigateToPreviousQuestion, shouldRenderSection, handleSwitchStatement, navigateToPreviousOption } from "../../libs/Util.js";
import { booleanOptions, drugOptions, mechanismOptions } from "../../libs/optionsHandling.js";
import { generateNamesArray } from "../../libs/Util.js";
import Loader from "../../components/Loader/Loader.js";
import MoaSearchDropdown from "./questions/mechanismOfActionDropdown.js";
import DrugSearchDropdown from "./questions/drugDropdown";
import DeviceSearchDropdown from "./questions/deviceDropdown";
import { useLocation } from "react-router-dom";
import { questionAirOptionCards } from "../../libs/optionsHandling";

const useForceRender = () => {
  const [value, setValue] = useState(0);
  return () => setValue((val) => val + 1);
};

function ClinicalQuestion9() {
  const [lastOption, setLastOption] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDrug, setIsDrug] = useState({ value: "", label: "choose below" });
  const [isDevice, setIsDevice] = useState({ value: "", label: "choose below" });
  const [isMechanism, setIsMechanism] = useState({
    value: "",
    label: "choose below",
  });
  const location = useLocation();
  const [drug, setDrug] = useState("");
  const [mechanism, setMechanism] = useState("");
  const [device, setDevice] = useState(null);
  const [deviceOptions, setDeviceOptions] = useState([
    { value: "", label: "choose below" },
  ]);
  const [saveDataState, setSaveDataState] = useState(false);
  const [clearData, setClearData] = useState(false);
  const [importDataState, setImportDataState] = useState(false);

  const selector = useSelector((state) => state.options);
  const navigate = useNavigate();   

  const urlParams = new URLSearchParams(window.location.search);
  const page = urlParams.get("page");
  const mode = urlParams.get("mode");
  const savedFilter = urlParams.get("checkByCustomNctIds");
  const optionsToShow = new URLSearchParams(location.search).get("optionsToShow");
  const indicesToShow = optionsToShow ? optionsToShow.split(",").map(Number) : [];

  const pageOptions = indicesToShow
    .map((index) => {
      const card = questionAirOptionCards.find((card) => card.id === index);
      return card ? card.name.replace(/ & /g, "_").replace(/ /g, "_") : null; 
    })
    .filter((name) => name !== null);  

  let names = generateNamesArray(mode, pageOptions, savedFilter, page, optionsToShow);

  const OnSubmitForm = async () => {
   
    if (mode != "full_options") {
      let array = names.map((item) => {
        return item.text;
      });
      console.log("names", array);
      const itemToFind = "Drug & Mechanism";
      const currentIndex = array.indexOf(itemToFind);
      const nextIndex = (currentIndex + 1) % array.length;
      const nextItem = array[nextIndex];
      console.log("98773e4", nextItem);
     
    } else {
      navigate(
        `/clinical-question3?mode=full_options${
          page === "ccTreatmentGuide" ? "&page=ccTreatmentGuide" : ""
        }`
      );
    }
    // navigate(`/clinical-question3?mode=full_options${page === 'ccTreatmentGuide'  ?  '&page=ccTreatmentGuide'  :  ''}`);
  };

  const forceUpdate = useForceRender();

  const OnSubmitForm2 = async () => {
    try {
      setIsLoading(true);
      const url = STAGGING_BACKEND + "cancer/questionair/cancer_type/";
      const payload = {
        drug: drug,
        moa: mechanism,
        medical_device: device,
      };
      console.log(payload)
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "token " + localStorage.getItem("token"),
        },
        body: JSON.stringify(payload),
      };
      const response = await fetch(url, requestOptions);
      const responseData = await response.json();

      console.log(responseData);
      navigate(
        page === "ccTreatmentGuide"
          ? "/cc-treatment-plans"
          : `/stats-drugs${savedFilter === "true" ? "?checkByCustomNctIds=true" : ""}`
      );
    } catch (error) {
      console.log('error', error)
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // Retrieve the drug and device from local storage
    const storedDrug = JSON.parse(localStorage.getItem('selectedDrug'));
    const storedDevice = JSON.parse(localStorage.getItem('selectedDevice'));
    const storedMechanism = JSON.parse(localStorage.getItem('selectedMechanism'));

    if (storedDrug) {
      setDrug(storedDrug.value);
      setIsDrug({ value: "1", label: storedDrug.label });
    }
    if(storedMechanism){
      setMechanism(storedMechanism.value);
      setIsMechanism({ value: "1", label: storedMechanism.label })
    }
    if (storedDevice) {
      setDevice(storedDevice.value);
      setIsDevice({ value: "1", label: storedDevice.label });  
    }

    let array = names.map((item) => item.text);
    Check_Last_Index(array);
    setImportDataState(true);

    let select_array = [
      { title: "Drug_Mechanism_is_drug", options: booleanOptions },
      { title: "Drug_Mechanism_mechanism", options: booleanOptions },
      { title: "Drug_Mechanism_is_med_device", options: booleanOptions },
    ];
    select_array.forEach((item) => {
      Import_Save_Data(item.title, item.options);
    });
  }, []);

  function Import_Save_Data(title, options) {
    const genderOpt = window.localStorage.getItem(title);
    if (genderOpt) {
      const result = options.find((option) => option.value === genderOpt);
      if (result) {
        switch (title) {
          case "Drug_Mechanism_is_drug":
            setIsDrug(result);
            break;
          case "Drug_Mechanism_mechanism":
            setIsMechanism(result);
            break;
          case "Drug_Mechanism_is_med_device":
            setIsDevice(result);
            break;
          default:
            break;
        }
      }
    }
  }

  useEffect(() => {
    setSaveDataState(true);
    Save_Select_Data();
  }, [mechanism, drug, isMechanism, isDrug , isDevice]);

  function Save_Select_Data() {
    if (isDrug)
      window.localStorage.setItem(`Drug_Mechanism_is_drug`, isDrug.value);
    if (isMechanism)
      window.localStorage.setItem(
        `Drug_Mechanism_mechanism`,
        isMechanism.value
      );
    if (isDevice)
      window.localStorage.setItem(
        `Drug_Mechanism_is_med_device`,
        isDevice.value
      );
  }

  function Check_Last_Index(array) {
    const lastIndex = array[array.length - 1];
    console.log("last item is", lastIndex);
    if (lastIndex == "Drug Name & Mechanism of Action" || "Drug Name Mechanism of Action") setLastOption(true);
  }

  function HandleBackBtn() {
    if (mode != "full_options") {
      navigateToPreviousOption(navigate, "8", mode, optionsToShow);
    } else {
      navigate(
        `/clinical-question8?mode=full_options${
          page === "ccTreatmentGuide" ? "&page=ccTreatmentGuide" : ""
        }
      ${savedFilter === "true" ? "&checkByCustomNctIds=true" : ""}`
      );
    }
  }

  const handleClearCancel = () => {
    setClearData(false);
  };

  const handleClearConfirm = () => {
    setIsDrug("");
    setIsMechanism("");
    setIsDevice("");
    setClearData(false);
    setDrug("");
    setMechanism("");
    setDevice("");
  };

  const handleMoaSelect = (moa) => {
    setMechanism(moa.value);
  };

  const handleDrugSelect = (drug) => {
    setDrug(drug.value);
  };

  const handleDeviceSelect = (device) => {
    setDevice(device.value);
  };

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      zIndex: 50 
    })
  };

  const customMechanismStyles = {
    menu: (provided) => ({
      ...provided,
      zIndex: 30000 
    })
  };

  const handleMenuOpen = () => {
    const container = document.querySelector('.question-form-wrapper');
    if (container) {
      setTimeout(() => {
        if (container.scrollHeight > container.clientHeight) {
          container.scrollTop = container.scrollHeight - container.clientHeight;
        }
      }, 150); 
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <DashboardLayout>
        <div className="clinical-question-main-area mt-4">
          <h1 className="text-center site-heading">
            {page === "ccTreatmentGuide"
              ? "CC Treatment Guide"
              : "Clinical Trial Finder"}
          </h1>
          <QuestionAirTabs names={names} currentTab="8" />

          <div className="activeSection-wrapper">
            <div className="question-form-wrapper">
              <form action="">
                <h2>Drug name & Mechanism of Action</h2>
                <p>Please input the following data</p>
                <div className="form_container_sec">
                  <label htmlFor="">
                    Is there a particular drug you're interested in?{" "}
                  </label>

                  <Select
                    options={booleanOptions}
                    value={isDrug}
                    className="select_search"
                    id="is_drug"
                    onChange={(event) => {
                      setIsDrug(event);
                    }}
                    styles={{
                      menu: (base) => ({
                        ...base,
                        zIndex: 1000 
                      })
                    }}
                  />

                  {isDrug.value == "1" ? (
                    <>
                      <label htmlFor="">
                        Which drug would you like to know about?{" "}
                      </label>
                      <DrugSearchDropdown onDrugSelect={handleDrugSelect} />
                    </>
                  ) : null}

                  <label htmlFor="">Are you interested in a specific medical device? </label>
                  <Select
                    options={booleanOptions}
                    value={isDevice}
                    className="select_search"
                    id="is_interested_in_med_device"
                    onChange={(event) => {
                      setIsDevice(event);
                    }}
                    onMenuOpen={() => {
                      handleMenuOpen();
                    }}
                    menuPlacement="auto"
                    styles={{
                      menu: (base) => ({
                        ...base,
                        zIndex: 1000 
                      })
                    }}
                  />
                  {isDevice.value === "1" ? (
                    <>
                      <label htmlFor="">Which Medical Device are you interested in? </label>
                      <DeviceSearchDropdown onDeviceSelect={handleDeviceSelect} />
                    </>
                  ) : null}

                  <label htmlFor="">
                    Is there a particular mechanism of action you're interested
                    in?{" "}
                  </label>

                  <Select
                    options={booleanOptions}
                    value={isMechanism}
                    className="select_search"
                    id="mechanism"
                    onChange={(event) => {
                      setIsMechanism(event);
                    }}
                    onMenuOpen={() => {
                      handleMenuOpen();
                    }}
                    menuPlacement="auto"
                    styles={{
                      menu: (base) => ({
                        ...base,
                        zIndex: 1000 
                      })
                    }}
                  />

                  {isMechanism.value == "1" ? (
                    <>
                      <label htmlFor="">
                        Which mechanism of action are you interested in?{" "}
                      </label>
                      <MoaSearchDropdown onMoaSelect={handleMoaSelect} />
                    </>
                  ) : null}
                </div>
                <QuestionAirFormFooter
                  handleBack={HandleBackBtn}
                  handleSubmit={OnSubmitForm}
                  handleSubmit2={OnSubmitForm2}
                  handleClear={() => setClearData(true)}
                  isLastOption={lastOption}
                />
              </form>
            </div>
          </div>
        </div>
      </DashboardLayout>

      {clearData && (
        <DeleteData
          onCancel={handleClearCancel}
          onConfirm={handleClearConfirm}
          feilds={["is_drug", "which_drug", "mechanism", "which_mechanism"]}
        />
      )}
    </>
  );
}

export default ClinicalQuestion9;
