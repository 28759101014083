import React, { useEffect, useState } from "react";
import logo from "../../images/logo.png";
import birds from "../../images/birds.png";
import leafs from "../../images/leafs.png";
import "font-awesome/css/font-awesome.min.css";
import Sidebar from "../../components/sidebar/Sidebar";
import Header from "../../components/Header/Header";
import heart from '../../images/heart.svg';
import save from "../../images/save.png"
import download from '../../images/download.svg'
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { LOCAL_BACKEND, STAGGING_BACKEND } from "../../common/helper";
import { useLocation } from "react-router-dom";
import html2pdf from 'html2pdf.js';


const Results = () => {

    const [header, setHeader] = useState(false);
    const [sideBar, setSideBar] = useState(false);
    const [trialData, setTrialData] = useState({});
    const [render, setRender] = useState(false)
    const location = useLocation();



    function handleHeader() {
        setHeader((t) => !t);
    }
    function handleSideBar() {
        setSideBar((t) => !t);
    }

    const HandleSingleSave = async (nct_id) => {
        const url = STAGGING_BACKEND + "cancer/trialdetails/"
        const payload = {
            nct_id: nct_id,
            export: "save"
        }
        const requestOptions = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'token ' + localStorage.getItem("token"),

            },
            body: JSON.stringify(payload)
        }
        const response = await fetch(url, requestOptions);
        const responseData = await response.json()
        console.log(responseData.data)
        setRender(!render);
    }

    const HandleUnSave = async (nct_id) => {
        const url = STAGGING_BACKEND + "cancer/unsave/"
        const payload = {
            nct_id: nct_id,
        }
        const requestOptions = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'token ' + localStorage.getItem("token"),

            },
            body: JSON.stringify(payload)
        }
        const response = await fetch(url, requestOptions);
        const responseData = await response.json()
        console.log(responseData.data)
        setRender(!render);
    }

    useEffect(async () => {
        const url = STAGGING_BACKEND + "cancer/trialdetails/"
        const payload = {
            nct_id: location?.state?.nct_id
        }
        const requestOptions = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'token ' + localStorage.getItem("token"),

            },
            body: JSON.stringify(payload)
        }
        const response = await fetch(url, requestOptions);
        const responseData = await response.json();
        console.log(responseData);
        setTrialData(responseData.data.slice(-1)[0]);
    }, [render])


    const handleExportPDF = (trialID) => {
        const element = document.getElementById('study');
        const opt = {
            margin: 10,
            filename: `Trial-${trialID}.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        };

        html2pdf().from(element).set(opt).save();
    };

    return (
        <>
            <div className="mobile-header-section">
                <div
                    id="nav-icon4"
                    className={!sideBar ? "hamburger" : "hamburger open"}
                    onClick={handleSideBar}
                >
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <img src={logo} style={{ height: "60px" }} alt="" />
                <div
                    id="nav-icon3"
                    onClick={handleHeader}
                    className={!header ? "hamburger" : "hamburger open"}
                >
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>

            <div className="contact-section-wrapper">
                <Sidebar
                    sideBar={sideBar}
                    marginTopH6="mt3point5rem"
                    margintop2rem="margintop2rem"
                />
                <div className="contact-inner-parent">
                    <Header header={header} />

                    <div className="contact-form-main-area account-form-main-area" id="export">
                        <div className="w-100 mt-2">
                            <h1 class="text-center site-heading">Clinical Trial Finder</h1>

                            <div className="nct-number-row">
                                <p>{trialData?.OverallStatus}</p>
                                <div className="nct-number-container">
                                    <p>{trialData?.NCTId}</p>
                                    <img src={download} className="drugstableIcon" alt="" onClick={() => handleExportPDF(trialData.NCTId)} />
                                    {trialData.saved ? (
                                        <img src={save} className="drugstableIcon" alt="" onClick={() => {
                                            HandleUnSave(trialData.NCTId)
                                        }} />
                                    ) : <img src={heart} className="drugstableIcon" alt="" onClick={() => {
                                        HandleSingleSave(trialData.NCTId)
                                    }} />}
                                </div>
                            </div>
                            <div className="result-section">
                                <h1>{trialData?.BriefTitle}</h1>
                                <p className="blue_txt">Information Provided by: </p>
                                {/* <p className="blue_txt"><b>Last Updated:</b>, December 2014</p> */}

                                <div className="results-tab-container">

                                    <Tabs>
                                        <TabList>
                                            <div className="result-tab-listing-area">
                                                <Tab>Study</Tab>
                                                {/* <Tab>Results Submitted</Tab> */}
                                            </div>
                                        </TabList>

                                        <TabPanel>

                                            <div className="study-tab-section">
                                                <Tabs>
                                                    <TabList>
                                                        <div className="study-tab-listing">
                                                            <Tab>Study Overview</Tab>
                                                            <Tab>Contacts and Locations</Tab>
                                                            <Tab>Participation Criteria</Tab>
                                                            <Tab>Study Plan</Tab>
                                                            <Tab>Collaborators and Investigators</Tab>
                                                        </div>
                                                    </TabList>
                                                    <TabPanel>
                                                        <div className="study-tab-content-detail-area" id="study">
                                                            <h2 className="subheading-result-tab-content">Study Overview</h2>
                                                            <b>Brief Summary</b>
                                                            <p>{trialData?.BriefSummary}</p>

                                                            <br />

                                                            <b>Detailed Description</b>
                                                            <p className="mb-4">{trialData?.DetailedDescription}</p>
                                                            {/* *********** */}
                                                            <h6>Official Title</h6> <hr style={{ margin: '0' }} />
                                                            <p>{trialData?.OfficialTitle}</p>

                                                            <div className="tableview-results-parent mt-5">
                                                                <div>
                                                                    <h6>Conditions</h6> <hr style={{ margin: '0' }} />
                                                                    <p>{trialData?.Condition}</p>
                                                                    {/* <p>Acute: <b>Myocardial Infarction</b>.</p> */}
                                                                </div>
                                                                <div>
                                                                    <h6>Subtype</h6> <hr style={{ margin: '0' }} />
                                                                    <p>{trialData?.StudyType}</p>
                                                                </div>
                                                                <div>
                                                                    <h6>Deployment (ACTUAL)</h6> <hr style={{ margin: '0' }} />
                                                                    <p></p>
                                                                </div>

                                                                <div>
                                                                    <h6>INTERVENTION / TREATMENT</h6> <hr style={{ margin: '0' }} />
                                                                    <p>Drug: {trialData?.Drugs}</p>
                                                                    <p>Intervention Name: {trialData?.InterventionName}</p>
                                                                    <p>Intervention Description: {trialData?.InterventionDescription}</p>
                                                                    <p>Intervention Type: {trialData?.InterventionType}</p>

                                                                </div>

                                                                <div>
                                                                    <h6>PHASE</h6> <hr style={{ margin: '0' }} />
                                                                    {trialData?.Phase?.map((value, index) => {
                                                                        console.log(value)
                                                                        return <p>{value}</p>
                                                                    })}

                                                                </div>
                                                                <div>
                                                                    <h6>Other Study ID Numbers</h6> <hr style={{ margin: '0' }} />
                                                                    <p>{trialData?.OrgStudyId}</p>
                                                                </div>

                                                                <div>
                                                                    <h6>Study Start</h6> <hr style={{ margin: '0' }} />
                                                                    <p>{trialData?.StudyFirstSubmitDate}</p>
                                                                </div>
                                                                <div>
                                                                    <h6>Primary Completion (ACTUAL)</h6> <hr style={{ margin: '0' }} />
                                                                    <p>{trialData.PrimaryCompletionDate}</p>
                                                                </div>
                                                                <div>
                                                                    <h6>Study Completion (ACTUAL)</h6> <hr style={{ margin: '0' }} />
                                                                    <p>{trialData?.StudyFirstPostDate}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </TabPanel>

                                                    <TabPanel>
                                                        <div className="study-tab-content-detail-area">

                                                            <div className="tableview-results-parent mt-5">
                                                                <div>
                                                                    <h6>Contact:</h6> <hr style={{ margin: '0px' }} />
                                                                    {trialData?.CentralContact?.map((value, index) => {
                                                                        return (
                                                                            <>
                                                                                <p>Name: <b>{value.CentralContactName}</b>.</p>
                                                                                <p>Email: <b>{value.CentralContactEMail}</b>.</p>
                                                                                <p>Phone: <b>{value.CentralContactPhone}</b>.</p>
                                                                            </>
                                                                        )
                                                                    })}

                                                                </div>
                                                                <div>
                                                                    <h6>Location</h6> <hr style={{ margin: '0' }} />
                                                                    {trialData?.Location?.map((value, index) => {
                                                                        return (
                                                                            <>
                                                                                <p>LocationCountry: <b>{value.LocationCountry}</b>.</p>
                                                                                <p>City: <b>{value.LocationCity}</b>.</p>
                                                                                <p>Location Facility: <b>{value.LocationFacility}</b>.</p>
                                                                                <p>Location State: <b>{value.LocationState}</b>.</p>
                                                                                <p>Location Status: <b>{value.LocationStatus}</b>.</p>
                                                                                <p>Location Zip: <b>{value.LocationZip}</b>.</p>
                                                                            </>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </TabPanel>

                                                    <TabPanel>
                                                        <div className="study-tab-content-detail-area">
                                                            <h2 className="subheading-result-tab-content">Participation Criteria</h2>
                                                            <b>Criteria: </b>
                                                            <p>{trialData?.inclusion_criteria}</p>
                                                            <br />

                                                            {/* {/* <b>Detailed Description</b>
                                                            <p className="mb-4">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Accusantium totam architecto pariatur aspernatur vero iusto laboriosam soluta, nemo iure tempora quaerat ratione ullam nihil! Impedit qui sunt sint, est quae praesentium cupiditate amet non hic.</p>
                                                            <h6>Official Title</h6> <hr style={{ margin: '0' }} />
                                                            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. At expedita, tempora reiciendis commodi hic qui culpa possimus quos quaerat praesentium.</p>

                                                            <div className="tableview-results-parent mt-5">
                                                                <div>
                                                                    <h6>Conditions</h6> <hr style={{ margin: '0' }} />
                                                                    <p>Heat Disease: <b>Myocardial Infarction</b>.</p>
                                                                    <p>Acute: <b>Myocardial Infarction</b>.</p>
                                                                </div>
                                                                <div>
                                                                    <h6>Subtype</h6> <hr style={{ margin: '0' }} />
                                                                    <p>Interventional.</p>
                                                                </div>
                                                                <div>
                                                                    <h6>Deployment (ACTUAL)</h6> <hr style={{ margin: '0' }} />
                                                                    <p>254</p>
                                                                </div>

                                                                <div>
                                                                    <h6>INTERVENTION / TREATMENT</h6> <hr style={{ margin: '0' }} />
                                                                    <p>Drug: Retavase 10UI Bolus.</p>
                                                                    <p>Procedure:Angioplasty Heart Bolus.</p>
                                                                    <p>Device: Drug Eluting Stent placed in heart attack related artery.</p>
                                                                </div>

                                                                <div>
                                                                    <h6>PHASE</h6> <hr style={{ margin: '0' }} />
                                                                    <p>Phase 4</p>
                                                                </div>
                                                                <div>
                                                                    <h6>Other Study ID Numbers</h6> <hr style={{ margin: '0' }} />
                                                                    <p>PATCAR Pilot trial HSC 03234</p>
                                                                </div>

                                                                <div>
                                                                    <h6>Study Start</h6> <hr style={{ margin: '0' }} />
                                                                    <p>November 2003</p>
                                                                </div>
                                                                <div>
                                                                    <h6>Primary Completion (ACTUAL)</h6> <hr style={{ margin: '0' }} />
                                                                    <p>March 2014</p>
                                                                </div>
                                                                <div>
                                                                    <h6>Study Completion (ACTUAL)</h6> <hr style={{ margin: '0' }} />
                                                                    <p>March 2014</p>
                                                                </div> 
                                                             </div> */}
                                                        </div>
                                                    </TabPanel>
                                                    <TabPanel>
                                                        <div className="study-tab-content-detail-area">
                                                            <h2 className="subheading-result-tab-content">Study Plan</h2>
                                                            <b>Brief Summary</b>
                                                            {trialData?.ArmGroup?.map((value, index) => {
                                                                return (
                                                                    <>
                                                                        <p><b>Study {index + 1}</b></p>
                                                                        <p>{value.ArmGroupDescription}</p>
                                                                    </>
                                                                )
                                                            })}

                                                            <br />

                                                            <b>Label</b>
                                                            {trialData?.ArmGroup?.map((value, index) => {
                                                                return (
                                                                    <>
                                                                        <p>Study {index + 1} </p>
                                                                        <span>{value.ArmGroupLabel}</span>
                                                                    </>
                                                                )
                                                            })}

                                                            <p style={{ marginTop: "10px" }}><b>Type</b></p>
                                                            {trialData?.ArmGroup?.map((value, index) => {
                                                                return (
                                                                    <>
                                                                        <p>Study {index + 1} </p>
                                                                        <span>{value.ArmGroupType}</span>
                                                                    </>
                                                                )
                                                            })}
                                                            <div className="tableview-results-parent mt-5">
                                                                <div>
                                                                    <h6>Intervention Name</h6> <hr style={{ margin: '0' }} />
                                                                    {trialData?.ArmGroup?.map((value, index) => {
                                                                        return (
                                                                            <>
                                                                                {value.ArmGroupInterventionList.ArmGroupInterventionName.map((nestedValue, nestedIndex)=> {
                                                                                    return <p>{nestedValue}</p>
                                                                                })}
                                                                                
                                                                            </>
                                                                        )
                                                                    })}
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </TabPanel>

                                                    <TabPanel>
                                                        <div className="study-tab-content-detail-area">
                                                            <h2 className="subheading-result-tab-content">Collaborators & Investigations</h2>
                                                            <div className="tableview-results-parent mt-5">
                                                                <div>
                                                                    <h6>Investigators</h6> <hr style={{ margin: '0' }} />

                                                                    <p>Type: <b>{trialData?.ResponsiblePartyType}</b></p>
                                                                    <p>Affliliation: <b>{trialData?.ResponsiblePartyInvestigatorAffiliation}</b></p>
                                                                    <p>Title: <b>{trialData?.ResponsiblePartyInvestigatorTitle}</b></p>
                                                                    <p>Name: <b>{trialData?.ResponsiblePartyInvestigatorFullName}</b></p>
                                                                    
                                                                </div>
                                                                <div>
                                                                    <h6>Collaborators</h6> <hr style={{ margin: '0' }} />
                                                                    {typeof(trialData?.Collaborator) == "object" ? (
                                                                        trialData?.Collaborator.map((value, index) => {
                                                                            return <p>{value.CollaboratorName}</p>
                                                                        })
                                                                    ) : (<p>{trialData?.Collaborator}</p>)}
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </TabPanel>


                                                </Tabs>
                                            </div>
                                            {/* study-tab-section */}

                                        </TabPanel>
                                        <TabPanel>
                                            <p>results submitted</p>
                                        </TabPanel>
                                    </Tabs>

                                </div>
                                {/* results-tab-container */}

                            </div>

                        </div>
                        {/* contact-form-wrapper */}
                    </div>
                    {/* contact-form-main-area */}
                </div>
                {/* contact-inner-parent */}
                <img
                    src={birds}
                    style={{ height: "100px", width: "auto" }}
                    className="signup-birds hideInMobile"
                    alt=""
                />

            </div>
        </>
    );
};

export default Results;
