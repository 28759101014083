import React, { useEffect, useState, useRef } from "react";
import logo from "../../images/logo.png";
import Sidebar from "../../components/sidebar/Sidebar";
import Header from "../../components/Header/Header";
import birds from "../../images/birds.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import StatsCard from "../../components/statsCard/StatsCard";
import Therapeutics from "./Therapeutics";
import ClinicalTrials from "./ClinicalTrials";
import { LOCAL_BACKEND, STAGGING_BACKEND } from "../../common/helper";
import TrialsByDrugs from "./analyticsComponents/trials_by_drugs";
import TrialsByPhase from "./analyticsComponents/trialis_by_phase";
import TrialsByStage from "./analyticsComponents/trials_by_stage";
import TrialsByInstitution from "./analyticsComponents/trials_by_institution";
import TrialsByMutations from "./analyticsComponents/trials_by_mutations";
import TrialsByIntervention from "./analyticsComponents/trials_by_intervention";
import TrialsByProtiens from "./analyticsComponents/trials_by_protiens";
import TrialsByDistance from "./analyticsComponents/trials_by_distance";
import TrialsByMechanism from "./analyticsComponents/trials_by_mechanism";
import TrialsByTherapy from "./analyticsComponents/trials_by_therapy";


function StatsDrugs() {
  const [count, setCount] = useState({})
  const [data, setData] = useState({})
  const [tab, setTab] = useState("")
  const [details , setDetails] = useState({});

  const [header, setHeader] = useState(false);
  const [sideBar, setSideBar] = useState(false);
  const [showTherapeutics, setShowTherapeutics] = useState(false);


  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [render, setRender] = useState(false);
  const [loading, setLoading] = useState(false);
  const [trialsDataSet, setTrialsDataSet] = useState([])

  const loadingCallBack = (callBackValue) => {
    setLoading(callBackValue)
  }
  const renderCallBack = (callBackValue) => {
    setRender(callBackValue)
  }
  const totalRecordsCallBack = (callBackValue) => {
    setTotalRecords(callBackValue)
  }
  const totalPagesCallBack = (callBackValue) => {
    setTotalPages(callBackValue)
  }
  const currentPageCallBack = (callBackValue) => {
    setCurrentPage(callBackValue)
  }

  const urlParams = new URLSearchParams(window.location.search);
  const savedFilter = urlParams.get("checkByCustomNctIds");

  const NctIds = JSON.parse(window.sessionStorage.getItem("checkByCustomNctIds"));


  function handleHeader() {
    setHeader((t) => !t);
  }
  
  function handleSideBar() {
    setSideBar((t) => !t);
  }
  
  function handleAnalyticTab(parameter) {
    setTab(parameter);
    setDetails(parameter);
    document
      .querySelector(".analytics-drug-table-container")
      .classList.add("active");
    document.querySelector("#analytics-Container").classList.add("hide");
  }

  function HandleBackBtn() {
    document
      .querySelector(".analytics-drug-table-container")
      .classList.remove("active");
    document.querySelector("#analytics-Container").classList.remove("hide");
    
  }

  function HandleSeeDetail(parameter) {
    setDetails(parameter);
    document.querySelector('#numberofUniqueMechanism').style.display = "block";
    setShowTherapeutics(true);
    document.querySelector('#table').style.display = "none";
  }

    // Define an asynchronous function  
    const fetchData = async () => {
      try {
        setLoading(true); // Start loading before the API call
  
        let url = savedFilter !== "true" 
          ? `${STAGGING_BACKEND}cancer/stats?page_number=${currentPage}` 
          : `${STAGGING_BACKEND}cancer/stats/?by_save=${NctIds.saved}&custom_nct_ids=${NctIds.nct_ids}&page_number=${currentPage}`;

  
        const requestOptions = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'token ' + localStorage.getItem("token")
          },
        };
  
        const response = await fetch(url, requestOptions);
        const responseData = await response.json();
        setTrialsDataSet(responseData?.data?.data);
        setTotalPages(responseData?.data?.total_pages);
        setTotalRecords(responseData?.data?.total_documents);
        // setCurrentPage(responseData?.data?.current_page);
      } catch (error) {
        console.error('Error fetching data: ', error);
        // Optionally, handle the error state here
      } finally {
        setLoading(false); // Stop loading after the API call
      }
    };

    useEffect(() => {
      fetchData();
    }, [render, currentPage]);
    

  useEffect(async () => {
    let url = null;
    (savedFilter != "true" ? url = STAGGING_BACKEND + "cancer/analytics/" :
     url = STAGGING_BACKEND + "cancer/analytics/?by_save="+NctIds.saved+"&custom_nct_ids="+NctIds.nct_ids);

    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'token ' + localStorage.getItem("token")
      },
      // body: JSON.stringify(payload)
    };

    const response = await fetch(url, requestOptions);
    const responseData = await response.json();
    setCount(responseData.data)

  }, [])

  
  return (
    <>
      <div className="mobile-header-section">
        <div
          id="nav-icon4"
          className={!sideBar ? "hamburger" : "hamburger open"}
          onClick={handleSideBar}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
        <img src={logo} style={{ height: "60px" }} alt="" />
        <div
          id="nav-icon3"
          onClick={handleHeader}
          className={!header ? "hamburger" : "hamburger open"}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>

      <div className="cc-page-wrapper">
        <Sidebar
          sideBar={sideBar}
          marginTopH6="mt3point5rem"
          margintop2rem="margintop2rem"
        />
        <div className="main-area-inner-wrapper">
          <Header header={header} />
          <div className="new-page-content-wrapper mt-4">
            <h1 className="site-heading">Clinical Trial Finder</h1>

            <div className="tabs-drugs-parent mt-4">
              <Tabs  onSelect={(index) => {
                const event = index === 0 ? setShowTherapeutics(false) : null}}>
                <TabList>
                  <Tab>Clinical Trials</Tab>
                  <Tab>Analytics</Tab>
                  {/* <Tab>Therapeutics</Tab> */}
                </TabList>

                <TabPanel>
                  <div className="tab-content-wrapper-drugs mt-4">
                    <ClinicalTrials currentPage ={currentPage}
                     setCurrentPage={currentPageCallBack}
                     render = {render}
                     setRender = {renderCallBack}
                     totalPages = {totalPages}
                     setTotalPages = {totalPagesCallBack}
                     totalRecords = {totalRecords}
                     setTotalRecords = {totalRecordsCallBack}
                     data = {trialsDataSet}
                     loading = {loading}
                     setLoading = {loadingCallBack}/>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div
                    className="tab-content-wrapper-drugs mt-4"
                    id="analytics-Container"
                  >
                    <h5 className="blue-text text-center mb-4">Total number of trials: <b>{count?.total_trials}</b></h5>
                    <div className="row">
                      <div className="col mb-4" onClick={() => {
                        handleAnalyticTab("drugs")
                      }}>
                        <StatsCard
                          text="Number of Unique Drugs"
                          stats={count?.t_drugs}
                        />
                      </div>
                      <div className="col mb-4" onClick={() => {
                        handleAnalyticTab("mechanism")
                      }}>
                        <StatsCard
                          text="Number of Unique Mechanisms"
                          stats={count?.t_moa}
                        />
                      </div>
                      <div className="col mb-4" onClick={() => {
                        handleAnalyticTab("stage")
                      }}>
                        <StatsCard
                          text="Number of Unique Stages"
                          stats={count?.t_stage}
                        />
                      </div>
                      <div className="col mb-4" onClick={() => {
                        handleAnalyticTab("phase")
                      }}>
                        <StatsCard
                          text="Number of Unique Phases"
                          stats={count?.t_phase}
                        />
                      </div>
                      <div className="col mb-4" onClick={() => {
                        handleAnalyticTab("intervention")
                      }}>
                        <StatsCard
                          text="Number of Unique Intervention Types"
                          stats={count?.t_intervention}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col mb-4" onClick={() => {
                        handleAnalyticTab("distance")
                      }}>
                        <StatsCard
                          text="Number of Trials by Distance"
                          stats={count?.t_distance}
                        />
                      </div>
                      <div className="col mb-4" onClick={() => {
                        handleAnalyticTab("protiens")
                      }}>
                        <StatsCard
                          text="Number of Unique Proteins"
                          stats={count?.t_protein}
                        />
                      </div>
                      <div className="col mb-4" onClick={() => {
                        handleAnalyticTab("institution")
                      }}>
                        <StatsCard
                          text="Number of Unique Institutions"
                          stats={count?.t_institute}
                        />
                      </div>
                      <div className="col mb-4" onClick={() => {
                        handleAnalyticTab("therapy")
                      }}>
                        <StatsCard
                          text="Number of Unique Lines of Therapy"
                          stats={count?.lot}
                        />
                      </div>
                      <div className="col mb-4" onClick={() => {
                        handleAnalyticTab("mutations")
                      }}>
                        <StatsCard
                          text="Number of Unique Genetic Mutations"
                          stats={count?.mutation}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="analytics-drug-table-container" >
                    <div
                      style={{
                        overflowX: "auto",
                        overflowY: "auto",
                        maxHeight: "370px",
                      }}
                      className="drugstatusTable-wrapper"
                      id="table"
                    >
                      {/* <div className="d-flex justify-content-end mb-3">
                        <button className="blue-button" style={{ marginRight: '10px' }}>Save All</button>
                        <button className="blue-button" style={{ marginRight: '10px' }}>Download</button>
                        <button className="blue-button" onClick={HandleBackBtn}>Back</button>
                      </div> */}

                      {tab == "drugs" ?
                        (<> <TrialsByDrugs seeDetails={HandleSeeDetail}/> </>)
                        : null}
                      {tab == "mechanism" ?
                        (<> <TrialsByMechanism  seeDetails={HandleSeeDetail}/> </>)
                        : null}
                      {tab == "protiens" ?
                        (<> <TrialsByProtiens  seeDetails={HandleSeeDetail}/> </>)
                        : null}
                      {tab == "distance" ?
                        (<> <TrialsByDistance  seeDetails={HandleSeeDetail}/> </>)
                        : null}
                      {tab == "institution" ?
                        (<> <TrialsByInstitution  seeDetails={HandleSeeDetail}/> </>)
                        : null}
                      {tab == "mutations" ?
                        (<> <TrialsByMutations  seeDetails={HandleSeeDetail}/> </>)
                        : null}
                      {tab == "therapy" ?
                        (<> <TrialsByTherapy  seeDetails={HandleSeeDetail}/> </>)
                        : null}
                      {tab == "stage" ?
                        (<> <TrialsByStage  seeDetails={HandleSeeDetail}/> </>)
                        : null}
                      {tab == "phase" ?
                        (<> <TrialsByPhase  seeDetails={HandleSeeDetail}/> </>)
                        : null}
                      {tab == "intervention" ?
                        (<> <TrialsByIntervention  seeDetails={HandleSeeDetail}/> </>)
                        : null}
                    </div>
                  </div>

                  <div className="tab-content-wrapper-drugs mt-4" id="numberofUniqueMechanism">
                  {
                    showTherapeutics &&  <Therapeutics details={details} hideTherapeutics={() => setShowTherapeutics(false)} />
                  }
                  </div>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
        <img
          src={birds}
          style={{ height: "100px", width: "auto" }}
          className="signup-birds hideInMobile"
          alt=""
        />
      </div>
    </>
  );
}

export default StatsDrugs;
