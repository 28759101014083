import React, { useEffect, useState } from "react";
import Table from 'react-bootstrap/Table';
import heart from '../../../images/heart.svg';
import download from '../../../images/download.svg';
import { LOCAL_BACKEND, STAGGING_BACKEND } from "../../../common/helper";
import Loader from "../../../components/Loader/Loader";
import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip"; // Import your tooltip component

const TrialsByIntervention = ({ seeDetails }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const savedFilter = urlParams.get("checkByCustomNctIds");

  const NctIds = JSON.parse(window.sessionStorage.getItem("checkByCustomNctIds"));
  console.log(NctIds);

  useEffect(() => {
    const fetchData = async () => {
      const filter = "unique_interventions";

      let url = null;
      url = savedFilter !== "true"
        ? `${STAGGING_BACKEND}cancer/analytics/?filter=${filter}`
        : `${STAGGING_BACKEND}cancer/analytics/?filter=${filter}&by_save=${NctIds.saved}&custom_nct_ids=${NctIds.nct_ids}`;

      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'token ' + localStorage.getItem("token"),
        },
      };

      setIsLoading(true);
      const response = await fetch(url, requestOptions);
      const responseData = await response.json();
      console.log(responseData);
      setData(responseData.data);
      setIsLoading(false);
    };

    fetchData();
  }, [NctIds, savedFilter]);

  const downloadAll = async () => {
    const filter = "unique_interventions";
    let url = savedFilter !== "true"
      ? `${STAGGING_BACKEND}cancer/analytics/?filter=${filter}&export=xls`
      : `${STAGGING_BACKEND}cancer/analytics/?filter=${filter}&export=xls&by_save=${NctIds.saved}&custom_nct_ids=${NctIds.nct_ids}`;

    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'token ' + localStorage.getItem("token"),
      },
      responseType: 'blob',
    };

    const response = await fetch(url, requestOptions);
    const responseData = await response.blob();

    const blob = new Blob([responseData]);
    const blob_url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = blob_url;
    link.setAttribute('download', 'unique_interventions.xls');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  function HandleBackBtn() {
    document.querySelector(".analytics-drug-table-container").classList.remove("active");
    document.querySelector("#analytics-Container").classList.remove("hide");
  }

  function HandleSeeDetail() {
    document.querySelector('#numberofUniqueMechanism').style.display = "block";
    document.querySelector('#table').style.display = "none";
  }

  return (
    <>
      {isLoading && <Loader />}
      <div className="d-flex justify-content-end mb-3">
        <button className="blue-button" style={{ marginRight: '10px' }} onClick={downloadAll}>
          Download
        </button>
        <button className="blue-button" onClick={HandleBackBtn}>Back</button>
      </div>
      <div style={{ overflowX: "auto", overflowY: "auto", maxHeight: "370px", position: 'relative', right: '10px' }} className="drugstatusTable-wrapper">
        <Table hover className="drugsTable">
          <thead>
            <tr>
              <th>S NO</th>
              <th>INTERVENTION</th>
              <th>NO OF TRIALS</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data?.map((value, key) => (
              <tr key={key}>
                <td>{key + 1}</td>
                <td className="drugName">
                  {/* Use CustomTooltip for displaying the full text on hover */}
                  <CustomTooltip text={value.analytics_trial_type} />
                </td>
                <td>{value.trials}</td>
                <td>
                  <button className="blue-button" id="see-details" onClick={() => seeDetails({ key: value.analytics_trial_type, filter: "uniqueInterventions" })}>
                    See Details
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default TrialsByIntervention;
