import React, { useState } from "react";
import logo from "../../images/logo.png";
import Sidebar from "../../components/sidebar/Sidebar";
import Header from "../../components/Header/Header";
import birds from "../../images/birds.png";
import Table from 'react-bootstrap/Table';
import heart from '../../images/heart.svg';
import save from '../../images/save.png'; // Import for the filled heart icon
import download from '../../images/download.svg';
import CustomTooltip from '../../components/CustomTooltip/CustomTooltip'; // Adjust the path as necessary

const SavedTreatmentPlan = () => {
    const [header, setHeader] = useState(false);
    const [sideBar, setSideBar] = useState(false);
    const [favorites, setFavorites] = useState({}); // State to manage favorite items
  
    function handleHeader() {
        setHeader((t) => !t);
    }
    function handleSideBar() {
        setSideBar((t) => !t);
    }

    const toggleFavorite = (id) => {
        setFavorites(prev => ({
            ...prev,
            [id]: !prev[id] // Toggle favorite status
        }));
    };

    return (
        <>
            <div className="mobile-header-section">
                <div id="nav-icon4" className={!sideBar ? "hamburger" : "hamburger open"} onClick={handleSideBar}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <img src={logo} style={{ height: "60px" }} alt="" />
                <div id="nav-icon3" onClick={handleHeader} className={!header ? "hamburger" : "hamburger open"}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>

            <div className="cc-page-wrapper">
                <Sidebar sideBar={sideBar} marginTopH6="mt3point5rem" margintop2rem="margintop2rem" />
                <div className="main-area-inner-wrapper">
                    <Header header={header} />
                    <div className="new-page-content-wrapper mt-4">
                        <h1 className="site-heading mb-4">My Saved Treatment Plans</h1>
                        <div className="d-flex justify-content-end">
                            <button className="blue-button mb-3">Download</button>
                        </div>
                        <div style={{ overflowX: "auto", overflowY: "auto", maxHeight: "370px" }} className="drugstatusTable-wrapper">
                            <Table hover className='drugsTable'>
                                <thead>
                                    <tr>
                                        <th>Rank</th>
                                        <th>1st Line</th>
                                        <th>2nd Line</th>
                                        <th>3rd Line</th>
                                        <th>4th Line</th>
                                        <th>Trial Details</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {[1, 2, 3, 4, 5, 6].map((id) => (
                                        <tr key={id}>
                                            <td className='drugName'>{id < 10 ? `0${id}` : id}</td>
                                            <td><CustomTooltip text="Short description  " /></td>
                                            <td><CustomTooltip text="Brief overview " /></td>
                                            <td><CustomTooltip text="Details about  " /></td>
                                            <td><CustomTooltip text="Information on " /></td>
                                            <td>
                                                <button className='blue-button'>See Details</button>
                                                <img src={download} className="drugstableIcon" alt="Download" />
                                                <img 
                                                    src={favorites[id] ? save : heart} 
                                                    className="drugstableIcon" 
                                                    alt="Favorite" 
                                                    onClick={() => toggleFavorite(id)} 
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
                <img src={birds} style={{ height: "100px", width: "auto" }} className="signup-birds hideInMobile" alt="" />
            </div>
        </>
    );
};

export default SavedTreatmentPlan;
