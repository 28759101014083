import React, { useState, useEffect } from "react";
import Sidebar from "../../../components/sidebar/Sidebar";
import Header from "../../../components/Header/Header";
import birds from "../../../images/birds.png";
import leafs from "../../../images/leafs.png";
import logo from "../../../images/logo.png";
import EditProfileImage from "../../../images/settings.png";
import Classes from "../profile.module.css";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

const teamListing = [
  { label: "Team1" },
  { label: "Team2" },
  { label: "Team3" },
  { label: "Team5" },
  { label: "Team45" },
  { label: "Team343" },
  { label: "Team23" },
  { label: "Team66" },
];
const Team = () => {
  const [header, setHeader] = useState(false);
  const [sideBar, setSideBar] = useState(false);
  const [showCreateTeamDialog, setShowCreateTeamDialog] = useState(false);
  const [editCreateTeamDialog, setEditCreateTeamDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(true);
  const [selectedTeam, setSelectedTeam] = useState(null);

  function handleHeader() {
    setHeader((t) => !t);
  }

  function handleSideBar() {
    setSideBar((t) => !t);
  }

  const handleEditTeam = (teamLabel) => {
    setSelectedTeam(teamLabel);
    setEditCreateTeamDialog(true)
  }

  const handleCloseDialog = () => {
    setEditCreateTeamDialog(false)
    setShowCreateTeamDialog(false)
  }
  const handleInputChange = (event) => {
  setSelectedTeam(event.target.value);
}

  return (
    <>
      <div className="mobile-header-section">
        <div
          id="nav-icon4"
          className={!sideBar ? "hamburger" : "hamburger open"}
          onClick={handleSideBar}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
        <img src={logo} style={{ height: "60px" }} alt="" />
        <div
          id="nav-icon3"
          onClick={handleHeader}
          className={!header ? "hamburger" : "hamburger open"}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>

      <div className="cc-page-wrapper">
        <Sidebar
          sideBar={sideBar}
          marginTopH6="mt3point5rem"
          margintop2rem="margintop2rem"
        />
        <div className="main-area-inner-wrapper">
          <Header header={header} />

          <div className="new-page-content-wrapper mt-4">
            <h1 className="site-heading">Team Page</h1>
            <div className={Classes.teamNavArea}>
              <button className="blue-button">back</button>
              <button
                className={Classes.successBtn}
                onClick={() => {setShowCreateTeamDialog(true);setSelectedTeam(null)}}
              >
                Create
              </button>
            </div>
            <div className="tabs-drugs-parent mt-4">
              <Tabs>
                <TabList>
                  <Tab>My Teams</Tab>
                  <Tab>Other Invited Team</Tab>
                  <Tab>Invitation / Invite</Tab>
                </TabList>
                <TabPanel>
                  <div className={Classes.teamContentTabWrapper}>
                    <div className={Classes.teamListingHeader}>
                      <h3>Team Name</h3>
                      <h3>Actions</h3>
                    </div>
                    <div className={Classes.teamListingWrapper}>
                      <ol>
                        {teamListing?.map((item) => {
                          return (
                            <li>
                              <span>{item.label}</span>
                              <div className={Classes.ctaTeam}>
                                <p className={Classes.previewTeam}>
                                  <i className="fas fa-eye"></i>
                                  Preview
                                </p>
                                <p className={Classes.editTeam} onClick={() => handleEditTeam(item.label)}>
                                  <i className="fas fa-edit"></i> Edit
                                </p>
                                |
                                <p className={Classes.delTeam} onClick={() => setShowDeleteDialog(true)}>
                                  <i className="fas fa-trash"></i> Delete
                                </p>
                              </div>
                            </li>
                          );
                        })}
                      </ol>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className={Classes.teamContentTabWrapper}>
                    <p className={Classes.otherTeamInvited}>No Data Found</p>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className={Classes.teamContentTabWrapper}>
                    <p className={Classes.otherTeamInvited}>No Data Found</p>
                  </div>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
        <img
          src={birds}
          style={{ height: "100px", width: "auto" }}
          className="signup-birds hideInMobile"
          alt=""
        />
        <img
          src={leafs}
          style={{ height: "150px", width: "auto" }}
          className="signup-ground hideInMobile"
          alt=""
        />
      </div>
      {(showCreateTeamDialog || editCreateTeamDialog) && (
        <div className={Classes.createTeamDialog}>
          <div className={Classes.createTeamDialogContentWrapper}>
            <button className={Classes.closeBtn} onClick={handleCloseDialog}>x</button>
            <h3>Team name</h3>
            <input
            type="text"
            className="custom-form-control mt-4"
            placeholder="Enter Team name"
            value={selectedTeam || ''}  
            onChange={handleInputChange}
            />
            <button className="blue-button mt-4"  onClick={()=> setShowCreateTeamDialog(false)}>
            {editCreateTeamDialog ? 'Update': 'Create'}
            </button>
          </div>
        </div>
      )}

      {(showDeleteDialog) && (
        <div className={Classes.createTeamDialog}>
          <div className={Classes.createTeamDialogContentWrapper}>
            <button className={Classes.closeBtn} onClick={()=> setShowDeleteDialog(false)}>x</button>
            <h5>Are you sure want to delete this team</h5>
            <div className={Classes.deleteDialogCtaWrapper}>
            <button className="gray-button mt-4">
                Cancel
            </button>
            <button className="blue-button mt-4">
                Delete
            </button>
            </div>
            
          </div>
        </div>
      )}
    </>
  );
};

export default Team;
