import React, { useState } from "react";
import logo from "../../images/logo.png";
import birds from "../../images/birds.png";
import Sidebar from "../../components/sidebar/Sidebar";
import Header from "../../components/Header/Header";
import ContentImageSection from "../../components/ContentImageSection/ContentImageSection";
import CEO_img from "../../images/goueli.jpg";
import avatar from "../../images/avatar.jpg";
import cv from '../../images/basem_cv.docx'
import intro from "../../images/intro.png";

const About = () => {
  const [header, setHeader] = useState(false);
  const [sideBar, setSideBar] = useState(false);

  function handleHeader() {
    setHeader((t) => !t);
  }

  function handleSideBar() {
    setSideBar((t) => !t);
  }

  const headingForCCCLine1 = "About Us";
  const contentObj = {
    heading1: { headingForCCCLine1 },
    img: { intro },
    buttonText: "Enter The Site",
  };

  const handleDownloadCV = () => {
    const link = document.createElement("a");
    link.href = cv;
    link.download = "Basem_Goueli_CV.docx"; // Set the file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <div className="mobile-header-section">
        <div
          id="nav-icon4"
          className={!sideBar ? "hamburger" : "hamburger open"}
          onClick={handleSideBar}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
        <img src={logo} style={{ height: "60px" }} alt="" />
        <div
          id="nav-icon3"
          onClick={handleHeader}
          className={!header ? "hamburger" : "hamburger open"}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>

      <div className="cc-page-wrapper aboutPageWrapper">
        <Sidebar
          sideBar={sideBar}
          marginTopH6="mt3point5rem"
          margintop2rem="margintop2rem"
        />
        <div className="main-area-inner-wrapper">
          <Header header={header} />
          <div className="new-page-content mt-4">
            <ContentImageSection obj={contentObj} />
            <div className="about-page-inner-container">
              <div className="ceo-section mt-5">
                <div className="row">
                  <div className="col-lg-5">
                    <div className="about-col-wrapper custom-scroll">
                      <h2 className="section-heading">Bio</h2>
                      <p>
                        Dr. Goueli has directed three independent cancer
                        institutes, collectively representing 17 hospitals and
                        12 cancer centers. He is a hematologist and medical
                        oncologist who has been the institutional principal
                        investigator on nearly 100 clinical trials involving
                        breast cancer, myeloma, prostate cancer, lung cancer,
                        melanoma, ovarian cancer, head and neck cancer,
                        myelodysplastic syndrome, CLL, DLBCL, esophageal cancer,
                        etc. He was on three papers published in The New England
                        Journal of Medicine (prostate cancer, chronic
                        neutrophilic leukemia, lung cancer) and two in Lancet
                        (Shingles vaccine and head and neck cancer). He is
                        board-certified in hematology, medical oncology,
                        internal medicine, and artificial intelligence in
                        medicine.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="about-col-wrapper custom-scroll">
                      <h2 className="section-heading">Education</h2>
                      <p>
                        BS (double major Genetics/Cell Biology/Biochemistry):
                        University of Minnesota
                        <br />
                        MD: Mayo Clinic
                        <br />
                        PhD in biochemistry: Mayo Clinic
                        <br />
                        MBA: University of North Carolina, Chapel Hill
                        <br />
                        Internal Medicine Residency: George Washington
                        University
                        <br />
                        Hospital with 4 months at the NIH
                        <br />
                        Hematology/Oncology Clinical Fellowship: Stanford
                        <br />
                        Biochemistry Post-Doctoral Fellowship: <br />
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="ceo-profile-img-section">
                      <div className="img-wrapper">
                        <img src={CEO_img} alt="CEO" />
                      </div>
                      <div className="img-caption-area">
                        <p>
                          Basem S. Goueli MD, PhD, MBA
                          <br />
                          CEO & Founder
                        </p>
                        <button
                          className="download-cv-btn"
                          onClick={handleDownloadCV}
                        >
                          Download CV
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* ceo-section */}

              <div className="reachUs-section mt-5">
                <h1 className="site-heading">Reach Us</h1>
                <div className="row mt-5">
                <div className="col-lg-6 mb-4">
            <div className="reachUs-col-wrapper">
                <h4>
                    Cancer Clarity, New York, USA
                    <br />
                    <a href="tel:+15079903394">+1 (507) 990-3394</a>
                    <br />
                    <a href="mailto:basemgoueli@gmail.com">basemgoueli@gmail.com</a>
                </h4>
            </div>
        </div>
                  {/* col 6 */}

                  <div className="col-lg-6 mb-4">
                    <div className="reachUs-col-wrapper">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d24165.055897091555!2d-74.18011309869655!3d40.79210407667069!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c255a5af6d81df%3A0x865bfcf80738f7b4!2sBelleville%2C%20NJ%2C%20USA!5e0!3m2!1sen!2s!4v1666284873634!5m2!1sen!2s"
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                      ></iframe>
                    </div>
                  </div>
                  {/* col 6 */}
                </div>
              </div>
              {/* reachUs-section */}
            </div>
          </div>
        </div>
        <img
          src={birds}
          style={{ height: "100px", width: "auto", position: "fixed" }}
          class="signup-birds hideInMobile"
          alt=""
        />
      </div>
    </>
  );
};

export default About;




{/* <div className="team-section mt-5">
<div className="row">
  <div className="col-lg-3 mb-4">
    <div className="team-card-wrapper">
      <div className="team-img-area">
        <img src={avatar} alt="" />
      </div>
      {/* team-img-area */}
      // <div className="team-caption-area">
      //   <p>
      //     Muhammad Qasim <br />
      //     Chief Data Scientist
      //   </p>
      // </div>
      {/* team-caption-area */}
    // </div>
    {/* team-card-wrapper */}
  // </div>
  // <div className="col-lg-3 mb-4">
  //   <div className="team-card-wrapper">
  //     <div className="team-img-area">
  //       <img src={avatar} alt="" />
  //     </div>
  //     {/* team-img-area */}
  //     <div className="team-caption-area">
  //       <p>
  //         Nauman Shah <br />
  //         Data Scientist
  //       </p>
  //     </div>
      {/* team-caption-area */}
    // </div>
    {/* team-card-wrapper */}
  // </div>
  // <div className="col-lg-3 mb-4">
  //   <div className="team-card-wrapper">
  //     <div className="team-img-area">
  //       <img src={avatar} alt="" />
  //     </div>
      {/* team-img-area */}
      // <div className="team-caption-area">
      //   <p>
      //     Syed Hammad Shah <br />
      //     Lead full stack developer
      //   </p>
      // </div>
      {/* team-caption-area */}
    // </div>
    {/* team-card-wrapper */}
  // </div>
  // <div className="col-lg-3 mb-4">
  //   <div className="team-card-wrapper">
  //     <div className="team-img-area">
  //       <img src={avatar} alt="" />
  //     </div>
      {/* team-img-area */}
      // <div className="team-caption-area">
      //   <p>
      //     Usman Feroz <br />
      //     Front end developer
      //   </p>
      // </div>
      {/* team-caption-area */}
    // </div>
    {/* team-card-wrapper */}
  // </div>
  // <div className="col-lg-3 mb-4">
  //   <div className="team-card-wrapper">
  //     <div className="team-img-area">
  //       <img src={avatar} alt="" />
  //     </div>
      {/* team-img-area */}
      // <div className="team-caption-area">
      //   <p>
      //     Ayesha shafique<br />
      //     Chief Data Scientist
      //   </p>
      // </div>
      {/* team-caption-area */}
    // </div>
    {/* team-card-wrapper */}
  // </div>
  // <div className="col-lg-3 mb-4">
  //   <div className="team-card-wrapper">
  //     <div className="team-img-area">
  //       <img src={avatar} alt="" />
  //     </div>
      {/* team-img-area */}
      // <div className="team-caption-area">
      //   <p>
      //     Tabish Irfan <br />
      //     Front End Developer
      //   </p>
      // </div>
      {/* team-caption-area */}
    // </div>
    {/* team-card-wrapper */}
  // </div>
  // <div className="col-lg-3 mb-4">
  //   <div className="team-card-wrapper">
  //     <div className="team-img-area">
  //       <img src={avatar} alt="" />
  //     </div>
      {/* team-img-area */}
      // <div className="team-caption-area">
      //   <p>
      //     Umair Ahmed <br />
      //     Project Manager
      //   </p>
      // </div>
      {/* team-caption-area */}
    // </div>
    {/* team-card-wrapper */}
  // </div>
  // <div className="col-lg-3 mb-4">
  //   <div className="team-card-wrapper">
  //     <div className="team-img-area">
  //       <img src={avatar} alt="" />
  //     </div>
      {/* team-img-area */}
      // <div className="team-caption-area">
      //   <p>
      //     Saheeq Ahmed <br />
      //     Web Scrapping
      //   </p>
      // </div>
      {/* team-caption-area */}
    // </div>
    {/* team-card-wrapper */}
//   </div>
// </div>
// </div>
// team-section 