import React, { useState } from "react";
import logo from "../../images/logo.png";
import Sidebar from "../../components/sidebar/Sidebar";
import Header from "../../components/Header/Header";
import birds from "../../images/birds.png";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";
import leafs from "../../images/leafs.png";
const CcTreatmentGuideFinish = () => {
    const [header, setHeader] = useState(false);
    const [sideBar, setSideBar] = useState(false);
  
  
    function handleHeader() {
      setHeader((t) => !t);
    }
    function handleSideBar() {
      setSideBar((t) => !t);
    }

    const items = ['First','Second','Third','Fourth']
    
  const responsive = {
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2    ,
      },
      600: {
        items: 2,
      },
      700: {
        items: 3,
      },
      900: {
        items: 3,
      },

      1000: {
        items: 4,
      },
      1300: {
        items: 4,
      },
    },
  };
    return (
        <>
              <div className="mobile-header-section">
        <div
          id="nav-icon4"
          className={!sideBar ? "hamburger" : "hamburger open"}
          onClick={handleSideBar}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
        <img src={logo} style={{ height: "60px" }} alt="" />
        <div
          id="nav-icon3"
          onClick={handleHeader}
          className={!header ? "hamburger" : "hamburger open"}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>

      <div className="cc-page-wrapper">
        <Sidebar
          sideBar={sideBar}
          marginTopH6="mt3point5rem"
          margintop2rem="margintop2rem"
        />
        <div className="main-area-inner-wrapper">
          <Header header={header} />
          <div className="new-page-content-wrapper ccTreatmentGuideCarousel mt-4 m-auto" style={{maxWidth:'1200px'}}>
            <h1 className="site-heading mb-4">Cancer Clarity Treatment Guide</h1>
            <div className="mt-5">
            <OwlCarousel
                className="owl-theme "
                margin={30}
                items={4}
                nav={true}
                dots={false}
                {...responsive}
              >
                {items?.map((e, index) => {
                  return (
                    <>
                    <div className="ccTreatment-finish-card-parent">
                    <h3>{e} Line</h3>
                     <div className="ccTreatment-finish-card">
                        <div className="drug-name-container">
                        <h4>Drug Name</h4>
                        </div>
                        <div className="drug-description-wrapper">
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta consequuntur vero maiores cum, inventore ad vel hic corporis porro officiis.</p>
                        </div>
                     </div>
                     </div>
                    </>
                  );
                })}
              </OwlCarousel>
              <button className="blue-button ccTreatment-download-btn mx-auto d-block mt-3">
                Download
              </button>
            </div>
           
          </div>
        </div>
        <img
          src={birds}
          style={{ height: "100px", width: "auto" }}
          className="signup-birds hideInMobile"
          alt=""
        />
         <img
          src={leafs}
          style={{ height: "150px", width: "auto" }}
          className="signup-ground hideInMobile"
          alt=""
        />
      
      </div>
        </>
    );
}

export default CcTreatmentGuideFinish;
