import React, { useState, useEffect, useRef } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SaveData from "../../components/Save_Data/Save_Data";
import RenderData from "../../components/Save_Data/Render_Data";
import Select from "react-select";
import { STAGGING_BACKEND, LOCAL_BACKEND } from "../../common/helper";
import DeleteData from "../../components/Save_Data/Delete_Data";
import crossIcon from "../../images/cross.svg";
import DashboardLayout from "../../components/dashboardLayout/dashboardLayout";
import QuestionAirFormFooter from "../../components/questionAirFormFooter/questionAirFormFooter";
import QuestionAirTabs from "../../components/questionairTabs/questionairTabs";
import { trialTypeOptions, phaseTrialOptions, trialStatusOptions, booleanOptions } from "../../libs/optionsHandling";
import { generateNamesArray, handleSwitchStatement } from "../../libs/Util";
import { useLocation } from "react-router-dom";
import { navigateToPreviousOption, navigateToNextOption, getPageOptionsFromIndices } from "../../libs/Util";
import Loader from "../../components/Loader/Loader";
import DrugSearchDropdown from "./questions/drugDropdown";
import DeviceSearchDropdown from "./questions/deviceDropdown";

function ClinicalQuestion4() {
  const location = useLocation();

  const [lastOption, setLastOption] = useState(false);

  const [importDataState, setImportDataState] = useState(false);
  const [saveDataState, setSaveDataState] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [clearData, setClearData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [trialType, setTrialType] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [trialPhase, setTrialPhase] = useState("");
  const [trialStatus, setTrialStatus] = useState("");
  const [showDropDown, setShowDropDown] = useState(true);
  const drugRef = useRef();
  const medDeviceRef = useRef();
  const navigate = useNavigate();
  const selector = useSelector((state) => state.options);
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get("mode");
  const page = urlParams.get("page");
  const mode = urlParams.get("mode");
  const savedFilter = urlParams.get("checkByCustomNctIds");
  const nullValue = { label: "choose below", value: "" };
  const data = ["1", "2", "3", "4"];
  const filteredData = data.filter(item => item.toLowerCase().includes(searchTerm.toLowerCase()));
  const optionsToShow = new URLSearchParams(location.search).get("optionsToShow");
  const pageOptions = getPageOptionsFromIndices(optionsToShow);
  let names = generateNamesArray(mode, pageOptions, savedFilter, page, optionsToShow);

  function Save_Data_Session() {
    window.localStorage.setItem(
      "Trial_Sponsor_Phase_selected_options",
      JSON.stringify(selectedItems)
    );
  }

  function handleListItemClick(item) {
    let updatedSelectedItems;
    if (selectedItems.includes(item)) {
      updatedSelectedItems = selectedItems.filter(selectedItem => selectedItem !== item);
    } else {
      updatedSelectedItems = [...selectedItems, item];
    }
    setSelectedItems(updatedSelectedItems);
    window.localStorage.setItem("Trial_Sponsor_Phase_selected_options", JSON.stringify(updatedSelectedItems));
  }

  function Get_Selected_Options() {
    const get_options = window.localStorage.getItem(
      "Trial_Sponsor_Phase_selected_options"
    );
    return get_options ? JSON.parse(get_options) : [];
  }

  function onRemoveTag(itemToRemove) {
    const newSelectedItems = selectedItems.filter(item => item !== itemToRemove);
    setSelectedItems(newSelectedItems);
    window.localStorage.setItem("Trial_Sponsor_Phase_selected_options", JSON.stringify(newSelectedItems));
  }

  const OnSubmitForm = async () => {
    try {
      setIsLoading(true)
      const url = STAGGING_BACKEND + "cancer/questionair/cancer_type/";
      const payload = {
        trial_type: trialType.value,
        trial_phase: Get_Selected_Options(),
        trial_status: trialStatus.value,
      };
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "token " + localStorage.getItem("token"),
        },
        body: JSON.stringify(payload),
      };
      const response = await fetch(url, requestOptions);
      const responseData = await response.json();
      console.log(responseData);

      if (mode !== "full_options") {
        navigateToNextOption(navigate, '3', mode, optionsToShow);
      } else {
        navigate(
          `/clinical-question5?mode=full_options${page === "ccTreatmentGuide" ? "&page=ccTreatmentGuide" : ""
          }${savedFilter === "true" ? "&checkByCustomNctIds=true" : ""}`
        );
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let array = names.map((item) => {
      return item.text;
    });
    Check_Last_Index(array);
    setImportDataState(true);

    let select_array = [
      { title: "Trial_Sponsor_trial_type", options: trialTypeOptions },
      { title: "Trial_Sponsor_phase_trial", options: phaseTrialOptions },
      { title: "Trial_Sponsor_trial_status", options: trialStatusOptions },
      { title: "Trial_Sponsor_is_interested_in_drug", options: booleanOptions },
      {
        title: "Trial_Sponsor_is_interested_in_med_device",
        options: booleanOptions,
      },
    ];
    select_array.forEach((item) => {
      Import_Save_Data(item.title, item.options);
    });

    const get_opt = window.localStorage.getItem(
      "Trial_Sponsor_Phase_selected_options"
    );
    if (get_opt) {
      Render_At_Load(JSON.parse(get_opt));
    }
  }, []);

  function Import_Save_Data(title, options) {
    const genderOpt = window.localStorage.getItem(title);
    let result;
    if (genderOpt) {
      result = options.find((option) => option.value === genderOpt);
      if (result) {
        switch (title) {
          case "Trial_Sponsor_trial_type":
            setTrialType(result);
            break;
          case "Trial_Sponsor_phase_trial":
            setTrialPhase(result);
            break;
          case "Trial_Sponsor_trial_status":
            setTrialStatus(result);
            break;
          default:
            break;
        }
      }
    }
  }

  useEffect(() => {
    setSaveDataState(true);
    Save_Select_Data();
  }, [trialStatus, trialPhase, trialType]);

  function Save_Select_Data() {
    if (trialType)
      window.localStorage.setItem(`Trial_Sponsor_trial_type`, trialType.value);
    if (trialPhase)
      window.localStorage.setItem(
        `Trial_Sponsor_phase_trial`,
        trialPhase.value
      );
    if (trialStatus)
      window.localStorage.setItem(
        `Trial_Sponsor_trial_status`,
        trialStatus.value
      );
  }

  function Check_Last_Index(array) {
    const lastIndex = array[array.length - 1];
    console.log("last item is", lastIndex);
    if (lastIndex === "Demographics") setLastOption(true);
  }

  function Render_At_Load(selected_items) {
    if (selected_items !== null) {
      setSelectedItems(selected_items);
    }
  }

  const OnSubmitForm2 = async () => {
    try {
      setIsLoading(true);
      const url = STAGGING_BACKEND + "cancer/questionair/cancer_type/";
      const payload = {
        trial_type: trialType.value,
        trial_phase: trialPhase.value,
        trial_status: trialStatus.value,
      };
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "token " + localStorage.getItem("token"),
        },
        body: JSON.stringify(payload),
      };
      const response = await fetch(url, requestOptions);
      const responseData = await response.json();
      console.log(responseData);
      navigate(
        page === "ccTreatmentGuide"
          ? "/cc-treatment-plans"
          : `/stats-drugs${savedFilter === "true" ? "?checkByCustomNctIds=true" : ""
          }`
      );
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsLoading(false);
    }
  };

  function HandleBackBtn() {
    if (mode !== "full_options") {
      navigateToPreviousOption(navigate, '3', mode, optionsToShow);
    } else {
      navigate(
        `/clinical-question3?mode=full_options${page === "ccTreatmentGuide" ? "&page=ccTreatmentGuide" : ""
        }${savedFilter === "true" ? "&checkByCustomNctIds=true" : ""}`
      );
    }
  }

  const handleClearCancel = () => {
    setClearData(false);
  };

  const handleClearConfirm = () => {
    setTrialType(nullValue);
    setTrialPhase(nullValue);
    setTrialStatus(nullValue);
    setSelectedItems([]);
    window.localStorage.removeItem("Trial_Sponsor_Phase_selected_options");
    setClearData(false);
  };
  const handleMenuOpen = () => {
    const container = document.querySelector('.question-form-wrapper');
    if (container) {
      setTimeout(() => {
        if (container.scrollHeight > container.clientHeight) {
          container.scrollTop = container.scrollHeight - container.clientHeight;
        }
      }, 200); 
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <DashboardLayout>
        <h1 className="text-center site-heading">
          {page === "ccTreatmentGuide" ? "CC Treatment Guide" : "Clinical Trial Finder"}
        </h1>
        <QuestionAirTabs names={names} currentTab="3" />
        <div className="activeSection-wrapper">
          <div className="question-form-wrapper">
            <form action="">
              <h2>Trial Type & Sponsor</h2>
              <p>Please input the following data</p>
              <div className="form_container_sec">
                <label htmlFor="">What type of trial are you interested in? </label>
                <Select
                  options={trialTypeOptions}
                  value={trialType}
                  className="select_search"
                  id="trial_type"
                  onChange={(event) => {
                    setTrialType(event);
                  }}
                />

                <label htmlFor="">What phase trial are you interested in? </label>
                <br />
                <section className="multiselect-section">
                  <div className="custom-multiselect-container">
                    <div
                      className="multiselect-label-area"
                      style={{ padding: "0px" }}
                      onClick={() => setShowDropDown(true)}
                    >
                      <input
                        type="text"
                        placeholder="Select Options"
                        value={searchTerm}
                        className="mutationSearchInput"
                        onChange={(e) => setSearchTerm(e.target.value)}
                        onClick={() => setShowDropDown(true)}
                      />
                    </div>
                    {showDropDown && (
                      <div className="mutliselect-options-wrap">
                        <ul id="multiSelectList">
                          {filteredData.map((item, index) => (
                            <li
                              key={index}
                              value={item}
                              className={selectedItems.includes(item) ? "selected_li" : ""}
                              onClick={() => handleListItemClick(item)}
                            >
                              {item}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="select-tags-container" id="select-tags-container">
                    <h6 style={{ margin: "0", paddingTop:"5px" }} className="text-center">Selected Options</h6>
                    {Array.isArray(selectedItems) && selectedItems.map((item, index) => (
                      <div key={index} className="selected-tag">
                        {item}
                        <img
                          src={crossIcon}
                          className="selected-tag-cross"
                          onClick={() => onRemoveTag(item)}
                          alt="Remove tag"
                        />
                      </div>
                    ))}
                  </div>
                </section>

                <label htmlFor="">What trial status are you looking for? </label>
                <Select
                  options={trialStatusOptions}
                  value={trialStatus}
                  className="select_search"
                  id="trial_status"
                  onChange={(event) => {
                    setTrialStatus(event);
                  }}
                  onMenuOpen={() => {
                    handleMenuOpen();
                  }}
                  menuPlacement="auto"
                  styles={{
                    menu: (base) => ({
                      ...base,
                      zIndex: 1000 
                    })
                  }}
                />
              </div>
              <QuestionAirFormFooter
                handleBack={HandleBackBtn}
                handleSubmit={OnSubmitForm}
                handleSubmit2={OnSubmitForm2}
                handleClear={() => setClearData(true)}
                isLastOption={lastOption}
              />
            </form>
          </div>
        </div>
      </DashboardLayout>

      {saveDataState && (
        <SaveData
          feilds={[
            "drug_name",
            "is_interested_in_med_device",
            "med_device_name",
          ]}
          title={"Trial_Sponsor"}
        />
      )}
      {importDataState && (
        <RenderData
          feilds={[
            "drug_name",
            "is_interested_in_med_device",
            "med_device_name",
          ]}
          title={"Trial_Sponsor"}
        />
      )}
      {clearData && (
        <DeleteData
          onCancel={handleClearCancel}
          onConfirm={handleClearConfirm}
          feilds={[
            "trial_type",
            "is_interested_in_drug",
            "drug_name",
            "is_interested_in_med_device",
            "med_device_name",
            "phase_trial",
            "trial_status",
          ]}
        />
      )}
    </>
  );
}

export default ClinicalQuestion4;
