import React, {useEffect} from 'react';

const RenderData = (props) => {
    let feilds = props.feilds;
    let title = props.title;

    useEffect(() => {
        let val;
        feilds.forEach((feild)=>{
          val = window.localStorage.getItem(`${title}_${feild}`);
         
          if(val)
          {
            let elem = document.querySelector(`#${feild}`);
            console.log('elemval', elem)
            if(elem)  
            elem.value = val;
          }
        })    
    }, []);
    return (
        <div>
            {/* {setIsInstitutions(true)} */}
        </div>
    );

}

export default RenderData;
