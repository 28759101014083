import React, { useState, useRef, useEffect } from "react";
import logo from "../../images/logo.png";
import Swal from "sweetalert2";
import DashboardLayout from "../../components/dashboardLayout/dashboardLayout";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import Sidebar from "../../components/sidebar/Sidebar";
import Header from "../../components/Header/Header";
// import logo from "../../images/logo1.png";
function OtpScreen() {
  const navigate = useNavigate();
  const [sideBar, setSideBar] = useState(false);
  const [header, setHeader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const inputRefs = useRef(new Array(6).fill(React.createRef()));
  const [email, setEmail] = useState("");
  
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const emailQueryParam = urlParams.get("email");
    if (emailQueryParam) {
      setEmail(emailQueryParam);
      Swal.fire({
        title: 'Check Your Email',
        text: `We have sent an OTP to ${emailQueryParam}. Please check your email and enter the OTP here to proceed.`,
        iconHtml: `<img src=${logo} width="70" height="70" alt="Custom Icon">`,
        customClass: {
          icon: 'no-border'
        },

        confirmButtonText: 'Okay'
      });
    }
  }, []);

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;
    const newOtp = [...otp];
    newOtp[index] = element.value;
    setOtp(newOtp);
    if (element.nextSibling && element.value) {
      element.nextSibling.focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      if (otp[index] === "") {
        if (index !== 0) {
          inputRefs.current[index - 1].focus();
        }
      } else {
        const newOtp = [...otp];
        newOtp[index] = "";
        setOtp(newOtp);
      }
    }
  };

  useEffect(() => {
    inputRefs.current[0].focus();
  }, []);

  const handleSubmit = () => {
    if (otp.join("").length === 6) {
      setLoading(true);

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email: email, otp: otp.join("") }),
      };
      fetch("https://testapi.cancerclarity.com/user/verify-otp", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          setLoading(false);
          if (data.error === true) {
            Swal.fire("Error", "INVALID VERIFICATION PIN", "error");
          } else {
            navigate(`/update-password?email=${email}`);
          }
        })
        .catch((error) => {
          Swal.fire("Error", "Failed to verify OTP.", "error");
          setLoading(false);
        });
    }
  };
  function handleHeader() {
    setHeader((t) => !t);
  }

  function handleSideBar() {
    setSideBar((t) => !t);
  }
  return (
    <>
    <div>

   
      {loading && <Loader />}
      <div className="mobile-header-section">
          <div
            id="nav-icon4"
            className={!sideBar ? "hamburger" : "hamburger open"}
            onClick={handleSideBar}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
          <img src={logo} style={{ height: "60px" }} alt="" />
          <div
            id="nav-icon3"
            onClick={handleHeader}
            className={!header ? "hamburger" : "hamburger open"}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div className="signup-section-wrapper">
          <Sidebar sideBar={sideBar} />

          <div className="signup-inner-parent">
            <Header header={header} />

        <div className="forgotPassWrapper">
          <h1 className="marginZero site-heading">Enter OTP</h1>
          <p className="marginZero BluetextUnderHeading">
            Please enter the 6-digit OTP sent to your email address.
          </p>
          <div className="otp-box-wrapper mt-4 mb-3">
            {otp.map((data, index) => {
              return (
                <input
                  key={index}
                  className="otpInput"
                  type="text"
                  name={`otp-${index}`}
                  maxLength="1"
                  value={data}
                  onChange={(e) => handleChange(e.target, index)}
                  onFocus={(e) => e.target.select()}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  ref={(el) => (inputRefs.current[index] = el)}
                />
              );
            })}
          </div>

          <div className="input-box-wrapper mb-3">
            <button
              key={otp.join("")}
              className={`gray-button submit-btn ${
                otp.join("").length !== 6 ? "button-disabled" : ""
              }`}
              onClick={handleSubmit}
              disabled={otp.join("").length !== 6}
            >
              Submit
            </button>
          </div>
        </div>
        </div>
        </div>
        </div>
    </>
  );
}

export default OtpScreen;
