import React, { useState, useEffect } from "react";
import Sidebar from "../../../components/sidebar/Sidebar";
import Header from "../../../components/Header/Header";
import birds from "../../../images/birds.png";
import leafs from "../../../images/leafs.png";
import logo from "../../../images/logo.png";
import EditProfileImage from "../../../images/settings.png";
import Classes from "../profile.module.css";
import { Images } from "../../../Utils/Images";
import { Link } from "react-router-dom";
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Swal from "sweetalert2";
import { STAGGING_BACKEND, LOCAL_BACKEND } from '../../../common/helper';


const NutritionTracker = () => {
  const [header, setHeader] = useState(false);
  const [sideBar, setSideBar] = useState(false);
  const [_Date, setDate] = useState('');
  const [_Time, setTime] = useState('');
  const [editorState, setEditorState] = useState('');
  const [editorState2, setEditorState2] = useState('');
  const [meal, setMeal] = useState('');
  const [serving, setServing] = useState('');

  function handleHeader() {
    setHeader((t) => !t);
  }

  function handleSideBar() {
    setSideBar((t) => !t);
  }
  const handleDate = (event) => {
    let date = new Date(event.target.value);
    // let formattedDate = date.toLocaleString('en-US', { weekday: 'short', day: '2-digit', month: 'short' });
    let year = date.getFullYear();
    let month = String(date.getMonth() + 1).padStart(2, '0');
    let day = String(date.getDate()).padStart(2, '0');
    let formattedDate = `${year}-${month}-${day}`;
    console.log(formattedDate)
    setDate(formattedDate)
  }

  const handleTime = (event) => {
    setTime(event.target.value);
  }
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  }
  const onEditorStateChange2 = (editorState2) => {
    setEditorState2(editorState2);
  }
  const onMealContentStateChange = (editorConetent) => {
    setMeal(editorConetent.blocks[0].text);
  }

  const onServingContentStateChange = (editorConetent) => {
    setServing(editorConetent.blocks[0].text);
  }

  const addNutritionTracker = async (event) => {
    event.preventDefault();
    const url = STAGGING_BACKEND + "calendar/nutrition-tracker/";
    const payload = {
      "meal": meal,
      "date": _Date,
      "time": _Time,
      "serving": serving
    }
    const requestOptions = {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        Authorization: "token " + localStorage.getItem("token"),
      },
      body: JSON.stringify(payload)
    };

    const response = await fetch(url, requestOptions);
    const responseData = await response.json();
    if (responseData.error === false) {
      Swal.fire({
        title: "Sucessfull",
        text: responseData.message,
        icon: "success",
        confirmButtonText: "Close",
      });
    } else {
      Swal.fire({
        title: "Error!",
        text: responseData.message,
        icon: "error",
        confirmButtonText: "Try again",
      });
    }

  }

  return (
    <>
      <div className="mobile-header-section">
        <div
          id="nav-icon4"
          className={!sideBar ? "hamburger" : "hamburger open"}
          onClick={handleSideBar}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
        <img src={logo} style={{ height: "60px" }} alt="" />
        <div
          id="nav-icon3"
          onClick={handleHeader}
          className={!header ? "hamburger" : "hamburger open"}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>

      <div className="cc-page-wrapper">
        <Sidebar
          sideBar={sideBar}
          marginTopH6="mt3point5rem"
          margintop2rem="margintop2rem"
        />
        <div className="main-area-inner-wrapper">
          <Header header={header} />

          <div className="new-page-content-wrapper mt-4">
            <h1 className="site-heading">Care Cordinator</h1>

            <div className={Classes.checklistContainer}>
              <div className={Classes.trackerHeader}>
                <h2>Add Nutrition</h2>
                <div>
                  <h3>{_Date} {_Time}</h3>
                </div>
              </div>

              <div className={Classes.trackerFormContainer}>
                <form onSubmit={addNutritionTracker}>
                  <div className="row">
                  <div className="col-lg-6">
                      <label htmlFor="">
                        <b>Q1</b>
                        <span>Date?</span>
                      </label>
                      <input type="date" onChange={handleDate} className="form-control" id="date" />
                    </div>
                    <div className="col-lg-6">
                      <label htmlFor="">
                        <b>Q2</b>
                        <span>Time?</span>
                      </label>
                      <input type="time" onChange={handleTime} name="" className="form-control" id="" />
                    </div>

                    <div className="col-lg-6 mt-5">
                      <label htmlFor="">
                        <b>Q3</b>
                        <span>Please enter the beverage you drank or food you ate?</span>
                      </label>
                      <Editor
                        editorState={editorState}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={onEditorStateChange}
                        onContentStateChange={onMealContentStateChange}
                      />
                    </div>
                    <div className="col-lg-6 mt-5">
                      <label htmlFor="">
                        <b>Q4</b>
                        <span>Approximate servings?</span>
                      </label>
                      <Editor
                        editorState={editorState2}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={onEditorStateChange2}
                        onContentStateChange={onServingContentStateChange}
                      />
                    </div>
                    
                    <div className="col-lg-6 mt-4">
                      <button className="blue-button">
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              </div>

            </div>
          </div>
        </div>
        <img
          src={birds}
          style={{ height: "100px", width: "auto" }}
          className="signup-birds hideInMobile"
          alt=""
        />

      </div>
    </>
  );
};

export default NutritionTracker;
