import { auth } from "./firebase";

import { GoogleAuthProvider, TwitterAuthProvider, OAuthProvider } from "firebase/auth";
import { signInWithPopup } from "firebase/auth";
import { FacebookAuthProvider } from "firebase/auth";
import { Navigate } from "react-router-dom";
import { STAGGING_BACKEND } from "../common/helper";

const GoogleProvider = new GoogleAuthProvider();
const FacebookProvider = new FacebookAuthProvider();
const TwitterProvider = new TwitterAuthProvider();
const appleProvider = new OAuthProvider('apple.com');

export const encodeJWT = async (payload) => {
    const api = `${STAGGING_BACKEND}user/social-auth-token`
    const requestOption = {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
    };
    const response = await fetch(api, requestOption);
    const data = await response.json();

    if (data.error === false) {
        localStorage.setItem("token", data.data.token);
        localStorage.setItem("user", data.data.email);
        window.location.reload();
    }
}

export const GoogleAuth = () => {
    signInWithPopup(auth, GoogleProvider).then(
        (data) => {
            console.log(data);
            // alert('test')
            const payload = {
                "email": data._tokenResponse.email,
                "first_name": data._tokenResponse.firstName,
                "last_name": data._tokenResponse.lastName,
                "username": data._tokenResponse.fullName,
            };
            encodeJWT(payload);
        }
    ).catch(
        (error) => {
            console.log(error);
        }
    )
}

export const FacebookAuth = () => {
    signInWithPopup(auth, FacebookProvider).then(
        (data) => {
            console.log(data);
            const payload = {
                "email": data._tokenResponse.email,
                "first_name": data._tokenResponse.firstName,
                "last_name": data._tokenResponse.lastName,
                "username": data._tokenResponse.fullName,
            };
            encodeJWT(payload);
        }
    ).catch(
        (error) => {
            console.log(error);
        }
    )
}

export const AppleAuth = () => {
    signInWithPopup(auth, appleProvider).then(
        (data) => {
            console.log(data);
            const payload = {
                "email": data._tokenResponse.email,
                "first_name": data._tokenResponse.firstName,
                "last_name": data._tokenResponse.lastName,
                "username": data._tokenResponse.displayName,
            }
            encodeJWT(payload);
        }
    ).catch(
        (error) => {
            console.log(error);
        }
    )
}

