import { initializeApp } from "firebase/app";
import {getAuth } from "firebase/auth";

// const firebaseConfig = {
//   apiKey: "AIzaSyBIszYCFVl3EM0LFQ-iJVVq8LAzoGI3F2w",
//   authDomain: "cancerclarity-46113.firebaseapp.com",
//   projectId: "cancerclarity-46113",
//   storageBucket: "cancerclarity-46113.appspot.com",
//   messagingSenderId: "271211704006",
//   appId: "1:271211704006:web:9027a1b8c7979c913171c7",
//   measurementId: "G-GW05YF4ZL8"
// };

const firebaseConfig = {
  apiKey: "AIzaSyBMWXphdXPwz5Y1oTa_sCu_L3pCgUpCshY",
  authDomain: "social-authentications-25a24.firebaseapp.com",
  projectId: "social-authentications-25a24",
  storageBucket: "social-authentications-25a24.appspot.com",
  messagingSenderId: "55845534434",
  appId: "1:55845534434:web:f826a251613f84fa67fcbb"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
