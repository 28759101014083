import React, { useState } from "react";
import logo from "../../images/logo.png";
import birds from "../../images/birds.png";
import leafs from "../../images/leafs.png";
import "font-awesome/css/font-awesome.min.css";
import Sidebar from "../../components/sidebar/Sidebar";
import Header from "../../components/Header/Header";
import { useDispatch } from "react-redux";
import { call } from "../../redux/slices/contact";
import Swal from "sweetalert2";

function ContactUs() {
  const [header, setHeader] = useState(false);
  const [sideBar, setSideBar] = useState(false);
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    organizationName: "",
    role: "",
    email: "",
    contactNo: "",
    message: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

 const handleSubmit = (event) => {
  event.preventDefault();
  const errors = validate(formValues);
  setFormErrors(errors);
  if (Object.keys(errors).length === 0) {
    dispatch(call(formValues))
      .unwrap()
      .then((promiseResult) => {
        setFormValues({
          firstName: "",
          lastName: "",
          organizationName: "",
          role: "",
          email: "",
          contactNo: "",
          message: "",
        });

        Swal.fire({
          title: "Successful!",
          text: promiseResult.message,
          icon: "success",
          confirmButtonText: "Cool",
        });
      })
      .catch((serverRejection) => {
        Swal.fire({
          title: "Error!",
          text: serverRejection.message,
          icon: "error",
          confirmButtonText: "Try again",
        });
      });
  }
};


  const validate = (values) => {
    const errors = {};
    if (!values.firstName) {
      errors.firstName = "First Name is required";
    }
    if (!values.lastName) {
      errors.lastName = "Last Name is required";
    }
    
    if (!values.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = "Email is invalid";
    }
    
    if (!values.message) {
      errors.message = "Message is required";
    }
    return errors;
  };

  function handleHeader() {
    setHeader((t) => !t);
  }

  function handleSideBar() {
    setSideBar((t) => !t);
  }

  return (
    <>
      <div className="mobile-header-section">
        <div
          id="nav-icon4"
          className={!sideBar ? "hamburger" : "hamburger open"}
          onClick={handleSideBar}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
        <img src={logo} style={{ height: "60px" }} alt="Logo" />
        <div
          id="nav-icon3"
          onClick={handleHeader}
          className={!header ? "hamburger" : "hamburger open"}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div className="contact-section-wrapper">
        <Sidebar
          sideBar={sideBar}
          marginTopH6="mt3point5rem"
          margintop2rem="margintop2rem"
        />
        <div className="contact-inner-parent">
          <Header header={header} />

          <div className="contact-form-main-area">
            <div className="contact-form-wrapper mt-2">
              <h1 className="text-center site-heading">Contact Us</h1>
              <form onSubmit={handleSubmit} className="mt-3">
                {/* Form fields */}
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group mb-2">
                      <label htmlFor="firstName">First Name:</label>
                      <input
                        type="text"
                        name="firstName"
                        className={`custom-form-control ${
                          formErrors.firstName ? "input-error" : ""
                        }`}
                        onChange={handleChange}
                        value={formValues.firstName}
                      />
                      {formErrors.firstName && (
                        <p className="formvalidation-error-text">
                          {formErrors.firstName}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-group mb-2">
                      <label htmlFor="firstName">Last Name:</label>
                      <input
                        type="text"
                        name="lastName"
                        className={`custom-form-control ${
                          formErrors.lastName ? "input-error" : ""
                        }`}
                        onChange={handleChange}
                        value={formValues.lastName}
                      />
                      {formErrors.lastName && (
                        <p className="formvalidation-error-text">
                          {formErrors.lastName}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-group mb-2">
                      <label htmlFor="firstName">Organization Name:</label>
                      <input
                        type="text"
                        name="organizationName"
                        className={`custom-form-control ${
                          formErrors.organizationName ? "input-error" : ""
                        }`}
                        onChange={handleChange}
                        value={formValues.organizationName}
                      />

                      {formErrors.organizationName && (
                        <p className="formvalidation-error-text">
                          {formErrors.organizationName}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-group mb-2">
                      <label htmlFor="firstName">Role:</label>
                      <input
                        type="text"
                        name="role"
                        className={`custom-form-control ${
                          formErrors.role ? "input-error" : ""
                        }`}
                        onChange={handleChange}
                        value={formValues.role}
                      />
                      {formErrors.role && (
                        <p className="formvalidation-error-text">
                          {formErrors.role}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-group mb-2">
                      <label htmlFor="firstName">Email:</label>
                      <input
                        type="text"
                        name="email"
                        className={`custom-form-control ${
                          formErrors.email ? "input-error" : ""
                        }`}
                        onChange={handleChange}
                        value={formValues.email}
                      />
                      {formErrors.email && (
                        <p className="formvalidation-error-text">
                          {formErrors.email}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-group mb-2">
                      <label htmlFor="firstName">Contact No:</label>
                      <input
                        type="number"
                        name="contactNo"
                        className={`custom-form-control ${
                          formErrors.contactNo ? "input-error" : ""
                        }`}
                        onChange={handleChange}
                        value={formValues.contactNo}
                      />
                      {formErrors.contactNo && (
                        <p className="formvalidation-error-text">
                          {formErrors.contactNo}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="form-group mb-2">
                      <label htmlFor="firstName">
                        Brief Description of Inquiry:
                      </label>
                      <textarea
                        name="message"
                        className={`custom-form-control ${
                          formErrors.message ? "input-error" : ""
                        }`}
                        onChange={handleChange}
                        value={formValues.message}
                        rows={7}
                      />
                      {formErrors.message && (
                        <p className="formvalidation-error-text">
                          {formErrors.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <input
                  type="submit"
                  className="gray-button submit-btn mt-3"
                  style={{ color: "#fff" }}
                  value="Send"
                />
              </form>
            </div>
            {/* contact-form-wrapper */}
          </div>
          {/* contact-form-main-area */}
        </div>
        {/* contact-inner-parent */}
        <img
          src={birds}
          style={{ height: "100px", width: "auto" }}
          className="signup-birds hideInMobile"
          alt="Birds"
        />
        <img
          src={leafs}
          style={{ height: "150px", width: "auto" }}
          className="signup-ground hideInMobile"
          alt="Leafs"
        />
      </div>
    </>
  );
}

export default ContactUs;
