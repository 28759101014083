
// navigationUtils.js or a similar utility file
import { questionAirOptionCards } from './optionsHandling';


  export const saveToLocalStorage = (key, value) => {
  window.localStorage.setItem(key, JSON.stringify(value));
};

export const loadFromLocalStorage = (key) => {
  const value = window.localStorage.getItem(key);
  return value ? JSON.parse(value) : null;
};

export const clearFromLocalStorage = (key) => {
  window.localStorage.removeItem(key);
};

export const navigateToNextQuestion = (
  currentIndex,
  questionsArray,
  navigateFunction,
  queryParams
) => {
  const nextIndex = (currentIndex + 1) % questionsArray.length;
  navigateFunction(questionsArray[nextIndex].route + queryParams);
};

export const navigateToPreviousQuestion = (
  currentIndex,
  questionsArray,
  navigateFunction,
  queryParams
) => {
  const previousIndex =
    (currentIndex - 1 + questionsArray.length) % questionsArray.length;
  navigateFunction(questionsArray[previousIndex].route + queryParams);
};

export const shouldRenderSection = (option) => {
  return option && option.value === "1";
};

// utils/namesGenerator.js
export const generateNamesArray = (mode, pageOptions, savedFilter, page, optionsToShow) => {
  const baseRoute = (route) => {
    let url = `${route}`;
    const params = new URLSearchParams();
    if (mode !== "full_options") {
      params.append("optionsToShow", optionsToShow);
    } else {
      params.append("mode", mode);
    }
    if (page) params.append("page", page);
    if (savedFilter === "true") params.append("checkByCustomNctIds", "true");
    if (params.toString()) url += `?${params.toString()}`;
    return url;
  };
  
    let names = [];
  
    if (mode === "full_options") {
      const fullOptionRoutes = [
        "clinical-questions",
        "clinical-question2",
        "clinical-question3",
        "clinical-question4",
        "clinical-question5",
        "clinical-question6",
        "clinical-question7",
        "clinical-question8",
        "clinical-question9"
        // Add more routes if necessary
      ];
  
      const fullOptionTexts = [
        "Cancer Type & Stage",
        "Patient Characteristics",
        "Location",
        "Trial Type & Sponsor",
        "Clinical Characteristics",
        "Cancer Characteristics",
        "Molecular Profile",
        "Cancer Treatment History",
        "Drug Name & Mechanism of Action"
        // Add more texts corresponding to the routes
      ];
  
      fullOptionRoutes.forEach((route, index) => {
        names.push({
          text: fullOptionTexts[index],
          route: baseRoute(`/${route}`)
        });
      });
    } else {
      
      pageOptions.forEach(element => {
        const optionCard = questionAirOptionCards.find(card => card.name.replace(/ & /g, "_").replace(/ /g, "_") === element);
        let text = element.replace(/_/g, " ");
        let route = optionCard ? baseRoute(`/${optionCard.page}`) : "";
        let tab = optionCard ? optionCard.tab : "";
  
        names.push({
          text,
          route,
          tab 
        });
      });
    }
    return names;
  };
  

  export const handleSwitchStatement = (nextItem, navigate, page, savedFilter, optionsToShow) => {
    const routeBase = (route) => {
      return `${route}?mode=full_options${page ? `&page=${page}` : ""}${savedFilter ? "&checkByCustomNctIds=true" : ""}${optionsToShow ? `&optionsToShow=${optionsToShow}` : ""}`;
    };
    
  
    switch (nextItem) {
      case "Cancer Type & Stage":
        navigate(routeBase("/clinical-questions"));
        break;
      case "Molecular Profile":
        navigate(routeBase("/clinical-question7"));
        break;
      case "Drug & Mechanism":
        navigate(routeBase("/clinical-question9"));
        break;
      case "Treatment History":
        navigate(routeBase("/clinical-question8"));
        break;
      case "Location":
        navigate(routeBase("/clinical-question3"));
        break;
      case "Cancer Characteristics":
        navigate(routeBase("/clinical-question6"));
        break;
      case "Functional Status":
        navigate(routeBase("/clinical-question5"));
        break;
      case "Demographics":
        navigate(routeBase("/clinical-question2"));
        break;
      default:
        // Optional: handle default case
        break;
    }
  };
  
  
  
  
  
  export function navigateBasedOnNextItem(
    nextItem,
    page,
    savedFilter,
    navigate
  ) {
    switch (nextItem) {
      case "Molecular Profile":
        navigate(
          `/clinical-question7${
            page === "ccTreatmentGuide" ? "&page=ccTreatmentGuide" : ""
          }${savedFilter === "true" ? "&checkByCustomNctIds=true" : ""}`
        );
        break;
      case "Drug & Mechanism":
        navigate(
          `/clinical-question9${
            page === "ccTreatmentGuide" ? "&page=ccTreatmentGuide" : ""
          }${savedFilter === "true" ? "&checkByCustomNctIds=true" : ""}`
        );
        break;
      case "Treatment History":
        navigate(
          `/clinical-question8${
            page === "ccTreatmentGuide" ? "&page=ccTreatmentGuide" : ""
          }${savedFilter === "true" ? "&checkByCustomNctIds=true" : ""}`
        );
        break;
      case "Location":
        navigate(
          `/clinical-question3${
            page === "ccTreatmentGuide" ? "&page=ccTreatmentGuide" : ""
          }${savedFilter === "true" ? "&checkByCustomNctIds=true" : ""}`
        );
        break;
      case "Cancer Characteristics":
        navigate(
          `/clinical-question6${
            page === "ccTreatmentGuide" ? "&page=ccTreatmentGuide" : ""
          }${savedFilter === "true" ? "&checkByCustomNctIds=true" : ""}`
        );
        break;
      case "Clinical Characteristics":
        navigate(
          `/clinical-question5${
            page === "ccTreatmentGuide" ? "&page=ccTreatmentGuide" : ""
          }${savedFilter === "true" ? "&checkByCustomNctIds=true" : ""}`
        );
        break;
      case "Demographics":
        navigate(
          `/clinical-question2${
            page === "ccTreatmentGuide" ? "&page=ccTreatmentGuide" : ""
          }${savedFilter === "true" ? "&checkByCustomNctIds=true" : ""}`
        );
        break;
      default:
    }
  }
  export function getQueryParam(paramName, search) {
    const searchParams = new URLSearchParams(search);
    return searchParams.get(paramName);
  } // Adjust the import path as necessary

export const navigateToPreviousOption = (navigate, currentIndex, mode, optionsToShow) => {
  const indicesToShow = optionsToShow.split(",").map(str => str);
  const currentIndexPosition = indicesToShow.indexOf(currentIndex.toString());

  let previousIndex = null;
  if (currentIndexPosition > 0) {
    previousIndex = indicesToShow[currentIndexPosition - 1];
  } // No else needed if you don't want wrapping behavior

  if (previousIndex !== null) {
    const previousOptionCard = questionAirOptionCards.find(card => card.id.toString() === previousIndex);
    if (previousOptionCard) {
      navigate(`/${previousOptionCard.page}?mode=${mode}&optionsToShow=${optionsToShow}`);
    }
  }
};


export const navigateToNextOption = (navigate, currentIndex, mode, optionsToShow) => {
  const indicesToShow = optionsToShow.split(",").map(str => str);
  const currentIndexPosition = indicesToShow.indexOf(currentIndex.toString());

  let nextIndex = null;
  if (currentIndexPosition !== -1 && currentIndexPosition < indicesToShow.length - 1) {
    nextIndex = indicesToShow[currentIndexPosition + 1];
  }
  

  if (nextIndex !== null) {
    const nextOptionCard = questionAirOptionCards.find(card => card.id.toString() == nextIndex);
    
    if (nextOptionCard) {
      navigate(`/${nextOptionCard.page}?mode=${mode}&optionsToShow=${optionsToShow}`);
    }
  }
};



export const getPageOptionsFromIndices = (optionsToShow) => {
  const indicesToShow = optionsToShow
    ? optionsToShow.split(",").map(Number)
    : [];
  
  const pageOptions = indicesToShow
    .map((index) => {
      const card = questionAirOptionCards.find((card) => card.id === index);
      return card ? card.name.replace(/ & /g, "_").replace(/ /g, "_") : null; // Format to match expected pageOptions format
    })
    .filter((name) => name !== null); // Exclude null values if card not found

  return pageOptions;
};