import React, { useState, useEffect } from "react";
import logo from "../../images/logo.png";
import signupBirds from "../../images/signup-birds.png";
import signupGround from "../../images/signup-ground.png";
import googleG from "../../images/g.svg";
import "font-awesome/css/font-awesome.min.css";
import { Link } from "react-router-dom";
import Sidebar from "../../components/sidebar/Sidebar";
import Header from "../../components/Header/Header";
import { GoogleAuth, FacebookAuth, AppleAuth } from "../../firebase/authentication";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { login } from "../../redux/slices/userAuth";
import Swal from 'sweetalert2';
import { Navigate } from "react-router-dom";
import TrialCriteria from "../trailCriteria/trialCriteria";
import { useNavigate } from "react-router-dom";
import { STAGGING_BACKEND, LOCAL_BACKEND } from '../../common/helper';
import Loader from "../../components/Loader/Loader"; // Import the Loader component

function Login() {
  const [header, setHeader] = useState(false);
  const [sideBar, setSideBar] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showEyeIcon, setShowEyeIcon] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = new useDispatch();
  const response = useSelector((state) => state.userAuth);
  const navigate = useNavigate();

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  const foo = getParameterByName("backToURL"); // "lorem"

  function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return "/";
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  function showHidePassword() {
    setShowPassword((t) => !t);
  }

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i;
    return re.test(email);
  };

  const validateEmailRealTime = (email) => {
    if (!email) {
      setEmailError("Email is required");
      return false;
    } else if (!validateEmail(email)) {
      setEmailError("Invalid Email");
      return false;
    } else {
      setEmailError("");
      return true;
    }
  };
  
  const validatePasswordRealTime = (password) => {
    if (!password) {
      setPasswordError("Password is required");
      return false;
    } else {
      setPasswordError("");
      return true;
    }
  };
  const submit = async (event) => {
    event.preventDefault();
    
    const isEmailValid = validateEmailRealTime(email);
    const isPasswordValid = validatePasswordRealTime(password);
  
    if (!isEmailValid || !isPasswordValid) {
      return; // Stop if there are validation errors
    }
  
    setLoading(true); // Set loading to true when request starts
    const data = { email: email, password: password };
    const url = STAGGING_BACKEND + "user/login";
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
  
    try {
      const response = await fetch(url, requestOptions);
      const responseData = await response.json();
      setLoading(false); // Set loading to false when response is received
      if (responseData?.error === false && responseData?.data?.token) {
        Swal.fire({
          title: "Successful!",
          text: responseData.message,
          icon: "success",
          confirmButtonText: "Cool",
        });
        localStorage.setItem("token", responseData.data.token);
        localStorage.setItem("user", responseData.data.name);
        localStorage.setItem("test", JSON.stringify(responseData.data));
        navigate("/services");
      } else {
        Swal.fire({
          title: "Error!",
          text: responseData.message,
          icon: "error",
          confirmButtonText: "Try again",
        });
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: "Error!",
        text: "An error occurred. Please try again.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  useEffect(() => {
    if (password !== "") {
      setShowEyeIcon(true);
    } else {
      setShowEyeIcon(false);
    }
  }, [password]);

  function handleHeader() {
    setHeader((t) => !t);
  }

  function handleSideBar() {
    setSideBar((t) => !t);
  }

  if (localStorage.getItem("token")) {
    return <Navigate to="/" />;
  } else {
    return (
      <div>
        {loading && <Loader />}
        <div className="mobile-header-section">
          <div
            id="nav-icon4"
            className={!sideBar ? "hamburger" : "hamburger open"}
            onClick={handleSideBar}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
          <img src={logo} style={{ height: "60px" }} alt="" />
          <div
            id="nav-icon3"
            onClick={handleHeader}
            className={!header ? "hamburger" : "hamburger open"}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div className="signup-section-wrapper">
          <Sidebar sideBar={sideBar} />

          <div className="signup-inner-parent">
            <Header header={header} />

            <div className="signup-form-main-area">
              <div className="signup-form-wrapper mt-5">
                <h1 className="marginZero site-heading">Welcome!</h1>

                <p className="marginZero BluetextUnderHeading">
                  Sign into your account
                </p>
                <form action="" className="login-form" onSubmit={submit}>
                  <div className="row">
                    <div className="col-lg-12 px-1">
                      <div className="input-box-wrapper mb-3">
                        <input
                          type="text"
                          name="userName"
                          className={`signup-box-input loginfields ${
                            emailError ? "error-input" : ""
                          }`}
                          placeholder="Email"
                          id=""
                          value={email}
                          onChange={(event) => {
                            setEmail(event.target.value);
                            validateEmailRealTime(event.target.value);
                          }}
                        />
                        {emailError && (
                          <small className="error-message">{emailError}</small>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-12 px-1">
                      <div className="input-box-wrapper mb-3">
                        <input
                          type={showPassword ? "text" : "password"}
                          name="password"
                          className={`signup-box-input loginfields ${
                            passwordError ? "error-input" : ""
                          }`}
                          placeholder="Password"
                          id=""
                          value={password}
                          onChange={(event) => {
                            setPassword(event.target.value);
                            validatePasswordRealTime(event.target.value);
                          }}
                        />
                        {passwordError && (
                          <small className="error-message">
                            {passwordError}
                          </small>
                        )}
                        {showEyeIcon && (
                          <i
                            className="far fa-eye password-eye-icon"
                            onClick={showHidePassword}
                          ></i>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-12 px-1">
                      <div className="input-box-wrapper d-flex justify-content-center mb-3">
                        <a
                          href="/forgot-password"
                          className="text-center forgotPass"
                        >
                          Forgot Password?
                        </a>
                      </div>
                    </div>

                    <div className="col-lg-12 px-1">
                      <div className="input-box-wrapper mb-3">
                        <input
                          type="submit"
                          className="gray-button submit-btn"
                          value="SIGN IN"
                        />
                        <p className="or-txt">or</p>
                        <p className="text-center mt-1">
                          Sign in with Social accounts
                        </p>
                        <div
                          className="d-flex mx-auto"
                          style={{ width: "fit-content" }}
                        >
                          <div
                            className="social-icon-circle align-items-end"
                            onClick={FacebookAuth}
                          >
                            <i className="fa fa-facebook-f"></i>
                          </div>
                          <div
                            className="social-icon-circle"
                            style={{ backgroundColor: "whitesmoke" }}
                          >
                            <img src={googleG} alt="" onClick={GoogleAuth} />
                          </div>

                          <div
                            className="social-icon-circle"
                            onClick={AppleAuth}
                          >
                            <i className="fa-brands fa-apple"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <img src={signupBirds} className="signup-birds hideInMobile" alt="" />
          <img
            src={signupGround}
            className="signup-ground hideInMobile"
            alt=""
          />
        </div>
      </div>
    );
  }
}

export default Login;
