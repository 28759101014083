import React, { useState, useEffect, useRef } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import SaveData from "../../components/Save_Data/Save_Data";
import RenderData from "../../components/Save_Data/Render_Data";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DeleteData from "../../components/Save_Data/Delete_Data";
import { STAGGING_BACKEND, LOCAL_BACKEND } from "../../common/helper";
import Select from "react-select";
import DashboardLayout from "../../components/dashboardLayout/dashboardLayout";
import QuestionAirFormFooter from "../../components/questionAirFormFooter/questionAirFormFooter";
import QuestionAirTabs from "../../components/questionairTabs/questionairTabs";
import {
  saveToLocalStorage,
  loadFromLocalStorage,
  generateNamesArray,
  handleSwitchStatement,
  clearFromLocalStorage,
  navigateToNextQuestion,
  navigateToPreviousQuestion,
  shouldRenderSection,
} from "../../libs/Util.js";
import { booleanOptions } from "../../libs/optionsHandling.js";
import { useLocation } from "react-router-dom";
import {
  navigateToPreviousOption,
  navigateToNextOption,
  getPageOptionsFromIndices,
} from "../../libs/Util";
import Loader from "../../components/Loader/Loader.js";

import FirstTreatmentDropdown from "./questions/firstTreamentDropdown.js";
import SecondTreatmentDropdown from "./questions/secondTreamentDropdown.js";
import ThirdTreatmentDropdown from "./questions/thirdTreamentDropdown.js";
import FourthTreatmentDropdown from "./questions/fourthTreamentDropdown.js";
import FifthTreatmentDropdown from "./questions/fifthTreamentDropdown.js";

function ClinicalQuestion8() {
  const location = useLocation();

  const [header, setHeader] = useState(false);
  const [sideBar, setSideBar] = useState(false);
  const [lastOption, setLastOption] = useState(false);
  const [isTreated, setIsTreated] = useState({ label: "", value: "" });
  const [firstTreatment, setFirstTreatment] = useState("");
  const [secondTreatment, setSecondTreatment] = useState("");
  const [thirdTreatment, setThirdTreatment] = useState("");
  const [fourthTreatment, setFourthTreatment] = useState("");
  const [fifthTreatment, setFifthTreatment] = useState("");
  const [clearData, setClearData] = useState(false);
  const [saveDataState, setSaveDataState] = useState(false);
  const [importDataState, setImportDataState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const firstTreatmentRef = useRef();
  const secondTreatmentRef = useRef();
  const thirdTreatmentRef = useRef();
  const fourthTreatmentRef = useRef();
  const fifthTreatmentRef = useRef();

  const selector = useSelector((state) => state.options);
  const navigate = useNavigate();

  const urlParams = new URLSearchParams(window.location.search);
  const page = urlParams.get("page");
  const mode = urlParams.get("mode");
  const nullValue = { label: "choose below", value: "" };
  const savedFilter = urlParams.get("checkByCustomNctIds");
  const optionsToShow = new URLSearchParams(location.search).get(
    "optionsToShow"
  );
  const pageOptions = getPageOptionsFromIndices(optionsToShow);
  let names = generateNamesArray(
    mode,
    pageOptions,
    savedFilter,
    page,
    optionsToShow
  );

  const OnSubmitForm = async () => {
    try { 
      setIsLoading(true);
      const url = STAGGING_BACKEND + "cancer/questionair/cancer_type/";
      let payload = {};
      if (isTreated.value == "1") {
        payload = {
          treatment: true,
          first_treatment: firstTreatment,
          second_treatment: secondTreatment,
          third_treatment: thirdTreatment,
          fourth_treatment: fourthTreatment,
          fifth_treatment: fifthTreatment,
        };
      } else {
        payload = {
          treatment: false,
        };
      }
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "token " + localStorage.getItem("token"),
        },
        body: JSON.stringify(payload),
      };
      const response = await fetch(url, requestOptions);
      const responseData = await response.json();
      if (mode != "full_options") {
        navigateToNextOption(navigate, "7", mode, optionsToShow);
      } else {
        navigate(
          `/clinical-question9?mode=full_options${
            page === "ccTreatmentGuide" ? "&page=ccTreatmentGuide" : ""
          }${savedFilter === "true" ? "&checkByCustomNctIds=true" : ""}`
        );
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let array = names.map((item) => {
      return item.text;
    });
    Check_Last_Index(array);
    setImportDataState(true);

    let select_array = [
      { title: "Treatment_History_is_treated", options: booleanOptions },
    ];
    select_array.forEach((item) => {
      Import_Save_Data(item.title, item.options);
    });
  }, []);

  function Import_Save_Data(title, options) {
    const genderOpt = window.localStorage.getItem(title);
    if (genderOpt) {
      const result = options.find((option) => option.value === genderOpt);
      if (result) {
        console.log("typee", result, title);
        switch (title) {
          case "Treatment_History_is_treated":
            setIsTreated(result);
            break;
          default:
            break;
        }
      }
    }
  }

  useEffect(() => {
    setSaveDataState(true);
    Save_Select_Data();
  }, [
    isTreated,
    firstTreatment,
    secondTreatment,
    thirdTreatment,
    fourthTreatment,
    fifthTreatment,
  ]);

  function Save_Select_Data() {
    if (isTreated)
      window.localStorage.setItem(
        `Treatment_History_is_treated`,
        isTreated.value
      );
  }

  function Check_Last_Index(array) {
    const lastIndex = array[array.length - 1];
    console.log("last item is", lastIndex);
    if (lastIndex == "Treatment History") setLastOption(true);
  }

  const OnSubmitForm2 = async () => {
    try {
      setIsLoading(true);
      const url = STAGGING_BACKEND + "cancer/questionair/cancer_type/";
      let payload = {};
      if (isTreated == "1") {
        payload = {
          treatment: true,
          first_treatment: firstTreatment,
          second_treatment: secondTreatment,
          third_treatment: thirdTreatment,
          fourth_treatment: fourthTreatment,
          fifth_treatment: fifthTreatment,
        };
      } else {
        payload = {
          treatment: false,
        };
      }
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "token " + localStorage.getItem("token"),
        },
        body: JSON.stringify(payload),
      };
      const response = await fetch(url, requestOptions);
      const responseData = await response.json();
      navigate(
        page === "ccTreatmentGuide"
          ? "/cc-treatment-plans"
          : `/stats-drugs${
              savedFilter === "true" ? "?checkByCustomNctIds=true" : ""
            }`
      );
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  function HandleBackBtn() {
    if (mode != "full_options") {
      navigateToPreviousOption(navigate, "7", mode, optionsToShow);
    } else {
      navigate(
        `/clinical-question7?mode=full_options${
          page === "ccTreatmentGuide" ? "&page=ccTreatmentGuide" : ""
        }
      ${savedFilter === "true" ? "&checkByCustomNctIds=true" : ""}`
      );
    }
  }

  const handleClearCancel = () => {
    setClearData(false);
  };

  const handleClearConfirm = () => {
    setIsTreated(nullValue);
    setClearData(false);
  };

  const onFirstTreatmentSelect = (firstTreatment) => {
    setFirstTreatment(firstTreatment.value);
  };
  const onSecondTreatmentSelect = (secondTreatment) => {
    setSecondTreatment(secondTreatment.value);
  };
  const onThirdTreatmentSelect = (thirdTreatment) => {
    setThirdTreatment(thirdTreatment.value);
  };
  const onFourthTreatmentSelect = (fourthTreatment) => {
    setFourthTreatment(fourthTreatment.value);
  };
  const onFifthTreatmentSelect = (fifthTreatment) => {
    setFifthTreatment(fifthTreatment.value);
  };

  return (
    <>
    {
      isLoading && <Loader/>
    }
      <DashboardLayout>
        <div className="clinical-question-main-area mt-4">
          <h1 className="text-center site-heading">
            {page === "ccTreatmentGuide"
              ? "CC Treatment Guide"
              : "Clinical Trial Finder"}
          </h1>
          <QuestionAirTabs names={names} currentTab="7" />

          <div className="activeSection-wrapper">
            <div className="question-form-wrapper">
              <form action="">
                <h2>Cancer Treatment History</h2>
                <p>Please input the following data</p>
                <div className="form_container_sec" >
                  <label htmlFor="">
                    Have you been treated for your cancer?{" "}
                  </label>
                  <Select
                    options={booleanOptions}
                    value={isTreated}
                    className="select_search"
                    id="is_treated"
                    onChange={(event) => {
                      setIsTreated(event);
                    }}
                    styles={{
                      menu: (base) => ({
                        ...base,
                        zIndex: 1000 
                      })
                    }}
                  />
                  {isTreated.value === "1" ? (
                    <>
                      <label htmlFor="">
                        What treatment did you receive first?
                      </label>
                      <FirstTreatmentDropdown onFirstTreatmentSelect={onFirstTreatmentSelect} />

                      <label htmlFor="">
                        What treatment did you receive second?
                      </label>
                      <SecondTreatmentDropdown onSecondTreatmentSelect={onSecondTreatmentSelect} />

                      <label htmlFor="">
                        What treatment did you receive third?
                      </label>
                      <ThirdTreatmentDropdown onThirdTreatmentSelect={onThirdTreatmentSelect} />

                      <label htmlFor="">
                        {" "}
                        What treatment did you receive fourth?
                      </label>
                      <FourthTreatmentDropdown onFourthTreatmentSelect={onFourthTreatmentSelect} />

                      <label htmlFor="">
                        What treatment did you receive fifth?
                      </label>
                      <FifthTreatmentDropdown onFifthTreatmentSelect={onFifthTreatmentSelect} />
                    </>
                  ) : null}
                </div>
                <QuestionAirFormFooter
                  handleBack={HandleBackBtn}
                  handleSubmit={OnSubmitForm}
                  handleSubmit2={OnSubmitForm2}
                  handleClear={() => setClearData(true)}
                  isLastOption={lastOption}
                />
              </form>
            </div>
          </div>
        </div>
      </DashboardLayout>

      {saveDataState && (
        <SaveData
          feilds={[
            "is_treated",
            "first_treatment",
            "second_treatment",
            "third_treatment",
            "fourth_treatment",
            "fifth_treatment",
          ]}
          title={"Treatment_History"}
        />
      )}
      {importDataState && (
        <RenderData
          feilds={[
            "is_treated",
            "first_treatment",
            "second_treatment",
            "third_treatment",
            "fourth_treatment",
            "fifth_treatment",
          ]}
          title={"Treatment_History"}
        />
      )}
      {clearData && (
        <DeleteData
          onCancel={handleClearCancel}
          onConfirm={handleClearConfirm}
          feilds={[
            "is_treated",
            "first_treatment",
            "second_treatment",
            "third_treatment",
            "fourth_treatment",
            "fifth_treatment",
          ]}
        />
      )}
    </>
  );
}

export default ClinicalQuestion8;
