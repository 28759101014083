import React, { useState, useEffect, useRef } from 'react';
import { STAGGING_BACKEND } from "../../../common/helper";

const SecondTreatmentDropdown = ({ onSecondTreatmentSelect }) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedTreatment, setSelectedTreatment] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [treatments, setTreatments] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const dropdownRef = useRef(null);
    
    const apiUrl = STAGGING_BACKEND + "cancer/questionair/get-filtered-items/";

    // Retrieve stored value from localStorage when the component mounts
    useEffect(() => {
        const storedTreatment = JSON.parse(localStorage.getItem('selectedSecondTreatment'));
        if (storedTreatment) {
            setSelectedTreatment(storedTreatment.label);  // Set the stored treatment label in input
            setSearchTerm(storedTreatment.label);         // Optionally, set the search term for display
            onSecondTreatmentSelect(storedTreatment);     // Send the stored treatment as a payload on load
        }

        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onSecondTreatmentSelect]);

    // Handle searching and fetching treatments
    useEffect(() => {
        if (searchTerm && showDropdown) {
            const debounceTimeout = setTimeout(() => {
                setIsSearching(true);
                const requestOptions = {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "token " + localStorage.getItem("token"),
                    },
                };
                fetch(`${apiUrl}?filter=unique_treatments&query=${encodeURIComponent(searchTerm)}`, requestOptions)
                    .then(response => response.json())
                    .then(data => {
                        setTreatments(data.data);
                        setIsSearching(false);
                    })
                    .catch(error => {
                        console.error('Error fetching treatments:', error);
                        setIsSearching(false);
                    });
            }, 300); // Debounce time

            return () => clearTimeout(debounceTimeout);
        } else {
            setTreatments([]);
        }
    }, [searchTerm, apiUrl, showDropdown]);

    // Handle treatment selection
    const handleTreatmentSelect = (treatment) => {
        setSelectedTreatment(treatment.label);
        setSearchTerm('');
        setShowDropdown(false);
        onSecondTreatmentSelect(treatment);
        localStorage.setItem('selectedSecondTreatment', JSON.stringify(treatment)); // Store selected treatment in localStorage
    };

    return (
        <div ref={dropdownRef} style={{ position: 'relative', zIndex: 20 }}>
            <div
                onClick={() => setShowDropdown(!showDropdown)}
                style={{ cursor: 'pointer', padding: '5px', border: '1px solid #ccc' }}
            >
                <input
                    type="text"
                    placeholder="Search Treatment"
                    value={selectedTreatment || searchTerm}
                    className="cityInput"
                    onChange={(e) => {
                        setSelectedTreatment('');
                        setSearchTerm(e.target.value);
                    }}
                    style={{ width: '100%', padding: '10px', margin: '0', boxSizing: 'border-box', backgroundColor: 'white' }}
                />
            </div>
            {showDropdown && (
                <div
                    style={{
                        width: '100%',
                        zIndex: 3,
                        maxHeight: '200px',
                        overflowY: 'auto',
                        backgroundColor: '#fff',
                        border: '1px solid #ccc',
                        borderTop: 'none',
                    }}
                >
                    {isSearching && <div>Searching...</div>}
                    <ul style={{ listStyle: 'none', padding: '0', margin: '0' }}>
                        {treatments.map(treatment => (
                            <li
                                key={treatment.id}
                                onClick={() => handleTreatmentSelect(treatment)}
                                style={{ padding: '10px', borderBottom: '1px solid #ccc', cursor: 'pointer' }}
                            >
                                {treatment.label}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default SecondTreatmentDropdown;
