import React, { useState, useEffect, useRef } from 'react';
import { STAGGING_BACKEND, LOCAL_BACKEND } from "../../../common/helper";

const DeviceSearchDropdown = ({onDeviceSelect}) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedDevice, setSelectedDevice] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [devices, setDevices] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const dropdownRef = useRef(null);
    
    const apiUrl = STAGGING_BACKEND + "cancer/questionair/get-filtered-items/";
    // const url = STAGGING_BACKEND + "cancer/questionair/get-filtered-items/";

    useEffect(() => {
        const storedDevice = JSON.parse(localStorage.getItem('selectedDevice'));
        if (storedDevice) {
          setSelectedDevice(storedDevice.value);
          setSearchTerm(storedDevice.label);
        }
      
        const handleClickOutside = (event) => {
          if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowDropdown(false);
          }
        };
      
        document.addEventListener('mousedown', handleClickOutside);
      
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);

    useEffect(() => {
        if (searchTerm && showDropdown) {
            const debounceTimeout = setTimeout(() => {
                setIsSearching(true);
                const requestOptions = {
                    method: "GET",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: "token " + localStorage.getItem("token"),
                    }}
                fetch(`${apiUrl}?filter=unique_medical_devices&query=${encodeURIComponent(searchTerm)}`, requestOptions)
                    .then(response => response.json())
                    .then(data => {
                        setDevices(data.data);
                        setIsSearching(false);
                    })
                    .catch(error => {
                        console.error('Error fetching cities:', error);
                        setIsSearching(false);
                    });
            }, 300); // Debounce time

            return () => clearTimeout(debounceTimeout);
        } else {
            setDevices([]);
        }
    }, [searchTerm, apiUrl, showDropdown]);

    const handleDeviceSelect = (device) => {
        setSelectedDevice(device.value);
        setSearchTerm('');
        setShowDropdown(false);
        onDeviceSelect(device);
        localStorage.setItem('selectedDevice', JSON.stringify(device)); 
    };
    console.log("device: ", selectedDevice)

    return (
        <div ref={dropdownRef} style={{ position: 'relative', zIndex: 2 }}>
            <div
                onClick={() => setShowDropdown(!showDropdown)}
                style={{ cursor: 'pointer', padding: '5px', border: '1px solid #ccc' }}
            >
                {<input
                    type="text"
                    placeholder="Search Medical Devices "
                    value={selectedDevice || searchTerm}
                    className='cityInput'
                    onChange={(e) => {
                        setSelectedDevice(null)
                        setSearchTerm(e.target.value)}}
                    style={{ width: '100%', padding: '10px', margin: '0', boxSizing: 'border-box', backgroundColor:'white' }}
                />}
            </div>
            {showDropdown && (
                <div style={{
                    position: 'absolute',
                    width: '100%',
                    zIndex: 3,
                    maxHeight: '200px', // Fixed height
                    overflowY: 'auto', // Enable scrolling
                    backgroundColor: '#fff', // Background color for visibility
                    border: '1px solid #ccc', // Border for dropdown
                    borderTop: 'none' // Remove border top to blend with the input field
                }}>
                    {isSearching && <div>Searching...</div>}
                    <ul style={{ listStyle: 'none', padding: '0', margin: '0' }}>
                        {devices.map(device => (
                            <li
                                key={device.id}
                                onClick={() => handleDeviceSelect(device)}
                                style={{ padding: '10px', borderBottom: '1px solid #ccc', cursor: 'pointer' }}
                            >
                                {device.label}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default DeviceSearchDropdown;

