import React, { useState, useEffect } from "react";
import Sidebar from "../../../components/sidebar/Sidebar";
import Header from "../../../components/Header/Header";
import birds from "../../../images/birds.png";
import leafs from "../../../images/leafs.png";
import logo from "../../../images/logo.png";
import Classes from "../profile.module.css";
import { Images } from "../../../Utils/Images";
import Select from "react-select";
import DatePicker from "react-multi-date-picker";
import { Calendar as DatePickerCalendar } from "react-multi-date-picker";
import DateObject from "react-date-object";
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useNavigate } from "react-router-dom";
import { Editor } from "react-draft-wysiwyg";
import { STAGGING_BACKEND, LOCAL_BACKEND } from "../../../common/helper";
import Swal from "sweetalert2";
import { convertTo12HourFormat, convertDatetimeTo12HourFormat } from '../../../common/helper';


const trackerOptions = [
  { value: "Symptom Tracker", label: "Symptom Tracker" },
  { value: "Medication Tracker", label: "Medication Tracker" },
  { value: "Nutrition Tracker", label: "Nutrition Tracker" },
  { value: "Appointment Tracker", label: "Appointment Tracker" },
];
const calendarFilterOptions = [
  "All",
  "Medication",
  "Symptom",
  "Nutrition",
  "Events",
  "Appointment"
];
const localizer = momentLocalizer(moment); // Create localizer with moment

const transformEvent = (event) => ({
  start: new Date(event.start_date),
  end: event.tracker_type == "tracker_event" ? new Date(event.end_date) : new Date(event.start_date),
  title: event.title,
  type: event.tracker_type,
  queries: event.queries,
  id: event.id
}); //for big calendar

const EventCalendar = () => {
  const [header, setHeader] = useState(false);
  const [sideBar, setSideBar] = useState(false);
  const [tracker, setTracker] = useState("");
  const [value, setValue] = useState(new DateObject());
  const [selectedDate, setSelectedDate] = useState(null);
  const [isCalendarFiltersOpen, setCalendarFiltersOpen] = useState(false);
  const [selectedCalendarFilterOption, setSelectedCalendarFilterOption] = useState("All");
  const [isTrackerModalVisible, setTrackerModalVisible] = useState(false);
  const [events, setEvents] = useState([]);
  const [selectedTrackerQueries, setSelectedTrackerQueries] = useState(null);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [startOfMonth, setStartOfMonth] = useState(null);
  const [endOfMonth, setEndOfMonth] = useState(null);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [createEventDescription, setCreateEventDescription] = useState("");
  const [eventTitle, setEventTitle] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [description, setDescription] = useState("");
  const [medicationTrackerContent, setMedicationTrackerContent] = useState(false);
  const [symptomTrackerContent, setSymptomTrackerContent] = useState(false);
  const [nutritionTrackerContent, setNutritionTrackerContent] = useState(false);
  const [appointmentTrackerContent, setAppointmentTrackerContent] = useState(false);
  const [eventTrackerContent, setEventTrackerContent] = useState(false)
  const [responseEvents, setResponseEvents] = useState([])
  const [eventDetails, setEventDetails] = useState({})
  const [eventDelete, setEventDeleted] = useState(false)

  const navigate = useNavigate();

  useEffect(async () => {
    const url = STAGGING_BACKEND + `calendar/?filter=${selectedCalendarFilterOption}`
    const requestOptions = {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'token ' + localStorage.getItem("token"),

      },
    }
    const response = await fetch(url, requestOptions);
    const responseData = await response.json();
    console.log(responseData);
    setResponseEvents(responseData?.data)
  }, [selectedCalendarFilterOption, eventDelete]);

  useEffect(() => {
    updateMonthDates(currentDate);
    const transformedEvents = responseEvents?.map(transformEvent);
    console.log("---events", responseEvents)
    setEvents(transformedEvents);

  }, [responseEvents])

  const HandleCalendarExport = async () =>{
    const url = STAGGING_BACKEND + `calendar/export-calendar`
    const requestOptions = {
        method : "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'token ' + localStorage.getItem("token"),
            
          },
    }
    const response = await fetch(url, requestOptions);
    const responseData = await response.blob()

    const blob = new Blob([responseData]);
    const blob_url = window.URL.createObjectURL(blob);


    const link = document.createElement('a');
    link.href = blob_url;
    link.setAttribute('download', 'My-calendar.xlsx'); // Set the desired file name

    // Append the link to the document body and click it to trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up by removing the link and revoking the URL object
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
    
  }

  const updateMonthDates = (date) => {
    const start = new Date(date.getFullYear(), date.getMonth(), 1);
    const end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    setStartOfMonth(start);
    setEndOfMonth(end);
  }

  function handleNavigate(date) {
    setCurrentDate(date);
    updateMonthDates(date);
  }

  const toggleCalendarFiltersDropdown = () =>
    setCalendarFiltersOpen(!isCalendarFiltersOpen);

  const handleCalendarFilterOptionClick = (event) =>
    setSelectedCalendarFilterOption(event.target.value);


  function handleHeader() {
    setHeader((t) => !t);
  }
  const handleDialogClose = () => setDialogOpen(false);

  function handleSideBar() {
    setSideBar((t) => !t);
  }
  const redirectToProfile = () => {
    navigate('/profile');
  }
  const handleDateChange = (date) => {
    if (date instanceof DateObject)
      setValue(date);
  };

  const handleStartDatetime = (event) => {
    const userDate = new Date(event.target.value);

    const year = userDate.getFullYear();
    const month = String(userDate.getMonth() + 1).padStart(2, "0");
    const day = String(userDate.getDate()).padStart(2, "0");
    const hours = String(userDate.getHours()).padStart(2, "0");
    const minutes = String(userDate.getMinutes()).padStart(2, "0");
    const timezoneOffset = -userDate.getTimezoneOffset() / 60; // Calculate the timezone offset in hours
    const offsetSign = timezoneOffset >= 0 ? '+' : '-';
  
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:00${offsetSign}${String(Math.abs(timezoneOffset)).padStart(2, "0")}`;
    setStartDate(formattedDate);
  };

  const handleEndDatetime = (event) => {
    const userDate = new Date(event.target.value);

  const year = userDate.getFullYear();
  const month = String(userDate.getMonth() + 1).padStart(2, "0");
  const day = String(userDate.getDate()).padStart(2, "0");
  const hours = String(userDate.getHours()).padStart(2, "0");
  const minutes = String(userDate.getMinutes()).padStart(2, "0");
  const timezoneOffset = -userDate.getTimezoneOffset() / 60; // Calculate the timezone offset in hours
  const offsetSign = timezoneOffset >= 0 ? '+' : '-';

  const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:00${offsetSign}${String(Math.abs(timezoneOffset)).padStart(2, "0")}`;
    setEndDate(formattedDate);
  };

  const onContentStateChange = (editorConetent) =>
    setDescription(editorConetent.blocks[0].text);


  useEffect(() => {
    switch (tracker.value) {
      case "Symptom Tracker":
        navigate(`/symptom-tracker`);
        break;
      case "Medication Tracker":
        navigate(`/meditation-tracker`);
        break;
      case "Nutrition Tracker":
        navigate(`/nutritiion-tracker`);
        break;
      case "Appointment Tracker":
        navigate(`/appointment-tracker`);
        break;
      default:
        break;
    }
  }, [tracker]);

  const optionColorMap = {
    Medication: "rgb(14, 98, 128)",
    Symptom: "rgb(205, 85, 5)",
    Nutrition: "rgb(0, 128, 0)",
    Notes: "rgb(199, 21, 21)",
    Chemotharapy: "rgb(44, 96, 14)",
    Radiation: "rgb(240, 147, 43)",
    Surgery: "rgb(247, 209, 191)",
    "Other treatment": "rgb(196, 20, 207)",
    "Clinic Visit Tracker": "rgb(173, 203, 250)",
    "Lab visit tracker": "rgb(149 175 192)",
    "Imaging Tracker (PET, CT, MRI, US, etc)": "rgb(235, 77, 75)",
    "Other Diagnostic Test Tracker": "rgb(240, 147, 43)",
  };

  const onEditorStateChange = (editorState) => {
    setCreateEventDescription(editorState);
  };

  const addEventTracker = async (event) => {
    event.preventDefault();
    const url = STAGGING_BACKEND + "calendar/event-tracker/";
    const payload = {
      title: eventTitle,
      start_date: startDate,
      end_date: endDate,
      description: description,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "token " + localStorage.getItem("token"),
      },
      body: JSON.stringify(payload),
    };

    const response = await fetch(url, requestOptions);
    const responseData = await response.json();
    if (responseData.error === false) {
      Swal.fire({
        title: "Sucessfull",
        text: responseData.message,
        icon: "success",
        confirmButtonText: "Close",
      });
    } else {
      Swal.fire({
        title: "Error!",
        text: responseData.message,
        icon: "error",
        confirmButtonText: "Try again",
      });
    }
  };

  // ******Calendar Dynamic Styling***********

  const eventStyleGetter = (event, start, end, isSelected) => {
    let backgroundColor = "#f5f5f5"; // default color
    console.log("event", event);
    if (event.type === "tracker_event") {
      backgroundColor = "#00a9dd"; // color for 'event' type
    } else if (event.type === "tracker_medication") {
      backgroundColor = "#0e6280"; // color for 'medicationTracker' type
    } else if (event.type === "tracker_symptom") {
      backgroundColor = "#cd5505"; // color for 'medicationTracker' type
    } else if (event.type === "tracker_nutrition") {
      backgroundColor = "green"; // color for 'medicationTracker' type
    } else if (event.type === "tracker_appointment") {
      backgroundColor = "brown"; // color for 'medicationTracker' type
    }

    const style = {
      backgroundColor: backgroundColor,
      borderRadius: "5px",
      opacity: 0.8,
      color: "white",
      border: "0px",
      display: "block",
    };

    return {
      style: style,
    };
  };

  const handleEventSelect = (event) => {
    if (event.type.startsWith("tracker_")) {
      setTrackerModalVisible(true);
      if (event.type == "tracker_medication") {
        setMedicationTrackerContent(true);
        setSymptomTrackerContent(false);
        setNutritionTrackerContent(false);
        setAppointmentTrackerContent(false);
        setEventTrackerContent(false)
        setEventDetails({
          id: event.id,
          type: event.type
        })
      }
      if (event.type == "tracker_symptom") {
        setSymptomTrackerContent(true);
        setMedicationTrackerContent(false);
        setNutritionTrackerContent(false);
        setAppointmentTrackerContent(false);
        setEventTrackerContent(false);
        setEventDetails({
          id: event.id,
          type: event.type
        })
      }
      if (event.type == "tracker_nutrition") {
        setNutritionTrackerContent(true);
        setSymptomTrackerContent(false);
        setMedicationTrackerContent(false);
        setAppointmentTrackerContent(false);
        setEventTrackerContent(false);
        setEventDetails({
          id: event.id,
          type: event.type
        })
      }
      if (event.type == "tracker_appointment") {
        setAppointmentTrackerContent(true);
        setNutritionTrackerContent(false);
        setSymptomTrackerContent(false);
        setMedicationTrackerContent(false);
        setEventTrackerContent(false);
        setEventDetails({
          id: event.id,
          type: event.type
        })
      }
      if (event.type == "tracker_event") {
        setEventTrackerContent(true)
        setAppointmentTrackerContent(false);
        setNutritionTrackerContent(false);
        setSymptomTrackerContent(false);
        setMedicationTrackerContent(false);
        setEventDetails({
          id: event.id,
          type: event.type
        })
      }

      setSelectedTrackerQueries(event.queries);
    }
    // else if ((event.type = "event")) {
    //   setDialogOpen(true);
    // }
  };


  const deleteEvent = async () => {
    const url = STAGGING_BACKEND + `calendar/?id=${eventDetails.id}&type=${eventDetails.type}`
    const requestOptions = {
      method: "DELETE",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'token ' + localStorage.getItem("token"),

      },
    }
    const response = await fetch(url, requestOptions);
    const responseData = await response.json();
    setEventDeleted(!eventDelete);
  }

  console.log("event details-----", eventDetails)
  return (
    <>
      <div className="mobile-header-section">
        <div
          id="nav-icon4"
          className={!sideBar ? "hamburger" : "hamburger open"}
          onClick={handleSideBar}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
        <img src={logo} style={{ height: "60px" }} alt="" />
        <div
          id="nav-icon3"
          onClick={handleHeader}
          className={!header ? "hamburger" : "hamburger open"}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>

      <div className="cc-page-wrapper event-calendar-page">
        <Sidebar
          sideBar={sideBar}
          marginTopH6="mt3point5rem"
          margintop2rem="margintop2rem"
        />
        <div className="main-area-inner-wrapper">
          <Header header={header} />

          <div className="new-page-content-wrapper mt-4">
            <div
              className={`${Classes.calendarHeaderWrapper} addTrackerDropdown`}
            >
              <button className="blue-button" onClick={redirectToProfile}>Back</button>
              <h1 className="site-heading">MY Personal Calendar</h1>
              <div className={Classes.addFlex} style={{ gap: "20px" }}>
                <Select
                  options={trackerOptions}
                  value={tracker}
                  placeholder="Add Trackers"
                  className={Classes.calendarTrackerSelect}
                  id="gender"
                  onChange={(event) => {
                    setTracker(event);
                  }}
                />
                <button className="blue-button" onClick={HandleCalendarExport}>Download Trackers</button>
              </div>
            </div>

            <div className={Classes.eventCalendarSection}>
              <div className="row">
                <div className="col-lg-3">
                  <div className={Classes.datePickerContainer}>
                    <DatePickerCalendar
                      value={value}
                      onChange={handleDateChange}
                      format="MMMM DD YYYY"
                      hideFooter
                    />

                    <div className="calendar-filters-dropdown">
                      <button
                        className={`calendar-filters-dropdown-button ${isCalendarFiltersOpen ? "open" : ""
                          }`}
                        onClick={toggleCalendarFiltersDropdown}
                      >
                        Calendar Filters{" "}
                        <span className="calendar-filters-dropdown-arrow">
                          ▼
                        </span>
                      </button>
                      {isCalendarFiltersOpen && (
                        <div className="calendar-filters-dropdown-content">
                          {calendarFilterOptions.map((option) => (
                            <>
                              <label key={option}>
                                <input
                                  type="radio"
                                  value={option}
                                  checked={
                                    selectedCalendarFilterOption === option
                                  }
                                  onChange={handleCalendarFilterOptionClick}
                                />
                                <span>{option}</span>
                                <div
                                  className="color-symbol-eventCalendar"
                                  style={{
                                    backgroundColor:
                                      optionColorMap[option] || "defaultColor",
                                  }}
                                ></div>
                              </label>
                            </>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-9">
                  <div className="bigCalendar-wrapper">
                    <div style={{ height: "500px", marginTop: "1em" }}>
                      <BigCalendar
                        localizer={localizer}
                        events={events}
                        startAccessor="start"
                        endAccessor="end"
                        selectable={true}
                        onSelectSlot={() => setDialogOpen(true)}
                        onSelectEvent={handleEventSelect}
                        eventPropGetter={eventStyleGetter}
                        onNavigate={handleNavigate}
                        defaultDate={currentDate}

                      />
                    </div>
                    {/* tracker modal */}

                    {isTrackerModalVisible && (
                      <div className={Classes.trackerModalSection}>
                        <div className={Classes.trackerInnerWrapper}>
                          <button
                            onClick={() => setTrackerModalVisible(false)}
                            style={{ float: "right" }}
                            className={Classes.trackerModalCrossBtn}
                          >
                            {" "}
                            X
                          </button>

                          {medicationTrackerContent && (
                            <>
                              <h3 className={Classes.trackerModalHeading}>
                                Medication Tracker Details
                              </h3>
                              <div
                                className={Classes.trackerQuestionModalWrapper}
                              >
                                <b>Date?</b>
                                <span>{selectedTrackerQueries?.q4}</span>
                                <b>Time?</b>
                                <span>{convertTo12HourFormat(selectedTrackerQueries?.q2)}</span>

                                <b>What medication did you take?</b>
                                <span>{selectedTrackerQueries?.q1}</span>
                                
                                <b>What was the dose?</b>
                                <span>{selectedTrackerQueries?.q3}</span>
                                
                              </div>
                            </>
                          )}

                          {symptomTrackerContent && (
                            <>
                              <h3 className={Classes.trackerModalHeading}>
                                Symptom Tracker Details
                              </h3>
                              <div
                                className={Classes.trackerQuestionModalWrapper}
                              >
                                <b>Date?</b>
                                <span>{selectedTrackerQueries?.q3}</span>
                                <b>Time?</b>
                                <span>{convertTo12HourFormat(selectedTrackerQueries?.q2)}</span>

                                <b>What symptom(s) did you have?</b>
                                <span>{selectedTrackerQueries?.q1}</span>
                                <b>What made the symptom(s) better or worse?</b>
                                <span>{selectedTrackerQueries?.q6}</span>

                                <b>What did you do to resolve the symptom?</b>
                                <span>{selectedTrackerQueries?.q4}</span>
                                <b>How long did the symptom last?</b>
                                <span>{selectedTrackerQueries?.q5}</span>
                                
                              </div>
                            </>
                          )}

                          {nutritionTrackerContent && (
                            <>
                              <h3 className={Classes.trackerModalHeading}>
                                Nutrition Tracker Details
                              </h3>
                              <div
                                className={Classes.trackerQuestionModalWrapper}
                              >
                                <b>Date?:</b>
                                <span>{selectedTrackerQueries?.q3}</span>
                                <b>Time?</b>
                                <span>{convertTo12HourFormat(selectedTrackerQueries?.q4)}</span>

                                <b>beverage you drank or food you ate?</b>
                                <span>{selectedTrackerQueries?.q1}</span>

                                <b>Approximate servings?</b>
                                <span>{selectedTrackerQueries?.q2}</span>
                                
                              </div>
                            </>
                          )}
                          {appointmentTrackerContent && (
                            <>
                              <h3 className={Classes.trackerModalHeading}>
                                Appointment Tracker Details
                              </h3>
                              <div
                                className={Classes.trackerQuestionModalWrapper}
                              >
                                <b>Date of appointment?</b>
                                <span>{selectedTrackerQueries?.q1}</span>
                                <b>Time of your appointment:</b>
                                <span>{convertTo12HourFormat(selectedTrackerQueries?.q3)}</span>
                                <b>Nature of appointment?</b>
                                <span>{selectedTrackerQueries?.q2}</span>
                                <b>Please describe the treatment, radiation, etc</b>
                                <span>{selectedTrackerQueries?.q4}</span>
                              </div>
                            </>
                          )}

                          {eventTrackerContent && (
                            <>
                              <h3 className={Classes.trackerModalHeading}>
                                Event Tracker Details
                              </h3>
                              <div
                                className={Classes.trackerQuestionModalWrapper}
                              >
                                <b>Title</b>
                                <span>{selectedTrackerQueries?.q1}</span>
                                <b>Start Date</b>
                                <span>{convertDatetimeTo12HourFormat(selectedTrackerQueries?.q2)}</span>
                                <b>End Date</b>
                                <span>{convertDatetimeTo12HourFormat(selectedTrackerQueries?.q3)}</span>
                                <b>Description</b>
                                <span>{selectedTrackerQueries?.q4}</span>
                              </div>
                            </>
                          )}

                          <button className="btn btn-danger" onClick={() => {
                            deleteEvent()
                            setTrackerModalVisible(false)
                          }}>Delete</button>
                        </div>
                      </div>
                    )}

                    {/* *** */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          src={birds}
          style={{ height: "100px", width: "auto" }}
          className="signup-birds hideInMobile"
          alt=""
        />
      </div>

      {/* *******Dialog*********** */}
      {isDialogOpen && (
        <div className={Classes.dialogOverlay}>
          <div className={Classes.dialogContent}>
            <button className={Classes.closeDialog} onClick={handleDialogClose}>
              X
            </button>
            <h3 className={Classes.eventCreateHeading}>Create Event</h3>
            <form onSubmit={addEventTracker}>
              <div className="row mt-4">
                <div className="col-lg-12">
                  <label htmlFor="">Title</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Title..."
                    onChange={(event) => setEventTitle(event.target.value)}
                  />
                </div>
                <div className="col-lg-6 mt-3">
                  <label htmlFor="">Start Date</label>
                  <input
                    type="datetime-local"
                    className="form-control"
                    onChange={(event) => handleStartDatetime(event)}
                  />
                </div>
                <div className="col-lg-6 mt-3">
                  <label htmlFor="">End Date</label>
                  <input
                    type="datetime-local"
                    className="form-control"
                    onChange={(event) => handleEndDatetime(event)}
                  />
                </div>
                <div className="col-lg-12 mt-3">
                  <label htmlFor="">Description</label>
                  <Editor
                    editorState={createEventDescription}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={onEditorStateChange}
                    onContentStateChange={onContentStateChange}
                  />
                  <button className="blue-button mt-3">Submit</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default EventCalendar;
