import Checklist1 from  '../images/checklist1.png'
// import Chemotherapy from  '../images/chemotherapy.png'
import TestResults from  '../images/testResults.png'
import FirstVisit from  '../images/firstVisit.png'
import FollowUp from  '../images/followUp.png'
import FollowUp2 from  '../images/followUp2.png'
import ChemotharapyDays from  '../images/chemotharapyDays.png'
import MedicationAskAbout from  '../images/medicationAskAbout.png'
import MyDiary from  '../images/diary.png'
import MySurvivorship from  '../images/survivorship.png'
import PersonalTrackers from  '../images/personalTrackers.png'
import TreatmentTrackers from  '../images/treatmentTrackers.png'
import AppointmentTrackers from  '../images/appointmentTrackers.png'
import WalnessCenter from  '../images/walnessCenter.png'
import MentalHealth from  '../images/mentalHealth.png'
import FollowUpSchedule from  '../images/followUpSchedule.png'
export const Images = {
    Checklist1,
    // Chemotherapy,
    TestResults,
    FirstVisit,
    FollowUp,
    FollowUp2,
    ChemotharapyDays,
    MedicationAskAbout,
    MyDiary,
    MySurvivorship,
    PersonalTrackers,
    TreatmentTrackers,
    AppointmentTrackers,
    WalnessCenter,
    MentalHealth,
    FollowUpSchedule
}