import React, { useState, useEffect } from "react";
import logo from "../../images/logo.png";
import Sidebar from "../../components/sidebar/Sidebar";
import Header from "../../components/Header/Header";
import birds from "../../images/birds.png";
import leafs from "../../images/leafs.png";
import Table from 'react-bootstrap/Table';
import heart from '../../images/heart.svg'
import download from '../../images/download.svg'
import { LOCAL_BACKEND, STAGGING_BACKEND } from "../../common/helper";
import TextTruncate from 'react-text-truncate';
import save from "../../images/save.png"
import Loader from '../../components/Loader/Loader'
import { Link  , useNavigate} from "react-router-dom";

const SavedTrials = () => {
  const [header, setHeader] = useState(false);
  const [sideBar, setSideBar] = useState(false);
  const [loading, setloading] = useState(true);
  const [data, setData] = useState([])
  const [render, setRender] = useState(false);
  const navigate = useNavigate();

  function handleHeader() {
    setHeader((t) => !t);
  }
  function handleSideBar() {
    setSideBar((t) => !t);
  }

  useEffect(async () => {
    const url = STAGGING_BACKEND + "cancer/get-save-trials/"
    const requestOptions = {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'token ' + localStorage.getItem("token"),

      },
    }
    const response = await fetch(url, requestOptions);
    const responseData = await response.json();
   
    setData(responseData.data)
    setloading(false);
  }, [render])

  const navigateRoute = (nct_id) => {
    navigate("/results" , {state:{nct_id:nct_id}});
  }

  const HandleSingleDownload = async (nct_id) =>{
    const url = STAGGING_BACKEND + "cancer/trialdetails/"
    const payload = {
        nct_id : nct_id,
        export : "xls"
    }
    const requestOptions = {
        method : "POST",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'token ' + localStorage.getItem("token"),
            
          },
          body : JSON.stringify(payload)
    }
    const response = await fetch(url, requestOptions);
    const responseData = await response.blob()

    const blob = new Blob([responseData]);
    const blob_url = window.URL.createObjectURL(blob);


    const link = document.createElement('a');
    link.href = blob_url;
    link.setAttribute('download', 'single_trial.xlsx'); // Set the desired file name

    // Append the link to the document body and click it to trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up by removing the link and revoking the URL object
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
    
  }

  const HandleUnSave = async (nct_id) =>{
    const url = STAGGING_BACKEND + "cancer/unsave/"
    const payload = {
        nct_id : nct_id,
    }
    const requestOptions = {
        method : "POST",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'token ' + localStorage.getItem("token"),
            
          },
          body : JSON.stringify(payload)
    }
    const response = await fetch(url, requestOptions);
    const responseData = await response.json()
    console.log(responseData.data)
    setRender(!render);
  }

  const HandleDownload = async () =>{
    const url = STAGGING_BACKEND + "cancer/get-save-trials/?export=xls";

    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'token ' + localStorage.getItem("token")
      },
      responseType: 'blob',
      // body: JSON.stringify(payload)
    };

    const response = await fetch(url, requestOptions);
    const responseData = await response.blob()

    const blob = new Blob([responseData]);
    const blob_url = window.URL.createObjectURL(blob);


    const link = document.createElement('a');
    link.href = blob_url;
    link.setAttribute('download', 'my_saved_trials.xls'); // Set the desired file name

    // Append the link to the document body and click it to trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up by removing the link and revoking the URL object
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
    
  }

  return (
    <>
    {loading ? ( <Loader /> ) : (
      <>
      <div className="mobile-header-section">
        <div
          id="nav-icon4"
          className={!sideBar ? "hamburger" : "hamburger open"}
          onClick={handleSideBar}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
        <img src={logo} style={{ height: "60px" }} alt="" />
        <div
          id="nav-icon3"
          onClick={handleHeader}
          className={!header ? "hamburger" : "hamburger open"}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>

      <div className="cc-page-wrapper">
        <Sidebar
          sideBar={sideBar}
          marginTopH6="mt3point5rem"
          margintop2rem="margintop2rem"
        />
        <div className="main-area-inner-wrapper">
          <Header header={header} />
          <div className="new-page-content-wrapper mt-4">
            <h1 className="site-heading mb-4">My saved Trials</h1>
            <div className="d-flex justify-content-end">
              <button class="blue-button mb-3" onClick={()=>{
                HandleDownload()
              }}>
                Download All
              </button>
            </div>
            <div style={{ overflowX: "auto", overflowY: "auto", maxHeight: "370px" }} className="drugstatusTable-wrapper">
            <Table hover className="drugsTable">
          <thead>
            <tr className="w-100">
              {/* <th>RANK</th>
              <th>SCORE</th> */}
              <th>NCT ID</th>
              <th>DISTANCE</th>
              <th>PHASE</th>
              <th>STATUS</th>
              <th>BRIEF TITLE</th>
              <th>TRIAL DETAILS</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, key) => {
              return <tr>
                {/* <td></td>
                <td className="drugName"><p>{item.score}</p></td> */}
                <td><p>{item.nct_id}</p></td>
                <td>
                  {item.distance}
                </td>
                <td>{item.phase}</td>
                <td><p>{item.status}</p></td>
                <td><TextTruncate
                  line={0.3}
                  element="span"
                  truncateText="…"
                  text={item.brief_title}
                /></td>
                <td>
                 <div style={{minWidth:'230px'}}>
                 {/* <Link to='/results'> */}
                  <button className="blue-button" onClick={()=>{
                    navigateRoute(item.nct_id);
                  }}>See Details</button>
                  {/* </Link> */}
                  <img src={download} className="drugstableIcon" alt="" onClick={()=>{
                    HandleSingleDownload(item.nct_id)
                  }}/>

                  {item.saved ? (
                    <img src={save} className="drugstableIcon" alt=""  onClick={()=>{
                      HandleUnSave(item.nct_id)
                    }}/>
                  ) : null}
                  
                 </div>
                </td>
              </tr>
            })}
          </tbody>
        </Table>
            </div>
          </div>
        </div>
        <img
          src={birds}
          style={{ height: "100px", width: "auto" }}
          className="signup-birds hideInMobile"
          alt=""
        />

      </div>
      </>
    )}
      
    </>
  );
}

export default SavedTrials;
