import React, { useState } from "react";
import logo from "../../images/logo.png";
import birds from "../../images/birds.png";
import heart from '../../images/heart.svg';
import save from "../../images/save.png";
import leafs from "../../images/leafs.png";
import "font-awesome/css/font-awesome.min.css";
import Sidebar from "../../components/sidebar/Sidebar";
import Header from "../../components/Header/Header";
import { Link, useNavigate } from "react-router-dom";


const TrialMenuPatient = () => {
  const [header, setHeader] = useState(false);
  const [sideBar, setSideBar] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [bySavedTrials, setBySavedTrials] = useState(false);
  const [byNctId, setByNctId] = useState("");
  const [showTooltip, setShowTooltip] = useState(false);

  const navigate = useNavigate();

  function handleHeader() {
    setHeader((t) => !t);
  }
  function handleSideBar() {
    setSideBar((t) => !t);
  }
  function handleDropdownButton() {
    setShowDropdown((t) => !t);
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    let nct_ids = null;
    byNctId !== "" ?  nct_ids = byNctId.split(",").map(element=>element.trim()) : nct_ids = null;

    (bySavedTrials ? window.sessionStorage.setItem("checkByCustomNctIds", JSON.stringify({"saved": true, "nct_ids":nct_ids}))
     : window.sessionStorage.setItem("checkByCustomNctIds", JSON.stringify({"saved": false, "nct_ids":nct_ids})));
    
    navigate('/clinical-questions?mode=full_options&checkByCustomNctIds=true');
  }

  return (
    <>
      <div className="mobile-header-section">
        <div
          id="nav-icon4"
          className={!sideBar ? "hamburger" : "hamburger open"}
          onClick={handleSideBar}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
        <img src={logo} style={{ height: "60px" }} alt="" />
        <div
          id="nav-icon3"
          onClick={handleHeader}
          className={!header ? "hamburger" : "hamburger open"}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>

      <div className="contact-section-wrapper">
        <Sidebar
          sideBar={sideBar}
          marginTopH6="mt3point5rem"
          margintop2rem="margintop2rem"
        />
        <div className="contact-inner-parent">
          <Header header={header} />

          <div className="trial-criteria-main-area mt-4">
            <div className="trial-criteria-buttons-wrapper">
              <h1 className="text-center site-heading">
                Clinical Trial Finder
              </h1>
              <p className="mb-5 BluetextUnderHeading">
                Select your trial criteria you wish to filter by
              </p>

              <Link to="/clinical-questions?mode=full_options">
                <button className="light-theme-btn  mb-3">
                  Find Trials You're Eligible for{" "}
                  <i className="fa fa-angle-right" aria-hidden="true"></i>
                </button>
              </Link>

              <button
                className={showDropdown ? 'light-theme-btn  mb-3 dropdownopen' : 'light-theme-btn  mb-3'}
                onClick={handleDropdownButton}
              >
                Determine Patient's Eligibilty <br />
                for trials I know
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </button>
              {showDropdown && (
                <div className="dropdown-eligibilty-container">
                  <Link to=''>
                    <button className={bySavedTrials ? ("selected-dark-theme-btn healthcare-savedtrials-btn  mb-2 py-0 w-100") :
                     ("light-theme-btn healthcare-savedtrials-btn  mb-2 py-0 w-100")} onClick={() => {
                      setBySavedTrials(!bySavedTrials);
                    }}>
                      {bySavedTrials ? (
                    <img src={save} className="heart-in-saved-trials-btn" alt="" />

                  ) : <img src={heart} className="heart-in-saved-trials-btn" alt="" />}
                      My Saved Trials
                    </button>
                  </Link>
                  <form onSubmit={handleSubmit}>
                    <div className="search-box-area">
                      <i className="fa fa-search"></i>
                      <input 
                        type="search" 
                        placeholder="Search by NCT Number" 
                        onChange={(event) => {
                          setByNctId(event.target.value);
                        }}
                        onFocus={() => setShowTooltip(true)}
                        onBlur={() => setShowTooltip(false)}
                      />
                      {showTooltip && (
                        <div className="tooltipinput">
                          Please enter comma-separated values.(e.g: No1, No2)
                        </div>
                      )}
                    </div>
                    <button class="blue-button mx-auto d-block mt-3 healthcare-trial-srch-btn" type="submit">
                      Search
                    </button>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
        <img
          src={birds}
          style={{ height: "100px", width: "auto" }}
          className="signup-birds hideInMobile"
          alt=""
        />
        <img
          src={leafs}
          style={{ height: "150px", width: "auto" }}
          className="signup-ground hideInMobile"
          alt=""
        />
      </div>
    </>
  );
};

export default TrialMenuPatient;
