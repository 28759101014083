import React,{useState, useEffect} from 'react';
import Sidebar from "../../components/sidebar/Sidebar";
import Header from "../../components/Header/Header";
import birds from "../../images/birds.png";
import leafs from "../../images/leafs.png";
import logo from "../../images/logo.png";
import profileImage from "../../images/profilePic.png";

const UploadPicture = () => {
    const [header, setHeader] = useState(false);
    const [sideBar, setSideBar] = useState(false);
    function handleHeader() {
      setHeader((t) => !t);
    }
    function handleSideBar() {
      setSideBar((t) => !t);
    }

    const [imagePreview, setImagePreview] = useState(null);
    const [removePicture, setRemovePicture] = useState(false);

    const handleImageUpload = (event) => {
      const file = event.target.files[0];
  
      if (file) {
        const reader = new FileReader();
  
        reader.onload = (e) => {
          setImagePreview(e.target.result);
        };
  
        reader.readAsDataURL(file);
      } else {
        setImagePreview(null);
      }
    };

    function removeImage(event)
    {
      setImagePreview(null);
    }
  
    return (
        <>
  <div className="mobile-header-section">
        <div
          id="nav-icon4"
          className={!sideBar ? "hamburger" : "hamburger open"}
          onClick={handleSideBar}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
        <img src={logo} style={{ height: "60px" }} alt="" />
        <div
          id="nav-icon3"
          onClick={handleHeader}
          className={!header ? "hamburger" : "hamburger open"}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>

      <div className="cc-page-wrapper">
        <Sidebar
          sideBar={sideBar}
          marginTopH6="mt3point5rem"
          margintop2rem="margintop2rem"
        />
        <div className="main-area-inner-wrapper">
          <Header header={header} />

          <div className="new-page-content-wrapper mt-4">
            <h1 className="site-heading">Profile Picture</h1>
           
           <div className="profile-picture-content-wrapper mt-4">
            <h2>Upload Picture</h2>
            <div className="row">
              <div className="col-lg-7">
              <div className="upload-picture-form-wrapper">
        <div className="image-wrapper shadow">
        <div className="preview_img_wrapper">
        {
          imagePreview && <img id="output" className='preview_img' src={imagePreview} width="200" />	
        }
        </div>
        
        
        </div>
        <div className="cta-wrapper" style={{marginLeft: '50px'}}>
        <button className='blue-button  d-block mb-4' >
        <i class="fa fa-upload" aria-hidden="true"></i> &nbsp; 
        Upload Picture
         <input type='file' accept="image/*" onChange={handleImageUpload} id="img_upload_btn" name="image"  /> </button>

        
        <button className='blue-button  d-block danger' onClick={removeImage}>
        <i class="fa fa-close" aria-hidden="true"></i> &nbsp; Remove Picture</button>
        </div>
        {/* cta-wrapper */}
        {/* image-wrapper */}
        </div>
        <div className='upload-img-cta-area'>
          <button className='blue-button danger'>
            cancel
          </button>
          <button className='blue-button'>
            Save
          </button>
        </div>
              </div>
              <div className="col-lg-5">
                <img src={profileImage} className="upload-profile-illustration" alt="" />
              </div>
            </div>
     
           </div>
           {/* profile-picture-content-wrapper */}


          </div>
        </div>
        <img
          src={birds}
          style={{ height: "100px", width: "auto" }}
          className="signup-birds hideInMobile"
          alt=""
        />
        <img
          src={leafs}
          style={{ height: "150px", width: "auto" }}
          className="signup-ground hideInMobile"
          alt=""
        />
      </div>
        </>
    );
}

export default UploadPicture;
