import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import DashboardLayout from "../../components/dashboardLayout/dashboardLayout";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import logo from "../../images/logo.png";
import Sidebar from "../../components/sidebar/Sidebar";
import Header from "../../components/Header/Header";
function UpdatePassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [sideBar, setSideBar] = useState(false);
  const [header, setHeader] = useState(false);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const emailQueryParam = urlParams.get('email');
    if (emailQueryParam) {
      setEmail(emailQueryParam);
    }
  }, []);

  const validatePassword = (password) => {
    const regex = /^(?=.*[a-z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{8,}$/;
    return regex.test(password);
  };

  const handlePasswordChange = (value) => {
    setNewPassword(value);
    if (value && !validatePassword(value)) {
      setPasswordError("The password must be at least 8 characters long and include a number and a special character.");
    } else if (confirmPassword && value !== confirmPassword) {
      setPasswordError("Passwords do not match");
    } else {
      setPasswordError("");
    }
  };

  const handleConfirmPasswordChange = (value) => {
    setConfirmPassword(value);
    if (newPassword && newPassword !== value) {
      setPasswordError("Passwords do not match");
    } else {
      setPasswordError("");
    }
  };

  const handleSubmit = () => {
    if (!validatePassword(newPassword)) {
      setPasswordError("The password must be at least 8 characters long and include a number and a special character.");
      return;
    }
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email: email, new_password: newPassword }),
    };

    fetch("https://testapi.cancerclarity.com/user/reset-password", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        Swal.fire("Success", "Password has been updated.", "success");
        navigate("/login");
      })
      .catch((error) => {
        Swal.fire("Error", "Failed to update password.", "error");
      })
      .finally(() => setLoading(false));
  };

  const canSubmit = newPassword && confirmPassword && newPassword === confirmPassword && passwordError === "";

  function handleHeader() {
    setHeader((t) => !t);
  }

  function handleSideBar() {
    setSideBar((t) => !t);
  }
  return (
    <>
    <div>

  
      {loading && <Loader />}
      <div className="mobile-header-section">
          <div
            id="nav-icon4"
            className={!sideBar ? "hamburger" : "hamburger open"}
            onClick={handleSideBar}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
          <img src={logo} style={{ height: "60px" }} alt="" />
          <div
            id="nav-icon3"
            onClick={handleHeader}
            className={!header ? "hamburger" : "hamburger open"}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div className="signup-section-wrapper">
          <Sidebar sideBar={sideBar} />

          <div className="signup-inner-parent">
            <Header header={header} />
    
        <div className="forgotPassWrapper">
          <h1 className="marginZero text-center ">Create New Password</h1>
          
          <div className="input-box-wrapper mt-4 mb-3">
            <input
              type="password"
              name="newPassword"
              className={`signup-box-input loginfields ${passwordError ? 'error' : newPassword && confirmPassword && 'success'}`}
              placeholder="New Password"
              value={newPassword}
              onChange={(event) => handlePasswordChange(event.target.value)}
            />
            {passwordError && <p style={{ color: 'red', fontSize: '14px', marginTop: '2px' }}>{passwordError}</p>}
          </div>
          <div className="input-box-wrapper mb-3">
            <input
              type="password"
              name="confirmPassword"
              className={`signup-box-input loginfields ${passwordError ? 'error' : newPassword && confirmPassword && 'success'}`}
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(event) => handleConfirmPasswordChange(event.target.value)}
            />
          </div>
          <div className="input-box-wrapper mb-3">
            <input
              type="submit"
              className={`gray-button submit-btn ${!canSubmit ? 'button-disabled' : ''}`}
              value="Update"
              onClick={handleSubmit}
              disabled={!canSubmit}
            />
          </div>
        </div>
        </div>
        </div>
        </div>
    </>
  );
}

export default UpdatePassword;
