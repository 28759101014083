import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import DashboardLayout from "../../components/dashboardLayout/dashboardLayout";
import logo from "../../images/logo.png";
import Sidebar from "../../components/sidebar/Sidebar";
import Header from "../../components/Header/Header";
function ClinicalOptions() {
  const navigate = useNavigate();
  const [selectedOptionIndexes, setSelectedOptionIndexes] = useState([0]); // Set index 0 as default
  const [sideBar, setSideBar] = useState(false);
  const [header, setHeader] = useState(false);
  const optionCards = [
    { id: 0, name: "Cancer Stage & Options" },
    { id: 1, name: "Patient Characteristics" },
    { id: 2, name: "Location" },
    { id: 3, name: "Trial Type & Sponsor" },
    { id: 4, name: "Clinical Characteristics" },
    { id: 5, name: "Cancer Characteristics" },
    { id: 6, name: "Molecular Profile" },
    { id: 7, name: "Cancer Treatment History" },
    { id: 8, name: "Drug Name & Mechanism of Action" },
  ];

  const handleOptionClick = (optionIndex) => {
    setSelectedOptionIndexes((prevIndexes) => {
      const isIndexSelected = prevIndexes.includes(optionIndex);
      if (isIndexSelected) {
        
        const newIndexes = prevIndexes.filter((index) => index !== optionIndex);
        if (optionIndex === 5) { 
          return newIndexes.filter(index => index !== 7); 
        } else if (optionIndex === 7) { 
          return newIndexes.filter(index => index !== 5); 
        }
        return newIndexes;
      } else {
        
        const newIndexes = [...prevIndexes, optionIndex];
        if (optionIndex === 5) { 
          if (!newIndexes.includes(7)) {
            newIndexes.push(7); 
          }
        } else if (optionIndex === 7) { 
          if (!newIndexes.includes(5)) {
            newIndexes.push(5); 
          }
        }
        return newIndexes;
      }
    });
  };

  function handleNextButton() {
    const selectedOptions = selectedOptionIndexes.map(index => optionCards[index].id);
    selectedOptions.sort((a, b) => a - b);
    console.log("Selected Options in Ascending Order:", selectedOptions);
    navigate(`/clinical-questions?optionsToShow=${selectedOptions}`);
  }

  function handleHeader() {
    setHeader((t) => !t);
  }

  function handleSideBar() {
    setSideBar((t) => !t);
  }

  return (
    <>
     <div className="mobile-header-section">
          <div
            id="nav-icon4"
            className={!sideBar ? "hamburger" : "hamburger open"}
            onClick={handleSideBar}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
          <img src={logo} style={{ height: "60px" }} alt="" />
          <div
            id="nav-icon3"
            onClick={handleHeader}
            className={!header ? "hamburger" : "hamburger open"}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div className="signup-section-wrapper">
          <Sidebar sideBar={sideBar} />

          <div className="signup-inner-parent">
            <Header header={header} />

     <div className="contact-form-main-area">
        <div className="contact-form-wrapper mt-2 clinical-question-wrapper">
          <h1 className="text-center site-heading">Clinical Trial Finder</h1>
          <p className="BluetextUnderHeading">
            Select your trial criteria you wish to filter by
          </p>
          <p className="text-center">Default setting is all.</p>
          <div className="row">
            {optionCards.map((option, index) => (
              <div className="col-lg-4" key={option.id}>
                <div
                  className={`question-option-card ${selectedOptionIndexes.includes(index) ? "active" : ""}`}
                  onClick={() => handleOptionClick(index)}
                >
                  <p>{option.name}</p>
                </div>
              </div>
            ))}
          </div>
          <button
            className="gray-button submit-btn mt-5"
            onClick={handleNextButton}
          >
            Next
          </button>
        </div>
      </div>
      </div>
      </div>
    </>
   
     
   
  );
}

export default ClinicalOptions;
