import React from 'react';

const SaveData = (props) => {
        let feilds = props.feilds;
        let title = props.title;
      
      let val;
      feilds.forEach((feild)=>{
        let elem = document.querySelector(`#${feild}`)
        if(elem)
        {
          val = elem.value;
          
          if(val)
          window.localStorage.setItem(`${title}_${feild}`,val);
        }
        
      })
    

    return (
        <div>
            
        </div>
    );
}

export default SaveData;
