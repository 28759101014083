import React, { useState, useEffect } from 'react';
import styles from '../../css/pagination.module.css';

const Pagination = ({ currentPage, totalPages, onPageChange, totalRecords }) => {
  const [resultsPerPage, setResultsPerPage] = useState(10); // Default to 10 results per page

  // Dynamically calculate totalPages based on totalRecords and resultsPerPage
  const computedTotalPages = Math.ceil(totalRecords / resultsPerPage);

  const maxPagesToShow = 5;
  let startPage, endPage;

  if (computedTotalPages <= maxPagesToShow) {
    startPage = 1;
    endPage = computedTotalPages;
  } else {
    if (currentPage <= Math.ceil(maxPagesToShow / 2)) {
      startPage = 1;
      endPage = maxPagesToShow;
    } else if (currentPage + Math.floor(maxPagesToShow / 2) >= computedTotalPages) {
      startPage = computedTotalPages - (maxPagesToShow - 1);
      endPage = computedTotalPages;
    } else {
      startPage = currentPage - Math.floor(maxPagesToShow / 2);
      endPage = currentPage + Math.floor(maxPagesToShow / 2);
    }
  }

  const pageNumbers = [];
  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  // Handle results per page change
  const handleResultsPerPageChange = (e) => {
    const newResultsPerPage = parseInt(e.target.value, 10);
    setResultsPerPage(newResultsPerPage);
    onPageChange(1); // Reset to page 1 when results per page changes
  };

  return (
    <nav>
      

      <ul className={styles.pagination}>
        <li className={styles.pageItem} style={{ padding: '0!important' }}>
          <button
            onClick={() => onPageChange(currentPage - 1)}
            className={`${styles.pageLink} ${currentPage === 1 ? styles.pageLinkDisabled : ''}`}
            disabled={currentPage === 1}
          >
            Previous
          </button>
        </li>
        {pageNumbers.map(number => (
          <li key={number} className={styles.pageItem} style={{ padding: '0px!important' }}>
            <button
              onClick={() => onPageChange(number)}
              className={`${styles.pageLink} ${currentPage === number ? styles.activePageLink : ''}`}
            >
              {number}
            </button>
          </li>
        ))}
        <li className={styles.pageItem}>
          <button
            onClick={() => onPageChange(currentPage + 1)}
            className={`${styles.pageLink} ${currentPage === computedTotalPages ? styles.pageLinkDisabled : ''}`}
            disabled={currentPage === computedTotalPages}
          >
            Next
          </button>
          
        </li>
        <select
          id="resultsPerPage"
          value={resultsPerPage}
          onChange={handleResultsPerPageChange}
          className={styles.resultsPerPageSelect}
        >
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </ul>
      <p className={styles.recordText}>
        Total Records: <b>{totalRecords}</b>
      </p>
      <div className={styles.resultsPerPage}>
        
      </div>
    </nav>
  );
};

export default Pagination;
