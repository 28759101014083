import { LOCAL_BACKEND, STAGGING_BACKEND } from "../common/helper";
const BASE_URL = STAGGING_BACKEND; // Make sure to define STAGGING_BACKEND somewhere

let commonHeaders = {
  "Content-Type": "application/json",
  Authorization: `token ${localStorage.getItem("token") || ""}`,
};

// Function to set the authorization token dynamically
function setAuthToken(token) {
  commonHeaders.Authorization = `token ${token}`;
}

// Function to make a GET request
async function getFromApi(endpoint, params = {}) {
  const url = new URL(`${BASE_URL}${endpoint}`);
  Object.keys(params).forEach((key) =>
    url.searchParams.append(key, params[key])
  );

  const requestOptions = {
    method: "GET",
    headers: { ...commonHeaders },
  };

  try {
    const response = await fetch(url, requestOptions);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    throw error;
  }
}

// Function to make a POST request
async function postToApi(endpoint, data = {}) {
  const url = `${BASE_URL}${endpoint}`;
  console.log("commonheader", commonHeaders);
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${localStorage.getItem("token") || ""}`,
    },
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(url, requestOptions);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    throw error;
  }
}

export { getFromApi, postToApi, setAuthToken };
