import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import heart from '../../images/heart.svg';
import save from "../../images/save.png";
import download from '../../images/download.svg'
import { LOCAL_BACKEND, STAGGING_BACKEND } from "../../common/helper";
import TextTruncate from 'react-text-truncate';
import { Link, useNavigate } from "react-router-dom";
import Loader from '../../components/Loader/Loader';
import CustomTooltip from "../../components/CustomTooltip/CustomTooltip";


const Therapeutics = ({ details, hideTherapeutics }) => {

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const savedFilter = urlParams.get("checkByCustomNctIds");

  const NctIds = JSON.parse(window.sessionStorage.getItem("checkByCustomNctIds"));


  const [render, setRender] = useState(true)
  const navigate = useNavigate();
  const navigateRoute = (nct_id) => {
    navigate("/results", { state: { nct_id: nct_id } });
  }

  function HandleBackBtn() {
    hideTherapeutics();

    document.querySelector('.analytics-drug-table-container').classList.add('active');
    document.querySelector('#table').style.display = "block";
    document.querySelector('#numberofUniqueMechanism').style.display = "none";
    // document.querySelector('#analytics-Container').style.display="block"
  }


  useEffect(async () => {

    console.log(details.key)
    if (typeof (details.key) != "undefined") {
      let encodedKey = encodeURIComponent(details.key);
      let url = null;
      (savedFilter != "true" ? url = STAGGING_BACKEND + "cancer/analytics_details/?filter=" + details.filter + "&key=" + encodedKey :
        url = STAGGING_BACKEND + "cancer/analytics_details/?filter=" + details.filter + "&key=" + encodedKey + "&by_save=" + NctIds.saved + "&custom_nct_ids=" + NctIds.nct_ids);

      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'token ' + localStorage.getItem("token")
        },
      };
      setIsLoading(true)
      const response = await fetch(url, requestOptions);
      const responseData = await response.json();
      console.log(responseData)
      setData(responseData.data);
      setIsLoading(false)
    }

  }, [render])

  const downloadAll = async () => {

    let url = null;
    (savedFilter != "true" ? url = STAGGING_BACKEND + "cancer/analytics_details/?filter=" + details.filter + "&key=" + details.key + "&export=xls" :
      url = STAGGING_BACKEND + "cancer/analytics_details/?filter=" + details.filter + "&key=" + details.key + "&by_save=" + NctIds.saved + "&custom_nct_ids=" + NctIds.nct_ids + "&export=xls");

    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'token ' + localStorage.getItem("token")
      },
      responseType: 'blob',
      // body: JSON.stringify(payload)
    };

    const response = await fetch(url, requestOptions);
    const responseData = await response.blob()

    const blob = new Blob([responseData]);
    const blob_url = window.URL.createObjectURL(blob);


    const link = document.createElement('a');
    link.href = blob_url;
    link.setAttribute('download', 'unique_trials.xls'); // Set the desired file name

    // Append the link to the document body and click it to trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up by removing the link and revoking the URL object
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  }


  const downloadSingleTrial = async (trial_id) => {
    let url = null;
    (savedFilter != "true" ? url = STAGGING_BACKEND + "cancer/analytics_details/?filter=" + details.filter + "&key=" + details.key + "&export=xls" + "&export_single_trial_id=" + trial_id :
      url = STAGGING_BACKEND + "cancer/analytics_details/?filter=" + details.filter + "&key=" + details.key + "&by_save=" + NctIds.saved + "&custom_nct_ids=" + NctIds.nct_ids + "&export=xls" + "&export_single_trial_id=" + trial_id);

    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'token ' + localStorage.getItem("token")
      },
      responseType: 'blob',
      // body: JSON.stringify(payload)
    };

    const response = await fetch(url, requestOptions);
    const responseData = await response.blob()

    const blob = new Blob([responseData]);
    const blob_url = window.URL.createObjectURL(blob);


    const link = document.createElement('a');
    link.href = blob_url;
    link.setAttribute('download', `unique-single-trials-${trial_id}.xls`); // Set the desired file name

    // Append the link to the document body and click it to trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up by removing the link and revoking the URL object
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  }



  const saveAll = async () => {
    let url = null;
    (savedFilter != "true" ? url = STAGGING_BACKEND + "cancer/analytics_details/?filter=" + details.filter + "&key=" + details.key + "&export=save" :
      url = STAGGING_BACKEND + "cancer/analytics_details/?filter=" + details.filter + "&key=" + details.key + "&by_save=" + NctIds.saved + "&custom_nct_ids=" + NctIds.nct_ids + "&export=save");

    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'token ' + localStorage.getItem("token")
      },
      responseType: 'blob',
      // body: JSON.stringify(payload)
    };

    const response = await fetch(url, requestOptions);
    const responseData = await response.json()
    setRender(!render)
  }


  const HandleUnSave = async (nct_id) => {
    const url = STAGGING_BACKEND + "cancer/unsave/"
    const payload = {
      nct_id: nct_id,
    }
    const requestOptions = {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'token ' + localStorage.getItem("token"),

      },
      body: JSON.stringify(payload)
    }
    const response = await fetch(url, requestOptions);
    const responseData = await response.json()
    console.log(responseData.data)
    setRender(!render);
  }

  const HandleSingleSave = async (nct_id) => {
    const url = STAGGING_BACKEND + "cancer/trialdetails/"
    const payload = {
      nct_id: nct_id,
      export: "save"
    }
    const requestOptions = {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'token ' + localStorage.getItem("token"),

      },
      body: JSON.stringify(payload)
    }
    const response = await fetch(url, requestOptions);
    const responseData = await response.json()
    console.log(responseData.data)
    setRender(!render);
  }



  let column = null;
  let filter = details.filter;
  switch (filter) {
    case "uniqueDrugs":
      column = "Drug"
      break

    case "uniqueMechanisms":
      column = "Mechanism of action"
      break

    case "uniqueProtiens":
      column = "Protein"
      break

    case "uniqueDistances":
      column = "Distance(Miles)"
      break

    case "uniqueInstitutions":
      column = "Institution"
      break

    case "uniqueMutations":
      column = "Mutation"
      break

    case "uniqueTherapies":
      column = "Line of therapy"
      break;

    case "uniqueStages":
      column = "Stage"
      break

    case "uniquePhases":
      column = "Phase"
      break;

    case "uniqueInterventions":
      column = "Intervention";
      break
  }
  return (
    <>
      {isLoading && <Loader />}
      <div className="d-flex justify-content-end  mb-3">
        <button className="blue-button" style={{ marginRight: '10px' }} onClick={() => {
          saveAll();
        }}>Save All</button>
        <button className="blue-button" style={{ marginRight: '10px' }} onClick={() => {
          downloadAll();
        }}>Download</button>
        <button className="blue-button" onClick={HandleBackBtn}>Back</button>
      </div>
      <div style={{ overflowX: "auto", overflowY: "auto", maxHeight: "370px" }} className="drugstatusTable-wrapper">
        <Table hover className='drugsTable'>
          <thead>
            <tr>
              <th>{column}</th>
              <th>NCT ID</th>
              <th>Brief title</th>
              <th>Status</th>
              <th>Sponsor</th>
              <th>Tumor type</th>
              {/* <th>Phase</th> */}
              <th></th>

            </tr>
          </thead>
          <tbody>

            {data?.map((item, key) => {
              return (
                <tr>
                  <td className='drugName'>
                    {/* <TextTruncate
                      line={1}
                      element="span"
                      truncateText="…"
                      text={item.key}
                    /> */}
                     <CustomTooltip text={item.key} /> 
                  </td>
                  <td>
                    <p>
                      {/* <TextTruncate
                        line={1}
                        element="span"
                        truncateText="…"
                        text={item.NCT_ID}
                      /> */}
                      {item.NCT_ID}
                    </p>
                  </td>
                  <td>
                  <CustomTooltip text={item.brief_title} />
                </td>
                  <td>
                    {/* <TextTruncate
                    line={1}
                    element="span"
                    truncateText="…"
                    text={item.status}
                  /> */}
                    {item.status}
                  </td>
                  <td>
                    {/* <TextTruncate
                    line={1}
                    element="span"
                    truncateText="…"
                    text={""}
                  /> */}
                    {""}
                  </td>
                  <td >{item.tumor_type} <br /></td>
                  {/* <td>Phase 4</td> */}
                  <td style={{ minWidth: '220px' }}>
                    <div className='d-flex justify-content-center align-items-center gap-1'>
                      <button className='blue-button' onClick={() => {
                        navigateRoute(item.NCT_ID);
                      }}>See Details</button>

                      <img src={download} className="drugstableIcon" alt="" onClick={() => {
                        downloadSingleTrial(item.NCT_ID);
                      }} />

                      {item.saved ? (
                        <img src={save} className="drugstableIcon" alt="" onClick={() => {
                          HandleUnSave(item.NCT_ID)
                        }} />
                      ) : <img src={heart} className="drugstableIcon" alt="" onClick={() => {
                        HandleSingleSave(item.NCT_ID)
                      }} />}

                    </div>
                  </td>
                </tr>
              )
            })}

          </tbody>
        </Table>
      </div>
    </>
  );
}
export default Therapeutics;
