import React, { useState, useEffect, useRef } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link, useNavigate } from "react-router-dom";
import { STAGGING_BACKEND, LOCAL_BACKEND } from "../../common/helper";
import SaveData from "../../components/Save_Data/Save_Data";
import RenderData from "../../components/Save_Data/Render_Data";
import { useDispatch, useSelector } from "react-redux";
import DeleteData from "../../components/Save_Data/Delete_Data";
import Select from "react-select";
import crossIcon from "../../images/cross.svg";
import DashboardLayout from "../../components/dashboardLayout/dashboardLayout";
import QuestionAirFormFooter from "../../components/questionAirFormFooter/questionAirFormFooter";
import QuestionAirTabs from "../../components/questionairTabs/questionairTabs";
import { ecogStatusOptions, karnoskyScoreOptions } from "../../libs/optionsHandling";
import { generateNamesArray, handleSwitchStatement } from "../../libs/Util";
import { useLocation } from "react-router-dom";
import { navigateToPreviousOption, navigateToNextOption, getPageOptionsFromIndices } from "../../libs/Util";
import Loader from "../../components/Loader/Loader";

function ClinicalQuestion5() {
  const location = useLocation();

  const [lastOption, setLastOption] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [importDataState, setImportDataState] = useState(false);
  const [saveDataState, setSaveDataState] = useState(false);
  const [ecog, setEcog] = useState("");
  const [karnosky, setKarnosky] = useState("");
  const [kidneyFunction, setKidneyFunction] = useState("");
  const [creatinineClearance, setCreatinineClearance] = useState("");
  const [whiteBloodCellsCount, setWhiteBloodCellsCount] = useState("");
  const [neutrophil, setNeutrophil] = useState("");
  const [hemoglobin, setHemoglobin] = useState("");
  const [plateletCount, setPlateletCount] = useState("");
  const [ast, setAst] = useState("");
  const [alt, setAlt] = useState("");
  const [bilirubin, setBilirubin] = useState("");
  const [albumin, setAlbumin] = useState("");
  const [alkalinePhosphatase, setAlkalinePhosphatase] = useState("");
  const [clearData, setClearData] = useState(false);
  const [data, setData] = useState([
    "Comorbidity1",
    "Comorbidity2",
    "Comorbidity3",
    "Comorbidity4",
  ]);
  const [symptomsData, setSymptomsData] = useState([
    "Symptom1",
    "Symptom2",
    "Symptom3",
    "Symptom4",
  ]);
  const [searchTerm, setSearchTerm] = useState("");
  const [symptomSearchTerm, setSymptomSearchTerm] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [symptomSelectedItems, setSymptomSelectedItems] = useState([]);
  const [showDropDown, setShowDropDown] = useState(true);
  const [showSymptomDropDown, setShowSymptomDropDown] = useState(true);

  const kidneyFunctionRef = useRef();
  const creatinineClearanceRef = useRef()
  const whiteBloodCellsCountRef = useRef()
  const neutrophilRef = useRef();
  const hemoglobinRef = useRef();
  const plateletCountRef = useRef();
  const albuminRef = useRef();
  const alkalineRef = useRef();
  const bilirubinRef = useRef();
  const altRef = useRef();
  const astRef = useRef();

  const navigate = useNavigate();
  const selector = useSelector((state) => state.options);

  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get("mode");
  const page = urlParams.get("page");
  const mode = urlParams.get("mode");
  const nullValue = { label: "choose below", value: "" };
  const savedFilter = urlParams.get("checkByCustomNctIds");

  const optionsToShow = new URLSearchParams(location.search).get("optionsToShow");
  const pageOptions = getPageOptionsFromIndices(optionsToShow);
  let names = generateNamesArray(mode, pageOptions, savedFilter, page, optionsToShow);

  const OnSubmitForm = async () => {
    try {
      setIsLoading(true);
      const url = STAGGING_BACKEND + "cancer/questionair/cancer_type/";
      const payload = {
        ecog_perf: ecog.value,
        karnosky_perf: karnosky.value,
        kidney_func: kidneyFunctionRef?.current?.value,
        creatinine_clearance: creatinineClearanceRef?.current?.value,
        white_blood_cells_count: whiteBloodCellsCountRef?.current?.value,
        neutrophil: neutrophilRef?.current?.value,
        hemoglobin: hemoglobinRef?.current?.value,
        platelet_count: plateletCountRef?.current?.value,
        ast: astRef?.current?.value,
        alt: altRef?.current?.value,
        bilirubin: bilirubinRef?.current?.value,
        albumin: albuminRef?.current?.value,
        alkaline_phostate: alkalineRef?.current?.value,
        comorbidity: Get_Selected_Options(),
        symptom: Get_SymptomSelected_Options(),
      };
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "token " + localStorage.getItem("token"),
        },
        body: JSON.stringify(payload),
      };
      const response = await fetch(url, requestOptions);
      const responseData = await response.json();
      console.log(responseData);

      if (mode != "full_options") {
        navigateToNextOption(navigate, '4', mode, optionsToShow);
      } else {
        navigate(
          `/clinical-question6?mode=full_options${page === "ccTreatmentGuide" ? "&page=ccTreatmentGuide" : ""
          }${savedFilter === "true" ? "&checkByCustomNctIds=true" : ""}`
        );
      }
    } catch (error) {
      console.log('error', error)
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let array = names.map((item) => {
      return item.text;
    });
    Check_Last_Index(array);
    setImportDataState(true);
    let select_array = [
      {
        title: "Functional_Status_ecog_performance_status",
        options: ecogStatusOptions,
      },
      {
        title: "Functional_Status_kernosky_performance_score",
        options: karnoskyScoreOptions,
      },
    ];
    select_array.forEach((item) => {
      Import_Save_Data(item.title, item.options);
    });

    const get_opt = window.localStorage.getItem(
      "clinical_characteristics_comorbidities_selected_options"
    );
    if (get_opt) {
      console.log("get_optttt****", JSON.parse(get_opt));

      Render_At_Load(JSON.parse(get_opt), null);
    }
    const get_symptoms_opt = window.localStorage.getItem(
      "clinical_characteristics_symptoms_selected_options"
    );
    if (get_symptoms_opt) {
      console.log("get_symptoms_opt****", JSON.parse(get_symptoms_opt));

      Render_At_Load(null, JSON.parse(get_symptoms_opt));
    }
  }, []);

  function Import_Save_Data(title, options) {
    const genderOpt = window.localStorage.getItem(title);
    const result = options.find((option) => option.value === genderOpt);
    if (result) {
      switch (title) {
        case "Functional_Status_ecog_performance_status":
          setEcog(result);
          break;
        case "Functional_Status_kernosky_performance_score":
          setKarnosky(result);
          break;

        default:
          break;
      }
    }
  }

  useEffect(() => {
    setSaveDataState(true);
    Save_Select_Data();
  }, [
    albumin,
    bilirubin,
    alt,
    ast,
    plateletCount,
    hemoglobin,
    neutrophil,
    kidneyFunction,
    karnosky,
    ecog,
  ]);

  function Save_Select_Data() {
    if (ecog)
      window.localStorage.setItem(
        `Functional_Status_ecog_performance_status`,
        ecog.value
      );
    if (karnosky)
      window.localStorage.setItem(
        `Functional_Status_kernosky_performance_score`,
        karnosky.value
      );
  }

  function Check_Last_Index(array) {
    const lastIndex = array[array.length - 1];
    console.log("last item is", lastIndex);
    if (lastIndex == "Functional Status") setLastOption(true);
  }

  const OnSubmitForm2 = async () => {
    try {
      setIsLoading(true)
      const url = STAGGING_BACKEND + "cancer/questionair/cancer_type/";
      const payload = {
        ecog_perf: ecog.value,
        karnosky_perf: karnosky.value,
        kidney_func: kidneyFunctionRef?.current?.value,
        creatinine_clearance: creatinineClearanceRef?.current?.value,
        white_blood_cells_count: whiteBloodCellsCountRef?.current?.value,
        neutrophil: neutrophilRef?.current?.value,
        hemoglobin: hemoglobinRef?.current?.value,
        platelet_count: plateletCountRef?.current?.value,
        ast: astRef?.current?.value,
        alt: altRef?.current?.value,
        bilirubin: bilirubinRef?.current?.value,
        albumin: albuminRef?.current?.value,
        alkaline_phostate: alkalineRef?.current?.value,
        comorbidity: Get_Selected_Options(),
        symptom: Get_SymptomSelected_Options(),
      };

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "token " + localStorage.getItem("token"),
        },
        body: JSON.stringify(payload),
      };
      const response = await fetch(url, requestOptions);
      const responseData = await response.json();
      console.log(responseData);
      navigate(
        page === "ccTreatmentGuide"
          ? "/cc-treatment-plans"
          : `/stats-drugs${savedFilter === "true" ? "?checkByCustomNctIds=true" : ""
          }`
      );
    } catch (error) {
      console.log('error', error)
    } finally {
      setIsLoading(false);
    }
  };

  function HandleBackBtn() {
    if (mode != "full_options") {
      navigateToPreviousOption(navigate, '4', mode, optionsToShow);
    } else {
      navigate(
        `/clinical-question4?mode=full_options${page === "ccTreatmentGuide" ? "&page=ccTreatmentGuide" : ""
        }${savedFilter === "true" ? "&checkByCustomNctIds=true" : ""}`
      );
    }
  }
  const handleClearCancel = () => {
    setClearData(false);
  };

  const handleClearConfirm = () => {
    setKarnosky(nullValue);
    setEcog(nullValue);
    setSelectedItems([]);
    setSymptomSelectedItems([]);
    setClearData(false);
  };

  const filteredData = data.filter((item) =>
    item.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const symptomsFilteredData = symptomsData.filter((item) =>
    item.toLowerCase().includes(symptomSearchTerm.toLowerCase())
  );

  function handleListItemClick(item) {
    let updatedSelectedItems;
    if (selectedItems.includes(item)) {
      updatedSelectedItems = selectedItems.filter(selectedItem => selectedItem !== item);
    } else {
      updatedSelectedItems = [...selectedItems, item];
    }
    setSelectedItems(updatedSelectedItems);
    window.localStorage.setItem(
      "clinical_characteristics_comorbidities_selected_options",
      JSON.stringify(updatedSelectedItems)
    );
  }

  function handleSymptomListItemClick(item) {
    let updatedSymptomSelectedItems;
    if (symptomSelectedItems.includes(item)) {
      updatedSymptomSelectedItems = symptomSelectedItems.filter(selectedItem => selectedItem !== item);
    } else {
      updatedSymptomSelectedItems = [...symptomSelectedItems, item];
    }
    setSymptomSelectedItems(updatedSymptomSelectedItems);
    window.localStorage.setItem(
      "clinical_characteristics_symptoms_selected_options",
      JSON.stringify(updatedSymptomSelectedItems)
    );
  }

  function onRemoveTag(itemToRemove) {
    const newSelectedItems = selectedItems.filter(item => item !== itemToRemove);
    setSelectedItems(newSelectedItems);
    window.localStorage.setItem(
      "clinical_characteristics_comorbidities_selected_options",
      JSON.stringify(newSelectedItems)
    );
  }

  function onSymptomRemoveTag(itemToRemove) {
    const newSymptomSelectedItems = symptomSelectedItems.filter(item => item !== itemToRemove);
    setSymptomSelectedItems(newSymptomSelectedItems);
    window.localStorage.setItem(
      "clinical_characteristics_symptoms_selected_options",
      JSON.stringify(newSymptomSelectedItems)
    );
  }

  function Get_Selected_Options() {
    const get_options = window.localStorage.getItem(
      "clinical_characteristics_comorbidities_selected_options"
    );
    return get_options ? JSON.parse(get_options) : [];
  }

  function Get_SymptomSelected_Options() {
    const get_options = window.localStorage.getItem(
      "clinical_characteristics_symptoms_selected_options"
    );
    return get_options ? JSON.parse(get_options) : [];
  }

  function Render_At_Load(selected_items, symptom_items) {
    if (selected_items !== null) {
      setSelectedItems(selected_items);
    }
    if (symptom_items !== null) {
      setSymptomSelectedItems(symptom_items);
    }
  }
  const handleMenuOpen = () => {
    const container = document.querySelector('.question-form-wrapper');
    if (container) {
      setTimeout(() => {
        if (container.scrollHeight > container.clientHeight) {
          // Calculate the desired scroll position
          const scrollDistance = (container.scrollHeight - container.clientHeight) * 0.1;
          container.scrollTop = scrollDistance;
        }
      }, 150);
    }
  };


  return (
    <>
      {isLoading && <Loader />}
      <DashboardLayout>
        <h1 className="text-center site-heading">
          {page === "ccTreatmentGuide"
            ? "CC Treatment Guide"
            : "Clinical Trial Finder"}
        </h1>
        <QuestionAirTabs names={names} currentTab="4" />
        <div className="activeSection-wrapper">
          <div className="question-form-wrapper">
            <form action="">
              <h2>Clinical Characteristics</h2>
              <p>Please input the following data</p>
              <div className="form_container_sec">
                <label htmlFor="">What is your ECOG performance status? </label>
                <Select
                  options={ecogStatusOptions}
                  value={ecog}
                  className="select_search"
                  id="ecog_performance_status"
                  onChange={(event) => {
                    setEcog(event);
                  }}
                  onMenuOpen={() => {
                    handleMenuOpen();
                  }}
                  menuPlacement="auto"
                />

                <label htmlFor="">
                  What is your Karnosky performance score?{" "}
                </label>
                <Select
                  options={karnoskyScoreOptions}
                  value={karnosky}
                  className="select_search"
                  id="kernosky_performance_score"
                  onChange={(event) => {
                    setKarnosky(event);
                  }}
                  onMenuOpen={() => {
                    handleMenuOpen();
                  }}
                  menuPlacement="auto"
                />

                <label htmlFor="">What is your kidney function (creatinine)? </label>
                <input
                  type="text"
                  name="userName"
                  className="signup-box-input loginfields"
                  placeholder="Enter Creatinine(valid values : 0-20)"
                  id="kidney_function"
                  ref={kidneyFunctionRef}
                  onChange={(event) => {
                    setKidneyFunction(event.target.value);
                  }}
                  onKeyPress={(event) => {
                    const isValidChar = /[0-9.]/.test(event.key);
                    const isDecimalPoint = event.key === ".";
                    if (!isValidChar || (isDecimalPoint && event.target.value.includes("."))) {
                      event.preventDefault();
                    }
                  }}
                  onInput={(event) => {
                    const value = event.target.value;
                    const sanitized = value.replace(/[^0-9.]/g, "");
                    event.target.value = sanitized;
                    if (value.split(".").length > 2) {
                      event.target.value = value.replace(/\.+$/, "");
                    }
                    if (parseFloat(sanitized) > 20) {
                      event.target.value = 20;
                    } else if (parseFloat(sanitized) < 0) {
                      event.target.value = 0;
                    }
                  }}
                />

                <label htmlFor="">What is your kidney function (creatinine Clearance)? </label>
                <input
                  type="text"
                  name="userName"
                  className="signup-box-input loginfields"
                  placeholder="Enter Creatinine Clearance (valid values : 0-150)"
                  id="creatine_clearance"
                  ref={creatinineClearanceRef}
                  onChange={(event) => {
                    setCreatinineClearance
                    (event.target.value);
                  }}
                  onKeyPress={(event) => {
                    const isValidChar = /[0-9.]/.test(event.key);
                    const isDecimalPoint = event.key === ".";
                    if (!isValidChar || (isDecimalPoint && event.target.value.includes("."))) {
                      event.preventDefault();
                    }
                  }}
                  onInput={(event) => {
                    const value = event.target.value;
                    const sanitized = value.replace(/[^0-9.]/g, "");
                    event.target.value = sanitized;
                    if (value.split(".").length > 2) {
                      event.target.value = value.replace(/\.+$/, "");
                    }
                    if (parseFloat(sanitized) > 150) {
                      event.target.value = 150;
                    } else if (parseFloat(sanitized) < 0) {
                      event.target.value = 0;
                    }
                  }}
                />

                <label htmlFor="">What is your white blood cell count? </label>
                <input
                  type="text"
                  name="userName"
                  className="signup-box-input loginfields"
                  placeholder="Enter white blood cell count (valid values : 0-2000)"
                  id="white_blood_count"
                  ref={whiteBloodCellsCountRef}
                  onChange={(event) => {
                    setWhiteBloodCellsCount(event.target.value);
                  }}
                  onKeyPress={(event) => {
                    const isValidChar = /[0-9.]/.test(event.key);
                    const isDecimalPoint = event.key === ".";
                    if (!isValidChar || (isDecimalPoint && event.target.value.includes("."))) {
                      event.preventDefault();
                    }
                  }}
                  onInput={(event) => {
                    const value = event.target.value;
                    const sanitized = value.replace(/[^0-9.]/g, "");
                    event.target.value = sanitized;
                    if (value.split(".").length > 2) {
                      event.target.value = value.replace(/\.+$/, "");
                    }
                    if (parseFloat(sanitized) > 2000) {
                      event.target.value = 2000;
                    } else if (parseFloat(sanitized) < 0) {
                      event.target.value = 0;
                    }
                  }}
                />

                <label htmlFor="">What is your neutrophil count? </label>
                <input
                  type="text"
                  name="userName"
                  className="signup-box-input loginfields"
                  placeholder="Enter Neutrophil Count (valid values : 0-100)"
                  id="neutrophil_count"
                  ref={neutrophilRef}
                  onChange={(event) => {
                    setNeutrophil(event.target.value);
                  }}
                  onKeyPress={(event) => {
                    const isValidChar = /[0-9.]/.test(event.key);
                    const isDecimalPoint = event.key === ".";
                    if (!isValidChar || (isDecimalPoint && event.target.value.includes("."))) {
                      event.preventDefault();
                    }
                  }}
                  onInput={(event) => {
                    const value = event.target.value;
                    const sanitized = value.replace(/[^0-9.]/g, "");
                    event.target.value = sanitized;
                    if (value.split(".").length > 2) {
                      event.target.value = value.replace(/\.+$/, "");
                    }
                    if (parseFloat(sanitized) > 100) {
                      event.target.value = 100;
                    } else if (parseFloat(sanitized) < 0) {
                      event.target.value = 0;
                    }
                  }}
                />
                <label htmlFor="">What is your hemoglobin? </label>
                <input
                  type="text"
                  className="signup-box-input loginfields"
                  placeholder="Enter Hemoglobin (valid values : 0-30)"
                  id="hemglobin"
                  ref={hemoglobinRef}
                  onChange={(event) => {
                    setHemoglobin(event.target.value);
                  }}
                  onKeyPress={(event) => {
                    const isValidChar = /[0-9.]/.test(event.key);
                    const isDecimalPoint = event.key === ".";
                    if (!isValidChar || (isDecimalPoint && event.target.value.includes("."))) {
                      event.preventDefault();
                    }
                  }}
                  onInput={(event) => {
                    const value = event.target.value;
                    const sanitized = value.replace(/[^0-9.]/g, "");
                    event.target.value = sanitized;
                    if (value.split(".").length > 2) {
                      event.target.value = value.replace(/\.+$/, "");
                    }
                    if (parseFloat(sanitized) > 30) {
                      event.target.value = 30;
                    } else if (parseFloat(sanitized) < 0) {
                      event.target.value = 0;
                    }
                  }}
                />
                <label htmlFor="">What is your platelet count? </label>
                <input
                  type="text"
                  name="userName"
                  className="signup-box-input loginfields"
                  placeholder="Enter Platelet Count (valid values : 0-3000)"
                  id="platelet_count"
                  ref={plateletCountRef}
                  onChange={(event) => {
                    setPlateletCount(event.target.value);
                  }}
                  onKeyPress={(event) => {
                    const isValidChar = /[0-9.]/.test(event.key);
                    const isDecimalPoint = event.key === ".";
                    if (!isValidChar || (isDecimalPoint && event.target.value.includes("."))) {
                      event.preventDefault();
                    }
                  }}
                  onInput={(event) => {
                    const value = event.target.value;
                    const sanitized = value.replace(/[^0-9.]/g, "");
                    event.target.value = sanitized;
                    if (value.split(".").length > 2) {
                      event.target.value = value.replace(/\.+$/, "");
                    }
                    if (parseFloat(sanitized) > 3000) {
                      event.target.value = 3000;
                    } else if (parseFloat(sanitized) < 0) {
                      event.target.value = 0;
                    }
                  }}
                />
                <label htmlFor="">What is your AST? </label>
                <input
                  type="text"
                  name="userName"
                  className="signup-box-input loginfields"
                  placeholder="Enter AST (valid values : 0-20000)"
                  id="ast"
                  ref={astRef}
                  onChange={(event) => {
                    setAst(event.target.value);
                  }}
                  onKeyPress={(event) => {
                    const isValidChar = /[0-9.]/.test(event.key);
                    const isDecimalPoint = event.key === ".";
                    if (!isValidChar || (isDecimalPoint && event.target.value.includes("."))) {
                      event.preventDefault();
                    }
                  }}
                  onInput={(event) => {
                    const value = event.target.value;
                    const sanitized = value.replace(/[^0-9.]/g, "");
                    event.target.value = sanitized;
                    if (value.split(".").length > 2) {
                      event.target.value = value.replace(/\.+$/, "");
                    }
                    if (parseFloat(sanitized) > 20000) {
                      event.target.value = 20000;
                    } else if (parseFloat(sanitized) < 0) {
                      event.target.value = 0;
                    }
                  }}
                />
                <label htmlFor="">What is your ALT? </label>
                <input
                  type="text"
                  name="userName"
                  className="signup-box-input loginfields"
                  placeholder="Enter ALT (valid values : 0-20000)"
                  id="alt"
                  ref={altRef}
                  onChange={(event) => {
                    setAlt(event.target.value);
                  }}
                  onKeyPress={(event) => {
                    const isValidChar = /[0-9.]/.test(event.key);
                    const isDecimalPoint = event.key === ".";
                    if (!isValidChar || (isDecimalPoint && event.target.value.includes("."))) {
                      event.preventDefault();
                    }
                  }}
                  onInput={(event) => {
                    const value = event.target.value;
                    const sanitized = value.replace(/[^0-9.]/g, "");
                    event.target.value = sanitized;
                    if (value.split(".").length > 2) {
                      event.target.value = value.replace(/\.+$/, "");
                    }
                    if (parseFloat(sanitized) > 20000) {
                      event.target.value = 20000;
                    } else if (parseFloat(sanitized) < 0) {
                      event.target.value = 0;
                    }
                  }}
                />
                <label htmlFor="">What is your bilirubin? </label>
                <input
                  type="text"
                  name="userName"
                  className="signup-box-input loginfields"
                  placeholder="Enter Bilirubin (valid values : 0-50)"
                  id="bilirubin"
                  ref={bilirubinRef}
                  onChange={(event) => {
                    setBilirubin(event.target.value);
                  }}
                  onKeyPress={(event) => {
                    const isValidChar = /[0-9.]/.test(event.key);
                    const isDecimalPoint = event.key === ".";
                    if (!isValidChar || (isDecimalPoint && event.target.value.includes("."))) {
                      event.preventDefault();
                    }
                  }}
                  onInput={(event) => {
                    const value = event.target.value;
                    const sanitized = value.replace(/[^0-9.]/g, "");
                    event.target.value = sanitized;
                    if (value.split(".").length > 2) {
                      event.target.value = value.replace(/\.+$/, "");
                    }
                    if (parseFloat(sanitized) > 50) {
                      event.target.value = 50;
                    } else if (parseFloat(sanitized) < 0) {
                      event.target.value = 0;
                    }
                  }}
                />
                <label htmlFor="">What is your albumin? </label>
                <input
                  type="text"
                  name="userName"
                  className="signup-box-input loginfields"
                  placeholder="Enter Albumin (valid values : 0-10)"
                  id="albumin"
                  ref={albuminRef}
                  onChange={(event) => {
                    setAlbumin(event.target.value);
                  }}
                  onKeyPress={(event) => {
                    const isValidChar = /[0-9.]/.test(event.key);
                    const isDecimalPoint = event.key === ".";
                    if (!isValidChar || (isDecimalPoint && event.target.value.includes("."))) {
                      event.preventDefault();
                    }
                  }}
                  onInput={(event) => {
                    const value = event.target.value;
                    const sanitized = value.replace(/[^0-9.]/g, "");
                    event.target.value = sanitized;
                    if (value.split(".").length > 2) {
                      event.target.value = value.replace(/\.+$/, "");
                    }
                    if (parseFloat(sanitized) > 10) {
                      event.target.value = 10;
                    } else if (parseFloat(sanitized) < 0) {
                      event.target.value = 0;
                    }
                  }}
                />
                <label htmlFor="">What is your alkaline phosphatase? </label>
                <input
                  type="text"
                  name="userName"
                  className="signup-box-input loginfields"
                  placeholder="Enter Alkaline Phosphatase"
                  id="alkaline_phosphatase"
                  ref={alkalineRef}
                  onChange={(event) => {
                    setAlkalinePhosphatase(event.target.value);
                  }}
                  onKeyPress={(event) => {
                    const isValidChar = /[0-9.]/.test(event.key);
                    const isDecimalPoint = event.key === ".";
                    if (!isValidChar || (isDecimalPoint && event.target.value.includes("."))) {
                      event.preventDefault();
                    }
                  }}
                  onInput={(event) => {
                    const value = event.target.value;
                    const sanitized = value.replace(/[^0-9.]/g, "");
                    event.target.value = sanitized;
                    if (value.split(".").length > 2) {
                      event.target.value = value.replace(/\.+$/, "");
                    }
                    if (parseFloat(sanitized) > 20000) {
                      event.target.value = 20000;
                    } else if (parseFloat(sanitized) < 0) {
                      event.target.value = 0;
                    }
                  }}
                />

                <label htmlFor="">
                  What Are Your Other Current Or Past Medical Problems (Comorbidities)?
                </label>
                <br />
                <section className="multiselect-section">
                  <div className="custom-multiselect-container">
                    <div
                      className="multiselect-label-area"
                      style={{ padding: "0px" }}
                      onClick={() => setShowDropDown(true)}
                    >
                      <input
                        type="text"
                        placeholder="Select Options"
                        value={searchTerm}
                        className="mutationSearchInput"
                        onChange={(e) => setSearchTerm(e.target.value)}
                        onClick={() => setShowDropDown(true)}
                      />
                    </div>
                    {showDropDown && (
                      <div className="mutliselect-options-wrap">
                        <ul id="multiSelectList">
                          {filteredData?.map((item, index) => (
                            <li
                              key={index}
                              value={item}
                              className={selectedItems?.includes(item) ? "selected_li" : ""}
                              onClick={() => handleListItemClick(item)}
                            >
                              {item}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="select-tags-container" id="select-tags-container">
                    <h6 style={{ margin: "0" }} className="text-center">Selected Options</h6>
                    {Array.isArray(selectedItems) && selectedItems?.map((item, index) => (
                      <div key={index} className="selected-tag">
                        {item}
                        <img
                          src={crossIcon}
                          className="selected-tag-cross"
                          onClick={() => onRemoveTag(item)}
                          alt="Remove tag"
                        />
                      </div>
                    ))}
                  </div>
                </section>

                {/* <label htmlFor="">What symptoms are you experiencing?</label>
                <br />
                <section className="multiselect-section">
                  <div className="custom-multiselect-container">
                    <div
                      className="multiselect-label-area"
                      style={{ padding: "0px" }}
                      onClick={() => setShowSymptomDropDown(true)}
                    >
                      <input
                        type="text"
                        placeholder="Select Options"
                        value={symptomSearchTerm}
                        className="mutationSearchInput"
                        onChange={(e) => setSymptomSearchTerm(e.target.value)}
                        onClick={() => setShowSymptomDropDown(true)}
                      />
                    </div>
                    {showSymptomDropDown && (
                      <div className="mutliselect-options-wrap">
                        <ul id="multiSelectList">
                          {symptomsFilteredData?.map((item, index) => (
                            <li
                              key={index}
                              value={item}
                              className={symptomSelectedItems?.includes(item) ? "selected_li" : ""}
                              onClick={() => handleSymptomListItemClick(item)}
                            >
                              {item}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="select-tags-container" id="select-tags-container">
                    <h6 style={{ margin: "0" }} className="text-center">Selected Options</h6>
                    {Array.isArray(symptomSelectedItems) && symptomSelectedItems?.map((item, index) => (
                      <div key={index} className="selected-tag">
                        {item}
                        <img
                          src={crossIcon}
                          className="selected-tag-cross"
                          onClick={() => onSymptomRemoveTag(item)}
                          alt="Remove tag"
                        />
                      </div>
                    ))}
                  </div>
                </section> */}
              </div>
              <QuestionAirFormFooter
                handleBack={HandleBackBtn}
                handleSubmit={OnSubmitForm}
                handleSubmit2={OnSubmitForm2}
                handleClear={() => setClearData(true)}
                isLastOption={lastOption}
              />
            </form>
          </div>
        </div>
      </DashboardLayout>

      {saveDataState && (
        <SaveData
          feilds={[
            "kidney_function",
            "neutrophil_count",
            "white_blood_count",
            "creatine_clearance",
            "hemglobin",
            "platelet_count",
            "alt",
            "ast",
            "bilirubin",
            "albumin",
            "alkaline_phosphatase",
          ]}
          title={"Functional_Status"}
        />
      )}
      {importDataState && (
        <RenderData
          feilds={[
            "kidney_function",
            "neutrophil_count",
            "hemglobin",
            "creatine_clearance",
            "white_blood_count",
            "platelet_count",
            "alt",
            "ast",
            "bilirubin",
            "albumin",
            "alkaline_phosphatase",
          ]}
          title={"Functional_Status"}
        />
      )}
      {clearData && (
        <DeleteData
          onCancel={handleClearCancel}
          onConfirm={handleClearConfirm}
          feilds={[
            "ecog_performance_status",
            "kernosky_performance_score",
            "creatine_clearance",
            "kidney_function",
            "neutrophil_count",
            "hemglobin",
            "white_blood_count",
            "platelet_count",
            "alt",
            "ast",
            "bilirubin",
            "albumin",
            "alkaline_phosphatase",
          ]}
        />
      )}
    </>
  );
}

export default ClinicalQuestion5;
