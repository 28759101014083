import React from 'react';

const DeleteData = (props) => {
    function Handle_Confirm_Btn() {
        let feilds = props.feilds;
        let array = [
            'Demographics_age', 'Demographics_gender', 'Demographics_race','Demographics_breastfeeding','Demographics_menopausal', 'Demographics_pregnant', 'Location_city', 'Location_zip_code',
            'Location_distance', 'Location_is_institution', 'Location_which_institution',
            'Trial_Sponsor_is_interested_in_drug', 'Trial_Sponsor_trial_status', 'Trial_Sponsor_phase_trial',
            'Trial_Sponsor_is_interested_in_med_device', 'Trial_Sponsor_med_device_name', 'Trial_Sponsor_drug_name',
            'Trial_Sponsor_trial_type', 'Functional_Status_ecog_performance_status', 'Functional_Status_hemglobin',
            'Functional_Status_kidney_function', 'Functional_Status_platelet_count', 'Functional_Status_neutrophil_count',
            'Functional_Status_kernosky_performance_score', 'Functional_Status_ast', 'Functional_Status_alt',
            'Functional_Status_bilirubin', 'Functional_Status_albumin', 'Functional_Status_alkaline_phosphatase',
            'Cancer_Characteristics_measurable_cancer', 'Cancer_Characteristicsis_cancer_pdl1', 'Cancer_Characteristicsis_homolgous',
            'Cancer_Characteristicsis_tmb_high', 'Cancer_Characteristics_cps_tps_score', 'Cancer_Type_what_cancer',
            'Cancer_Characteristics_actionable_mutation', 'Cancer_Characteristicsis_msi_high',
            'molecular_profile_selected_options', 'Treatment_History_is_treated', 'Treatment_History_second_treatment',
            'Treatment_History_third_treatment', 'Treatment_History_first_treatment',
            'Treatment_History_fourth_treatment', 'Treatment_History_fifth_treatment',
            'Drug_Mechanism_which_mechanism', 'Drug_Mechanism_is_drug', 'Drug_Mechanism_mechanism','Drug_Mechanism_is_med_device',
            'Drug_Mechanism_which_drug', 'clinical_characteristics_comorbidities_selected_options',
            'Trial_Sponsor_Phase_selected_options','selectedDrug','selectedDevice','selectedMechanism','Functional_Status_creatine_clearance','Functional_Status_white_blood_count',
            'clinical_characteristics_symptoms_selected_options','Cancer_Characteristicsis_HER2','Cancer_Characteristicsis_estrogenReceptorPositive',
            'Cancer_Characteristicsis_progesteroneReceptorPositive','Cancer_Characteristics_Ki67','Cancer_Type_subtype',
            'selectedFirstTreatment', 'selectedSecondTreatment','selectedThirdTreatment','selectedFourthTreatment','selectedFifthTreatment','Cancer_Characteristicsis_dMMR'
           
        ];

        array.forEach((item) => {
            if (item === 'Cancer_Type_estrogenReceptorPositive') {
                const test = window.localStorage.getItem(item);
                console.log('tst', test);
            }
            window.localStorage.removeItem(item);
        });
        array.forEach((item) => {
          if (item === 'Cancer_Characteristicsis_dMMR') {
              const test = window.localStorage.getItem(item);
              console.log('tst', test);
          }
          window.localStorage.removeItem(item);
      });

        feilds.forEach((feild) => {
            const key = `Cancer_Type_${feild}`; 
            console.log(key); 
            window.localStorage.removeItem(key);

            let elem = document.querySelector(`#${feild}`);
            if (elem) elem.value = '';
        });

        props.onConfirm();
    }

    return (
        <div className="clear-modal-overlay">
          <div className="clear-modal">
            <div className="clear-modal-header">
              <h4>Clear All Fields?</h4>
            </div>
            <div className="clear-modal-body">
              <p>Are you sure you want to clear all fields?</p>
            </div>
            <div className="clear-modal-footer">
              <button className="btn btn-cancel" onClick={props.onCancel}>
                Cancel
              </button>
              <button className="btn btn-confirm" onClick={Handle_Confirm_Btn}>
                Confirm
              </button>
            </div>
          </div>
        </div>
      );
}

export default DeleteData;
