import React, { useState, useEffect, useRef } from 'react';
import { STAGGING_BACKEND, LOCAL_BACKEND } from "../../../common/helper";
import debounce from 'lodash/debounce';

const DrugSearchDropdown = ({onDrugSelect}) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedDrug, setSelectedDrug] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [drugs, setDrugs] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const dropdownRef = useRef(null);
    
    const apiUrl = STAGGING_BACKEND + "cancer/questionair/get-filtered-items/";

    useEffect(() => {
        const storedDrug = JSON.parse(localStorage.getItem('selectedDrug'));
        if (storedDrug) {
          setSelectedDrug(storedDrug.value);
          setSearchTerm(storedDrug.label);
        }
      
        const handleClickOutside = (event) => {
          if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowDropdown(false);
          }
        };
      
        document.addEventListener('mousedown', handleClickOutside);
      
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // const cleanSearchTerm = (term) => {
    //     // Get only the first word, remove all non-alphanumeric characters, and trim
    //     return term.split(/\s+/)[0].replace(/[^a-zA-Z0-9]/g, '').trim();
    // };

    const cleanDisplayTerm = (term) => {
        // Remove quotes and other special characters, but keep spaces
        return term.replace(/['"]/g, '').replace(/[^\w\s]/g, '').trim();
    };
    const fetchDrugs = (query = '') => {
        setIsSearching(true);
        const requestOptions = {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "token " + localStorage.getItem("token"),
            }
        };
        fetch(`${apiUrl}?filter=unique_drugs&query=${encodeURIComponent(query)}`, requestOptions)
            .then(response => response.json())
            .then(data => {
                const sortedDrugs = data.data.sort((a, b) => {
    const aStartsWith = a.label.toLowerCase().startsWith(query.toLowerCase());
    const bStartsWith = b.label.toLowerCase().startsWith(query.toLowerCase());
    if (aStartsWith && !bStartsWith) return -1;
    if (!aStartsWith && bStartsWith) return 1;
    return 0;
});
setDrugs(sortedDrugs);
                setDrugs(sortedDrugs);
                setIsSearching(false);
            })
            .catch(error => {
                console.error('Error fetching drugs:', error);
                setIsSearching(false);
            });
    };

    const debouncedFetchDrugs = useRef(debounce(fetchDrugs, 300)).current;

    
    useEffect(() => {
        if (showDropdown && searchTerm) {
            debouncedFetchDrugs(searchTerm);
        }
    }, [searchTerm, showDropdown]);

    const handleDrugSelect = (drug) => {
        setSelectedDrug(drug.value);
        setSearchTerm('');
        setShowDropdown(false);
        onDrugSelect(drug);
        localStorage.setItem('selectedDrug', JSON.stringify(drug));
    };

    const handleFocus = () => {
        setShowDropdown(true);
        if (drugs.length === 0) {
            fetchDrugs();
        }
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        setSelectedDrug(null);
        setSearchTerm(value);
    };

    return (
        <div ref={dropdownRef} style={{ position: 'relative', zIndex: 20 }}>
            <div style={{ cursor: 'pointer', padding: '5px', border: '1px solid #ccc' }}>
                <input
                    type="text"
                    placeholder="Search Drugs"
                    value={selectedDrug || searchTerm}
                    className='cityInput'
                    onChange={handleInputChange}
                    onFocus={handleFocus}
                    style={{ width: '100%', padding: '10px', margin: '0', boxSizing: 'border-box', backgroundColor:'white' }}
                />
            </div>
            {showDropdown && (
                <div style={{
                    position: 'absolute',
                    width: '100%',
                    zIndex: 3,
                    maxHeight: '200px',
                    overflowY: 'auto',
                    backgroundColor: '#fff',
                    border: '1px solid #ccc',
                    borderTop: 'none'
                }}>
                    {isSearching && <div>Searching...</div>}
                    <ul style={{ listStyle: 'none', padding: '0', margin: '0' }}>
                        {drugs.map(drug => (
                            <li
                                key={drug.id}
                                onClick={() => handleDrugSelect(drug)}
                                style={{ padding: '10px', borderBottom: '1px solid #ccc', cursor: 'pointer' }}
                            >
                               {cleanDisplayTerm(drug.label)}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default DrugSearchDropdown;