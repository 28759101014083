import React, { useState, useEffect, useRef } from 'react';
import { STAGGING_BACKEND, LOCAL_BACKEND } from "../../../common/helper";

const CitySearchDropdown = ({onCitySelect}) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedCity, setSelectedCity] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [cities, setCities] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const dropdownRef = useRef(null);
    
    const apiUrl = STAGGING_BACKEND + "cancer/get-city-list/";

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (searchTerm && showDropdown) {
            const debounceTimeout = setTimeout(() => {
                setIsSearching(true);
                fetch(`${apiUrl}?query=${encodeURIComponent(searchTerm)}`)
                    .then(response => response.json())
                    .then(data => {
                        setCities(data.data);
                        setIsSearching(false);
                    })
                    .catch(error => {
                        console.error('Error fetching cities:', error);
                        setIsSearching(false);
                    });
            }, 300); // Debounce time

            return () => clearTimeout(debounceTimeout);
        } else {
            setCities([]);
        }
    }, [searchTerm, apiUrl, showDropdown]);

    const handleCitySelect = (city) => {
        setSelectedCity(city.value);
        setSearchTerm('');
        setShowDropdown(false);
        onCitySelect(city);
    };
    
    return (
        <div ref={dropdownRef} style={{ position: 'relative', zIndex: 2 }}>
            <div
                onClick={() => setShowDropdown(!showDropdown)}
                style={{ cursor: 'pointer', borderRadius:'10px'}}
            >
                {<input
                    type="text"
                    placeholder="Search cities..."
                    value={selectedCity || searchTerm}
                    className='cityInput'
                    onChange={(e) => {
                        setSelectedCity(null)  
                        setSearchTerm(e.target.value)}}
                    style={{ width: '100%', padding: '10px', margin: '0',borderRadius:'10px', boxSizing: 'border-box', backgroundColor:'rgba(106, 192, 227, 0.28)' }}
                />}
            </div>
            {showDropdown && (
                <div style={{
                    position: 'absolute',
                    width: '100%',
                    zIndex: 3,
                    maxHeight: '200px', // Fixed height
                    overflowY: 'auto', // Enable scrolling
                    backgroundColor: '#fff', // Background color for visibility
                    border: '1px solid #ccc', // Border for dropdown
                    borderTop: 'none' // Remove border top to blend with the input field
                }}>
                    {isSearching && <div>Searching...</div>}
                    <ul style={{ listStyle: 'none', padding: '0', margin: '0' }}>
                        {cities.map(city => (
                            <li
                                key={city.id}
                                onClick={() => handleCitySelect(city)}
                                style={{ padding: '10px', borderBottom: '1px solid #ccc', cursor: 'pointer' }}
                            >
                                {city.label}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default CitySearchDropdown;

