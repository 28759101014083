import React from "react";
import styles from "../../css/customTooltip.module.css";

function CustomTooltip({ text }) {
  return (
    <td className={styles.tooltipContainer}>
      <p
        className={styles.truncatedText}
      >
        {text}
      </p>
      <div className={styles.tooltip}>{text}</div>
    </td>
  );
}

export default CustomTooltip;
